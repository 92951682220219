
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import HealthSurvey from '../HealthSurvey';
import { useMeasurement } from '../../../customHooks/useMeasurement';
import { IHealthSurveyAnswer } from '../../../../interfaces';
import { INotification } from '../../../../interfaces/notification.interfaces';
import { usePatient } from '../../../customHooks/usePatient';

interface IProps {
  title: string;
  healthSurveyModalShow: boolean;
  handleHealthSurveyModalClose: () => void;
  patientID: string;
  readOnly?: boolean;
}

const HealthSurveyModal: React.FC<IProps> = ({
  title,
  healthSurveyModalShow,
  handleHealthSurveyModalClose,
  patientID,
  readOnly,
}) => {
  const { measurement } = useMeasurement();

  const { updatePatientHealthSurvey } = usePatient(patientID);

  const handlerSubmit = async () => {
    if (patientID) {
      const notificationMessage: INotification = {
        message: "Paciente actualizado correctamente",
        status: "success",
        show: true,
        type: "toast",
        icon: "ssp-eye-off font-l me-2",
      };

      const healthPatientSurveyAnswer =
        measurement.health_patient_survey as IHealthSurveyAnswer[];

      patientID &&
        healthPatientSurveyAnswer.length > 0 &&
        updatePatientHealthSurvey(
          patientID,
          healthPatientSurveyAnswer,
          notificationMessage
        );
    }
    handleHealthSurveyModalClose();
  };
  return (
    <Modal
      size='xl'
      show={healthSurveyModalShow}
      onHide={handleHealthSurveyModalClose}
    >
      <Modal.Header className='justify-content-between' closeButton>
        <h5 className='fw-bold'>{title}</h5>
      </Modal.Header>
      <Modal.Body>
        <HealthSurvey patientID={patientID} readOnly={readOnly} />
      </Modal.Body>
      <Modal.Footer className='justify-content-end'>
        {!readOnly && (
          <Button className='prim ms-2' onClick={handlerSubmit}>
            Guardar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default HealthSurveyModal;

import { useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useAppDispatch } from '../../../../module/hooks';
import { employeeList } from '../../../../module/reducers/employee/slice';
import { useEmployee } from '../../../customHooks/useEmployee';
import { useReminder } from '../../../customHooks/useReminder';

export const NewReminderStep3 = () => {
  const dispatch = useAppDispatch()

  const { employees, employeeStatus, findCompleteNameById } = useEmployee()

  const { reminder, handleReminder } = useReminder({})

  useEffect(() => {
  dispatch(employeeList(false));
  }, [dispatch])
  return (
    <Row >
      <Col lg={6} className="pe-lg-5">
        <h3 className="font-ml fw-bold mb-2 mt-4 pt-2">Selecciona  personal a cargo y recomendación:</h3>
        <p className="fw-light mb-4">Si dispones de Servicios de Salud Sandoz en tu farmacia puedes seleccionarlos en el listado y se agregarán automáticamente en tu listado de servicios. </p>
        <Form.Group className="form-group select" controlId="reminder-motive">
          <Form.Select aria-label="appointment-motive" disabled={employeeStatus === 'fetching'} value={reminder.employee_id} onChange={({target}) => handleReminder({employee_id: target.value, employee_complete_name: findCompleteNameById(target.value)})}>
              {
                employeeStatus === 'fetching' ? <option>Cargando servicios...</option> : <option value="">Seleccione el personal encargado</option>
              }
              {
                !!employees.length && employees.map((employee, key) => <option key={key} value={employee.id}>{employee.name} {employee.surname}</option>)
              }
            </Form.Select>
        </Form.Group>
        <Form.Group className="form-group" controlId="reminder-resume">
          <Form.Control as="textarea" placeholder="Escríbe aquí tu recomendación" style={{ minHeight: '250px' }} value={reminder.recommendation} onChange={({target}) => handleReminder({recommendation: target.value})} />
        </Form.Group>

      </Col>
      <Col lg={6} className="ps-lg-5 mt-lg-0 mt-4">
       
      </Col>
    </Row>


  )
}

import Button from 'react-bootstrap/Button';
import { useAuth } from '../../customHooks/useAuth';
import { LogoutModal } from './modals/LogoutModal';
import {  useState } from 'react';

export const SignOutComponent: React.FC = (): JSX.Element => {
  
  const [logoutShow, logoutSetShow] = useState(false);

  const handleLogoutShow = () => logoutSetShow(true);
  const handleLogoutClose = () => logoutSetShow(false);

  return (
    <>
      <Button className="transparent p-1 d-inline-flex" onClick={handleLogoutShow}>
        <span className="color-red fw-light">Cerrar sesión</span>
      </Button>

      <LogoutModal logoutShow={logoutShow} handleLogoutClose={handleLogoutClose} />
    </>

  );
};
import { Routes, Route } from "react-router";
import { Navigate } from "react-router-dom";
import { LoginPage, RegisterPage, ResetPasswordPage, NewPasswordPage } from "../core/pages/auth";
import { NewPatientDataPage } from "../core/pages/patients/NewPatientData";
import { PatientsPage } from "../core/pages/patients/Patients";
import { AllNotesPage } from "../core/pages/patients/AllNotes";
import { PatientFilePage } from "../core/pages/patients/PatientFile";
import { HomePage } from "../core/pages/home/Home";
import { PrivateRoute } from "./PrivateRouter";
import { PublicRoute } from "./PublicRoute";
import { RemindersPage } from "../core/pages/reminders/Reminders";
import { NewReminderPage } from "../core/pages/reminders/NewReminder";
import { NewAppointmentPage } from "../core/pages/appointments/NewAppointment";
import { NewMeditionPage } from "../core/pages/appointments/NewMedition";
import { ServicesPage } from "../core/pages/services/Services";
import { NewServicePage } from "../core/pages/services/NewService";
import { routes } from "./constants";
import { ProfilePage } from "../core/pages/profile/Profile";
import { EditPharmacyDataPage } from "../core/pages/profile/EditPharmacyData";
import { NewTeammatePage } from "../core/pages/profile/NewTeammate";
import { EditTeammatePage } from "../core/pages/profile/EditTeammate";

export const MainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={routes.login} />}></Route>
      
      {/* Authentication */}
      <Route path={routes.login} element={
        <PublicRoute>
          <LoginPage />
        </PublicRoute>
      }></Route>
      <Route path={routes.register} element={
        <PublicRoute>
          <RegisterPage />
        </PublicRoute>
      }></Route>
      <Route path={routes.forgotPassword} element={
        <PublicRoute>
          <ResetPasswordPage />
        </PublicRoute>
      }></Route>
      <Route path={`${routes.recoverPassword}/:token`} element={
        <PublicRoute>
          <NewPasswordPage />
        </PublicRoute>
      }></Route>

      {/* Home */}
      <Route path={routes.home} element={
        <PrivateRoute>
          <HomePage />
        </PrivateRoute>
      }></Route>
      
      {/* Patients */}
      <Route path={routes.patients} element={
        <PrivateRoute>
          <PatientsPage />
        </PrivateRoute>
      }></Route>
      <Route path={`${routes.patientArchive()}`} element={
        <PrivateRoute>
          <PatientFilePage />
        </PrivateRoute>
      }></Route>
      <Route path={routes.newPatient} element={
        <PrivateRoute>
          <NewPatientDataPage />
        </PrivateRoute>
      }></Route>
      {/* <Route path={`${routes.editPatient}/:id`} element={
        <PrivateRoute>
          <NewPatientDataPage edit={true} />
        </PrivateRoute>
      }></Route> */}
      <Route path={routes.patientAllNotes()} element={
        <PrivateRoute>
          <AllNotesPage />
        </PrivateRoute>
      }></Route>

      {/* Reminders */}
      <Route path={routes.reminders} element={
        <PrivateRoute>
          <RemindersPage />
        </PrivateRoute>
      }></Route>
      <Route path={`${routes.reminders}/:id`} element={
        <PrivateRoute>
          <RemindersPage />
        </PrivateRoute>
      }></Route>
      <Route path={routes.editReminder()} element={
        <PrivateRoute>
          <NewReminderPage />
        </PrivateRoute>
      }></Route>

      <Route path={routes.newReminder} element={
        <PrivateRoute>
          <NewReminderPage />
        </PrivateRoute>
      }></Route>

      {/* Appointments */}
      <Route path={`${routes.newAppointment}`} element={
        <PrivateRoute>
          <NewAppointmentPage />
        </PrivateRoute>
      }></Route>
      <Route path={`${routes.editAppointment()}`} element={
        <PrivateRoute>
          <NewAppointmentPage />
        </PrivateRoute>
      }></Route>
      <Route path={routes.newMedition} element={
        <PrivateRoute>
          <NewMeditionPage />
        </PrivateRoute>
      }></Route>
      <Route path={routes.editMedition()} element={
        <PrivateRoute>
          <NewMeditionPage />
        </PrivateRoute>
      }></Route>

      {/* Services */}
      <Route path={routes.services} element={
        <PrivateRoute>
          <ServicesPage />
        </PrivateRoute>
      }></Route>
      <Route path={routes.newService} element={
        <PrivateRoute>
          <NewServicePage />
        </PrivateRoute>
      }></Route>


      {/* Profile */}
      <Route path={routes.profile} element={
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      }></Route>
       <Route path={routes.newTeammate} element={
        <PrivateRoute>
          <NewTeammatePage />
        </PrivateRoute>
      }></Route>
      <Route path={`${routes.editTeammate}/:id`} element={
        <PrivateRoute>
          <EditTeammatePage />
        </PrivateRoute>
      }></Route>
       <Route path={routes.editPharmacyData} element={
        <PrivateRoute>
          <EditPharmacyDataPage />
        </PrivateRoute>
      }></Route>
     
    </Routes>
  )
}

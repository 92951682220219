import { Row, Col, Form } from 'react-bootstrap';
import { useMeasurement } from '../../../customHooks/useMeasurement';
import { useAppDispatch } from '../../../../module/hooks';
import { useEmployee } from '../../../customHooks/useEmployee';
import { employeeList } from '../../../../module/reducers/employee/slice';
import { IPatient } from "../../../../interfaces/patient.interfaces";
import { useEffect, useState } from 'react';
import { useService } from '../../../customHooks/useService';
import { serviceList } from '../../../../module/reducers/service/slice';



export const NewMeditionStep1 = ({
  patient,
}: {
  patient?: IPatient;
}) => {
  const dispatch = useAppDispatch();

  const { handleMeasurement, measurement } = useMeasurement();
  const { employees, employeeStatus, findCompleteNameById } = useEmployee();
  const [selectService, setSelectService] = useState<string>('');
  const { services, status } = useService();

  const handleSelectService = (serviceId: string) => {
    handleMeasurement({
      health_service_id: [serviceId, 'mediciones_adicionales'],
      health_service_name: services.find((service) => service.id === serviceId)
        ?.label,
    });
    setSelectService(serviceId);
  }

  useEffect(() => {
    if(measurement.health_service_id && measurement.health_service_id.length){
      setSelectService(measurement.health_service_id[0])
    }
  }, [])


  const firstServiceCheck = (service: any) => {
    if(patient?.health_services){
      return !patient.health_services.includes(service)
    }
    return true
  }

  useEffect(() => {
    dispatch(employeeList(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(serviceList());
  }, [dispatch]);

  return (
    <Row className='mt-3 align-items-stretch'>
      <Col lg={12}>
        <h3 className='font-xml fw-bold mb-5 color-sec'>Datos de la medición</h3>
      </Col>
      <Col
        className='d-flex align-content-between flex-column justify-content-between'
        lg={4}
      >
        <div>
          <p className='fw-bold mb-2'>
            Elige al profesional que realiza la medición
          </p>
          <p className='fw-light mb-4 color-dark50'>
            Selecciona en el listado al farmacéutico responsable de realizar
            esta medición
          </p>
        </div>
        <Form.Group
          className='form-group select with-icon'
          controlId='measurement-employee'
        >
          <Form.Select
            aria-label='measurement-employee'
            disabled={employeeStatus === "fetching"}
            value={measurement.employee_id}
            onChange={({ target }) =>
              handleMeasurement({
                employee_id: target.value,
                employee_complete_name: findCompleteNameById(target.value),
              })
            }
          >
            {employeeStatus === "fetching" ? (
              <option>Cargando personal encargado...</option>
            ) : (
              <option>Seleccione el personal encargado</option>
            )}
            {!!employees.length &&
              employees.map((employee, key) => (
                <option key={key} value={employee.id}>
                  {employee.name} {employee.surname}
                </option>
              ))}
          </Form.Select>
          <i className='ssp-person'></i>
        </Form.Group>
      </Col>
      <Col
        className='d-flex align-content-between flex-column justify-content-between'
        lg={4}
      >
        <div>
          <p className='fw-bold mb-2'>Servicios de salud</p>
          <p className='fw-light mb-4 color-dark50'>
            Selecciona el Servicio de Salud en que quieras agendar la cita
          </p>
        </div>

        <Form.Group
          className='form-group select with-icon'
          controlId='measurement-health-service'
        >
          <Form.Select
            name='measurement_health_service'
            value={selectService}
            aria-label=''
            disabled={status === "fetching"}
            onChange={({ target }) => handleSelectService(target.value)}
          >
            {status === "fetching" ? (
              <option>Cargando servicios...</option>
            ) : (
              <option value=''>Seleccionar servicio de salud</option>
            )}

             {!!services.length &&
              services.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.label}
                    </option>
              ))}
          </Form.Select>
          <i className='ssp-medkit'></i>
        </Form.Group>
      </Col>
      <Col
        className='d-flex align-content-between flex-column justify-content-between'
        lg={4}
      >
        <div>
          <p className='fw-bold mb-2'>Tipo de medición</p>
          <p className='fw-light mb-4 color-dark50'>
            Especifica de qué tipo de medición vas a realizar
          </p>
        </div>

        <Form.Group
          className='form-group select with-icon'
          controlId='measurement-visit-type'
        >
          <Form.Select
            name='measurement_visit_type'
            value={measurement.visit_type}
            aria-label=''
            onChange={({ target }) =>
              handleMeasurement({
                visit_type: target.value,
              })
            }
          >
            <option>Elige tipo de medición</option>
            {firstServiceCheck(selectService) &&
              <option value='primera_visita'>Primera medición</option>
            }
            {!firstServiceCheck(selectService) &&
                <option value='seguimiento'>Seguimiento</option>
            }
          </Form.Select>
          <i className='ssp-medkit'></i>
        </Form.Group>
      </Col>
    </Row>
  );
}

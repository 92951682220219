
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import { useAuth } from '../../../customHooks/useAuth';

interface IProps {
  PrintShow: boolean;
  handlePrintClose: () => void;
  handlePrint?: () => void;
}

export const PrintModal: React.FC<IProps> = ({
  PrintShow,
  handlePrintClose,
  handlePrint,
}) => {
  return (
    <Modal show={PrintShow} onHide={handlePrintClose}>
      <Modal.Header className='justify-content-between' closeButton>
        <h5 className='fw-bold'>¿Quieres imprimir el archivo?</h5>
      </Modal.Header>
      <Modal.Body>
        <p className='text-left fw-light color-dark50 font-s mt-3'>
          Se descargará a tu ordenador para que puedas imprimirlo desde ahi y
          ajustar tu impresora si lo necesitas.
        </p>
      </Modal.Body>
      <Modal.Footer className='justify-content-end'>
        <Button className='btn prim outline' onClick={handlePrintClose}>
          Anterior
        </Button>
        <Button className='btn prim' onClick={() => {
          handlePrint && handlePrint();
          handlePrintClose();
        }}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
import { del, get, post, put } from "../../helpers";
import { IResponseDetail, IResponseList, IService } from "../../interfaces";

export const serviceListService = async (): Promise<IResponseList<IService>> =>
  get(`/api/pharmacy/health-services`);

/*
export const serviceSandozListService = async (): Promise<IResponseList<IService>> =>
  get(`/api/health-services`);
  */

export const updateSandozServiceService = async (service: IService): Promise<IResponseList<IService>> =>
  post(`/api/pharmacy/add-health-services`, { services: [service.id] });

export const createService = async (service: IService): Promise<IResponseList<IService>> =>
  post(`/api/service`, service);

export const updateServiceService = async (service: IService): Promise<IResponseList<IService>> =>
  put(`/api/service/${service.id}`, service);
  //pharmacy/add-health-services

export const deleteServiceService = async (id: IService['id']): Promise<IResponseDetail<Partial<IService>>> =>
  del(`/api/service/${id}`);
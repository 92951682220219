import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import { useAppDispatch } from '../../../module/hooks'
import { clearStatus } from '../../../module/reducers/reminder'
import { useReminder } from '../../customHooks/useReminder'
import { TablePatient } from '../../components/patient/TablePatient'
import { NewPatientModal } from '../../components/shared/modals/NewPatientModal';
import { usePatient } from '../../customHooks/usePatient';
import Container from "react-bootstrap/Container";
import {patientList} from "../../../module/reducers/patient/slice";
import {clearFilters} from "../../../module/reducers/patient";

export const HomePatients = () => {
  const { total} = usePatient()
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearFilters());
  }, []);

  const [NewPatientShow, NewPatientSetShow] = useState(false);
  const handleNewPatientShow = () => NewPatientSetShow(true);
  const handleNewPatientClose = () => NewPatientSetShow(false);
  const { urgent, general, filters, nextDay, previousDay } = useReminder({ fetch: true })
  //const dispatch = useAppDispatch()
  //useEffect(() => () => { dispatch(clearStatus()) }, [dispatch])
  return (
    <>
    <div className="card p-4 ">
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="fw-bold font-xxml mb-0">Pacientes <span className="color-dark30 font-xl fw-light ps-1">({total})</span> <NavLink to="/pacientes" className="color-prim font-s fw-light">Ver todos</NavLink></h1>
        <div className="d-inline-flex align-items-center">
          <Button className=" alt-btn" onClick={handleNewPatientShow}>
            <span>Nuevo paciente</span>
            <i className="ssp-person-add icon-circle"></i>
          </Button>
        </div>
      </div>
      <TablePatient perPage={6} />
    </div>

    <NewPatientModal NewPatientShow={NewPatientShow} handleNewPatientClose={handleNewPatientClose} />

    </>
  )

}

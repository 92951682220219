import { useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useAppointment } from '../../../customHooks/useAppointment';
import { useAppDispatch } from '../../../../module/hooks';
import { useEmployee } from '../../../customHooks/useEmployee';
import { employeeList } from '../../../../module/reducers/employee/slice';

export const NewAppointmentStep1 = () => {
  const dispatch = useAppDispatch();
  const { employees, employeeStatus, findCompleteNameById } = useEmployee();
  const { handleAppointment, appointment } = useAppointment({});

  useEffect(() => {
    dispatch(employeeList(false));
  }, [dispatch]);

  return (
    <Row className='mt-3 align-items-end'>
      <Col className='' lg={6}>
        <h3 className='font-ml fw-bold mb-3 color-sec'>
          Profesionales de la cita
        </h3>
        <Form.Group
          className='form-group select with-icon'
          controlId='responsable-agendar'
        >
          <Form.Label>Responsable de agendar esta cita:</Form.Label>
          <Form.Select
            name='contact_by'
            aria-label=''
            disabled={employeeStatus === "fetching"}
            value={appointment.employee_id}
            onChange={({ target }) =>
              handleAppointment({
                employee_id: target.value,
                employee_complete_name: findCompleteNameById(target.value),
              })
            }
          >
            {employeeStatus === "fetching" ? (
              <option>Cargando servicios...</option>
            ) : (
              <option value=''>Seleccione el farmacéutico</option>
            )}
            {!!employees.length &&
              employees.map((employee, key) => (
                <option key={key} value={employee.id}>
                  {employee.name} {employee.surname}
                </option>
              ))}
          </Form.Select>
          <i className="ssp-person"></i>
        </Form.Group>
      </Col>
      <Col className='' lg={6}>
        <Form.Group
          className='form-group select with-icon'
          controlId='responsable-realizar'
        >
          <Form.Label>Responsable de realizar esta medición:</Form.Label>
          <Form.Select
            name='contact_by'
            aria-label=''
            disabled={employeeStatus === "fetching"}
            value={appointment.owner_id}
            onChange={({ target }) =>
              handleAppointment({
                owner_id: target.value,
                owner_complete_name: findCompleteNameById(target.value),
              })
            }
          >
            {employeeStatus === "fetching" ? (
              <option>Cargando servicios...</option>
            ) : (
              <option value=''>Seleccione el farmacéutico</option>
            )}
            {!!employees.length &&
              employees.map((employee, key) => (
                <option key={key} value={employee.id}>
                  {employee.name} {employee.surname}
                </option>
              ))}
          </Form.Select>
          <i className="ssp-person"></i>
        </Form.Group>
      </Col>
    </Row>
  );
}

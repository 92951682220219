import { useEffect, useState } from "react"
import { Button, Carousel } from "react-bootstrap"
import { IPatient } from "../../../interfaces"
import {useAppDispatch, useAppSelector} from "../../../module/hooks"
import appointment, { clearStatus } from "../../../module/reducers/appointment"
import {appointmentSlice, serviceList} from "../../../module/reducers/appointment/slice"
import { useAppointment } from "../../customHooks/useAppointment"
import DateAppointments from "./DateAppointments"
import { NewAppointmentModal } from "../shared/modals/NewAppointmentModal"
import { NewActivityModal } from "../shared/modals/NewActivityModal"
import { SimpleAppointmentCard } from "./SimpleAppointmentCard"
import {useSelector} from "react-redux";
import moment from "moment";
import * as _ from 'lodash';
interface IProps {
  patient?: IPatient
}

export const HomeAppointments: React.FC<IProps> = ({ patient }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [next, setNext] = useState<number>(1);
  const appointmentPerRow = 4;
  const { appointments, filters } = useAppointment({
    patient: patient?.id || undefined,
  });

  const copiedAppointments = _.cloneDeep(appointments).sort((a, b) => a?.periodicity_hour && b?.periodicity_hour && (a?.periodicity_hour > b?.periodicity_hour) ? 1 : -1)

    const selectedData =  useAppSelector(state => state.appointment)
    let dateFilter = selectedData.filters.start_date
    let actualDate = moment(new Date()).format("YYYY-MM-DD")
    if(dateFilter && dateFilter < actualDate && moment(dateFilter).diff(moment(actualDate), 'days') === -1){
        dateFilter = actualDate
    }

  const [NewActivityShow, setNewActivityShow] = useState<boolean>(false);
  const handleNewActivityClose = () => setNewActivityShow(false);

  const handleMore: () => void = () => {
    setNext(next + appointmentPerRow);
  };

  useEffect(() => {
    if (!patient) dispatch(serviceList());
  }, [dispatch, patient, filters]);
  useEffect(
    () => () => {
      dispatch(clearStatus());
    },
    [dispatch]
  );

  return (
    <>
      <div className='card h-100 p-4 '>
          <div className='d-flex align-items-center justify-content-between'>
            <h3 className='font-xml fw-bold'>Citas</h3>

            <Button
              className=' alt-btn'
              onClick={() => setNewActivityShow(true)}
            >
              <span>Nueva cita</span>
              <i className='ssp-calendar-clean icon-circle'></i>
            </Button>
          </div>
          <DateAppointments patient={patient} />

        {copiedAppointments && copiedAppointments.filter((appointment) => appointment.periodicity_start_date === dateFilter).length ? (
            copiedAppointments.filter((appointment) => appointment.periodicity_start_date === dateFilter).slice(0, next).map((appointment, key) => (
            <div className='appointment-list' key={key}>
              <SimpleAppointmentCard
                key={key}
                patientData={patient}
                {...appointment}
                cardType="home"
              />
            </div>
          ))
        ) : (
          <div>
              <span className='appointment empty message d-flex align-items-center justify-content-center w-100 mb-0'>No hay datos disponibles.</span>
          </div>
        )}
        {next < copiedAppointments?.filter((appointment) => appointment.periodicity_start_date === dateFilter).length && (
          <Button
            onClick={handleMore}
            className='w-100 prim30 color-sec justify-content-center align-items-center font-s radius-8'
          >
            Ver más <i className='ssp-chevron-down ms-2 font-m'></i>
          </Button>
        )}
      </div>
      <NewActivityModal
        NewActivityShow={NewActivityShow}
        handleNewActivityClose={handleNewActivityClose}
        title='Nueva Cita'
        type="appointment"
      />
    </>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createEmployeeService, deleteEmployeeService, employeeByIdService, employeeListService, updateEmployeeService } from "../../../api/services/employee.service";
import { getFilters } from "../../../helpers/restClient";
import { IEmployee, IEmployeeState } from "../../../interfaces/employee.interfaces";
import { RootState } from "../../store";
import { employeeExtraReducers } from "./extraReducers";

export const initialState: IEmployeeState = { list: [], status: 'fetching', filters: { perPage: 10, page: 1, search: "" }};

export const employeeSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<IEmployeeState['filters']>) => { state.filters = action.payload },
    updateFiltersModal: (state, action: PayloadAction<IEmployeeState['filters']>) => { state.filters = action.payload },
  },
  extraReducers: (builder) => employeeExtraReducers(builder),
});

export const employeeList = createAsyncThunk(
  'employees/list',
  async (isFilterable: boolean | undefined = true, { getState }) => {
    if(!isFilterable) return employeeListService()
    const rootState = getState() as RootState;
    const params = getFilters(rootState.employee)
    return employeeListService(params)
  }
);

export const findEmployeeById = createAsyncThunk(
  'employees/getByid',
  async (id: string, { getState }) => {
    const rootState = getState() as RootState;
    const { list } = rootState.employee
    const findEmployeeInStore = list.length && list.find(employee => employee.id === id);
    if(findEmployeeInStore) return { content: findEmployeeInStore, code: 200 };
    return employeeByIdService(id);
  }
);

export const registerEmployee = createAsyncThunk('employees/register', async (employee: Partial<IEmployee>) => createEmployeeService(employee));

export const updateEmployee = createAsyncThunk('employees/update', async (employee: Partial<IEmployee>) => updateEmployeeService(employee));

export const deleteEmployee = createAsyncThunk('employees/delete', async (id: string) => deleteEmployeeService(id));
import { Row, Col, Form } from 'react-bootstrap';
import { useReminder } from '../../../customHooks/useReminder';

export const NewReminderStep2 = () => {
  const { handleReminder, reminder } = useReminder({})
  return (
    <Row className="align-items-end">
      <Col lg={6} className="pe-lg-5">
        <h3 className="font-ml fw-bold mb-2 mt-4 pt-2">Selecciona el motivo del recordatorio</h3>
        <p className="fw-light mb-4">Si dispones de Servicios de Salud Sandoz en tu farmacia puedes seleccionarlos en el listado y se agregarán automáticamente en tu listado de servicios. </p>
        <Form.Group className="form-group select" controlId="reminder-motive">
          <Form.Select aria-label="reminder-motive" value={reminder.reason} onChange={({target}) => handleReminder({reason: target.value})}>
            <option value="">¿En qué va a consistir el recordatorio?</option>
            <option value="Llamada">Llamada</option>
            <option value="Cita">Cita</option>
            <option value="Medicación">Medicación</option>
            <option value="Servicio">Servicio</option>
            <option value="Otro">Otro</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="form-group" controlId="reminder-resume">
          <Form.Control as="textarea" placeholder="Escríbe aquí el resumen del motivo de tu recordatorio" style={{ minHeight: '250px' }} value={reminder.motive} onChange={({target}) => handleReminder({motive: target.value})}/>
        </Form.Group>

      </Col>
      <Col lg={6} className="ps-lg-5 mt-lg-0 mt-4">
        <div className="bg-prim5 radius-20 p-4 d-flex align-items-baseline mb-2rem">

          <Form.Check className="ms-3"
            type="checkbox"
            id="reminder-complete"
            name="reminder-complete"
            checked={reminder.type === "Urgente"}
            onChange={({ target }) => handleReminder({ type: target.checked ? 'Urgente' : 'General' })}
          />
          <div className="ps-3">
            <label className="fw-bold mb-2">Recordatorio <span className="color-red">URGENTE</span></label>
            <p className="font-s fw-light mb-2">Selecciona si este es un recordatorio que se considere urgente. </p>
            <p className="font-s fw-light">
              Esto implica que se recordará el día y a la hora que elijas en un lugar destacado y no se podrá posponer.</p>
          </div>
        </div>
      </Col>
    </Row>


  )
}

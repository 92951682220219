import { useNavigate } from "react-router-dom";
import { IHealthSurveyAnswer, IMeasurement, IPatient } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../module/hooks";
import { measurementById, registerMeasurement, updateHealthMeasurementSurvey, updateHealthServiceSurvey, updateMeasurement } from "../../module/reducers/measurement/slice";
import { setNotification } from "../../module/reducers/notification";
import { newMeasurement } from "../../module/reducers/measurement";
import { updatePatientById } from "../../module/reducers/patient";
import { findPatientById } from "../../module/reducers/patient/slice";
import {useEffect, useRef, useState} from "react";
import { INotification } from "../../interfaces/notification.interfaces";
import { routes } from "../../router/constants";

export const useMeasurement = (patient?: IPatient['id'], id?: IMeasurement['id']) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { list, total, status, measurement } = useAppSelector(state => state.measurement)
      useEffect(() => {
      if(status === 'nofetched' && patient && id) {
      dispatch(measurementById({ patient, id }))
    }
  }, [dispatch, status, id, patient])
  const handleCreateNew = async (fields: IMeasurement, setSubmitting: (isSubmitting: boolean) => void) => {
    const { code, message } = await dispatch(registerMeasurement(fields)).unwrap();
    setSubmitting(false);
    if(code! >= 400) return  code! >=500 ? dispatch(setNotification({ message: "Ha ocurrido un error al guardar la cita", status: "danger", show: true, type: "toast" })) : dispatch(setNotification({ message: message!, status: "danger", show: true, type: "toast" }))
    navigate(-1)
    return dispatch(setNotification({ message: "Cita agregada exitosamente", status: "success", show: true, type: "toast" }))
  }
  const handleMeasurement = async (measurement: Partial<IMeasurement>) => {
    if(Object.keys(measurement).includes('patient')) {
      const { content } = await dispatch(findPatientById(measurement.patient!)).unwrap();
      dispatch(updatePatientById({id: measurement.patient, checked: true}))
      measurement.patientInfo = content;
    }
    return dispatch(newMeasurement(measurement))
  }
  const handleUpdateMeasurement= async (fields: Partial<IMeasurement>, setSubmitting?: (isSubmitting: boolean) => void) => {
    const { code, message } = await dispatch(updateMeasurement(fields)).unwrap();
    if (setSubmitting) setSubmitting(false);
    if (code! >= 400) return code! >= 500 ? dispatch(setNotification({ message: "Ha ocurrido un error al guardar el recordatorio", status: "danger", show: true, type: "toast" })) : dispatch(setNotification({ message: message!, status: "danger", show: true, type: "toast" }))
    navigate(-1)
    dispatch(setNotification({ message: "Medición actualizada exitosamente", status: "success", show: true, type: "toast" }))
  }

  const handleHealthServiceSurveyService = async (patientID: string, serviceID: string, answers: IHealthSurveyAnswer[],) => {
    const healthSurveyServices = await dispatch(updateHealthServiceSurvey(
      {patientID, serviceID, answers})).unwrap()
    return healthSurveyServices;

  }

  const handleHealthMeasurementSurveyService = async (patientID: string, answers: IHealthSurveyAnswer[], notification?: INotification, redirect?: boolean) => {
    const fields = { patientID, answers };
    const { error, message, code } = await dispatch(updateHealthMeasurementSurvey(fields)).unwrap();
    if (error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    redirect && navigate(routes.home)
    notification && dispatch(setNotification({ message: notification.message, status: notification.status, show: notification.show, type: notification.type }))
  }

  const validations = (step: number): boolean => {
    if(step === 1 && measurement.patient && measurement.health_service_id && measurement.employee_id && measurement.visit_type) { return false }

    if(step === 2 && measurement.measurement_step === 3) { return false }

    if(step === 2 && measurement.measurement_step === 4) { return false }

    // if(step === 1 && !measurement.patient) return true
    // if(step === 2 && !measurement.health_service_id) return true
    // if(step === 3 && (!measurement.employee_id || !measurement.data)) return true
    // return false
    return true
  }
  return {
    measurement,
    handleMeasurement,
    list,
    total,
    handleCreateNew,
    validations,
    handleUpdateMeasurement,
    status,
    updateHealthServiceSurvey: handleHealthServiceSurveyService,
    updateMeasurementSurveyService: handleHealthMeasurementSurveyService
  }
}

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { INote } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../module/hooks"
import { newNote, clearStatus } from "../../module/reducers/note";
import { deleteNote, noteById, noteListByPatient, registerNote, updateNote } from "../../module/reducers/note/slice";
import { setNotification } from "../../module/reducers/notification";
import { findPatientById } from "../../module/reducers/patient/slice";
import { updatePatientById } from "../../module/reducers/patient";

export const useNote = (patientId?: string, id?: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { list, total, status, note } = useAppSelector(state => state.note)

  useEffect(()=> {
    if (patientId && id) dispatch(noteById({ patient: patientId, id}))
  }, [dispatch, id, patientId])

  useEffect(() => {
    const noteByPatient = async (id: string) => dispatch(noteListByPatient(id))
    if (patientId && status === 'nofetched' && !id) noteByPatient(patientId)
    return () => { 
      if (status === 'fetched') dispatch(clearStatus()) 
    }
  }, [dispatch, patientId, status, id])

  const handleCreateNote = async (fields: Partial<INote>, setSubmitting?: (isSubmitting: boolean) => void) => {
    const { error, code, message } = await dispatch(registerNote(fields)).unwrap();
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    navigate(0)
    dispatch(setNotification({ message: "Nota agregada exitosamente", status: "success", show: true, type: "toast" }))   
  }
  const handleUpdateNote = async (fields: Partial<INote>, setSubmitting: (isSubmitting: boolean) => void) => {
    const { code, error, message } = await dispatch(updateNote(fields)).unwrap();
    setSubmitting(false);
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    navigate(-1)
    dispatch(setNotification({ message: "Nota actualizado exitosamente", status: "success", show: true, type: "toast" }))
  }

  const handleNote = async (note: Partial<INote>) => {
    if(Object.keys(note).includes('patient')) {
      const { content } = await dispatch(findPatientById(note.patient!)).unwrap();
      dispatch(updatePatientById({id: note.patient, checked: true}))
    }
    return dispatch(newNote(note))
  }

  const del = async (note: INote) => {
    const { error, message, code } = await dispatch(deleteNote(note)).unwrap();
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    dispatch(noteListByPatient(note.patient))
    dispatch(setNotification({ message: "Nota eliminada exitosamente", status: "success", show: true, type: "toast" }))
  }
  return {
    status,
    list,
    total,
    handleNote,
    createNote: handleCreateNote,
    updateNote: handleUpdateNote,
    deleteNote: del,
    note
  }
}
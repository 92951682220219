import { isEmptyArray } from "formik";
import { SandozApi } from "../api";
import { IFilter, IParams } from "../interfaces";
import { AxiosErrorResponse, DataErrorResponse } from "../interfaces/error.interfaces";

export const get = async (url: string, params?: IParams) => {
  try {
    return (await SandozApi().get(url, { params })).data;
  } catch (error: unknown) {
    return getErrorResponse(error as AxiosErrorResponse);
  }
}

export const post = async (url: string, data: { [key: string]: any }, withHeaders: boolean | undefined = true) => {
  try {
    return (await SandozApi(withHeaders).post(url, data)).data;
  } catch (error: unknown) {
    return getErrorResponse(error as AxiosErrorResponse);
  }
}

export const put = async (url: string, data: { [key: string]: any }) => {
  try {
    return (await SandozApi().put(url, data)).data;
  } catch (error: unknown) {
    return getErrorResponse(error as AxiosErrorResponse);
  }
}

export const patch = async (url: string, data: { [key: string]: string }) => {
  try {
    return (await SandozApi().patch(url, data)).data;
  } catch (error: unknown) {
    return getErrorResponse(error as AxiosErrorResponse);
  }
}

export const del = async (url: string) => {
  try {
    return (await SandozApi().delete(url)).data;
  } catch (error: unknown) {
    return getErrorResponse(error as AxiosErrorResponse);
  }
}

export const getErrorResponse = ({ response }: AxiosErrorResponse): DataErrorResponse => ({
  error: true,
  message: response.data.message,
  code: response.data.code
})

export const getFilters = ({ filters }: { filters: IFilter}) => {
  const filtersWithData = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== null && !isEmptyArray(value) && value !== ""))
  return new URLSearchParams(filtersWithData as any).toString().replace(/%2C/g, ',')
}
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useAppDispatch } from '../../../../module/hooks';
import { serviceList } from '../../../../module/reducers/service/slice';
import { useMeasurement } from '../../../customHooks/useMeasurement';
import { AddServiceModal } from '../../../components/shared/modals/AddServiceModal';
import { AdditionalMeasurementQuestions, HealthMeasurementSurveyQuestions } from '../../../../api/services/data/measurementData';
import { flattenDeep, groupBy } from 'lodash';
import { PrintModal } from '../../../components/shared/modals/PrintModal';
import { useReactToPrint } from "react-to-print";
import { getHealthMeasurementSurvey, updateHealthMeasurementSurvey } from '../../../../module/reducers/measurement/slice';
import { INotification } from '../../../../interfaces/notification.interfaces';
import { useService } from "../../../customHooks/useService";
import moment from "moment";
import { useAuth } from "../../../customHooks/useAuth";



export const NewVisitStep2: React.FC = () => {
  const dispatch = useAppDispatch();
  const { measurement, handleMeasurement, updateMeasurementSurveyService } =
    useMeasurement();
  const { user, updateUser, updatePassword } = useAuth()
  const [AddServiceShow, AddServiceSetShow] = useState<boolean>(false);
  const [step, setStep] = useState<number>(3);
  const [answers, setAnswers] = useState<any[]>([]);
  const [measurementList, setMeasurementList] = useState<any>([]);
  const [servicesListPayload, setServicesListPayload] = useState<any[]>([]);
  const { services } = useService();
  const [patientNote, setPatientNote] = useState<string>(" ");
  const [privateNote, setPrivateNote] = useState<string>(" ");
  const [render, setRender] = useState<boolean>(false);
  const [addNote, setAddNote] = useState<boolean>(false);
  const handleAddServiceShow = () => AddServiceSetShow(true);
  const handleAddServiceClose = () => AddServiceSetShow(false);
  const [PrintShow, PrintSetShow] = useState<boolean>(false);
  const [displayPrintOption, setDisplayPrintOption] = useState<boolean>(true);
  const handlePrintShow = () => PrintSetShow(true);
  const handlePrintClose = () => PrintSetShow(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // copyStyles: true,
    // pageStyle: "{ margin: 5 mm; }",
    onBeforePrint: () => {
      PrintSetShow(false)
      setDisplayPrintOption(true)
    },
    //onAfterPrint: () => setDisplayPrintOption(false),
  });
  const patientID = measurement.patient;
  const measurementName = measurement.health_service_id as string[];
  const [completeName, setCompleteName] = useState<string>('');

  const [pigmentacionValue, setpigmentacionValue] = useState<number>(0);
  const baseOptions = ['Selecciona un resultado', 'Resultados correctos', 'Necesita consejo Farmacéutico', 'Derivar al médico']
  const servicio_piel_01 = ['Selecciona un resultado', 'Normal', 'Media', 'Baja']
  const servicio_piel_10 = ['Selecciona un resultado', 'Normal', 'Tendencia a seca', 'Seca', 'Muy seca']
  const servicio_piel_11 = ['Selecciona un resultado', 'Muy seca', 'Tendencia a seca', 'Normal', 'Grasa']
  /* Defining an array of objects which contains information about different levels of results for a medical service. */
  const resultLevel = [
    {
      title: "NIVEL 1: RESULTADOS CORRECTOS",
      description:
        "El resultado de todas las mediciones de este servicio están dentro de la normalidad.",
      color: "green",
    },
    {
      title: "NIVEL 2: NECESITA CONSEJO FARMACÉUTICO",
      description:
        "El resultado de al menos una de las mediciones requiere de seguimiento farmacéutico.",
      color: "orange",
    },
    {
      title: "NIVEL 3: DERIVAR AL MÉDICO",
      description:
        "El resultado de al menos una de las mediciones requiere atención médica.",
      color: "red",
    },
  ];

  /**
   * Handles the deletion of a service from a list of measurement names */
  const handleDeleteService = (serviceID: string) => {
    const found = measurementName.includes(serviceID);
    if (found) {
      const removeService = measurementName.filter(
        (service) => service !== serviceID
      );
      handleMeasurement({ health_service_id: removeService });
      const foundServiceToDelete = flattenDeep(servicesListPayload).filter(
        (item) => item.serviceID !== serviceID
      );
      const grouped = groupBy(foundServiceToDelete, "serviceID");
      setServicesListPayload([...Object.values(grouped)]);

      setAnswers(answers.filter((answer) => answer.serviceID !== serviceID))
      // const resultList = measurementList.filter((x:any) => x[0].serviceID != serviceID)
      // setMeasurementList(resultList)
    }
  };

  /**
   * Handles the selected answer and calculates the measurement level color and result based on the limits provided.
   */
  const handlerSelectedAnswer = (item: any, value: string) => {
    // calculate the min and max value
    let { min, max, reverse } = item.limits;
    let measurementLevelColor = "";
    if (value !== "") {
      if (min && max) {
        if (reverse) {
          const temp = min;
          min = max;
          max = temp;
        }
        const valueNumber = parseInt(value);
        if (valueNumber < min) {
          reverse
            ? (measurementLevelColor = "red")
            : (measurementLevelColor = "green");
          // measurementLevelColor = "green";
        }
        // else if (valueNumber >= min && valueNumber < max) {
        //   measurementLevelColor = "orange";
        // }
        else if (valueNumber >= max) {
          reverse
            ? (measurementLevelColor = "green")
            : (measurementLevelColor = "red");
          // measurementLevelColor = "red";
        } else {
          measurementLevelColor = "orange";
        }
      }
      if (item.serviceID == 'mediciones_adicionales' && item.title == 'Altura') {
        value = value.split('.')[0]
      }
    }
    if(value !== '' && item.questionID.includes('piel')){
      if(item.questionID.includes('_01')){
        value == 'Normal' ? measurementLevelColor = 'green' : value == 'Media' ? measurementLevelColor = 'orange' : measurementLevelColor = 'red'
      }
      if(item.questionID.includes('_02')){
        parseInt(value) < 30 ? measurementLevelColor = 'red' : parseInt(value) < 55 && parseInt(value) > 30 ? measurementLevelColor = 'orange' : measurementLevelColor = 'green'
      }
      if(item.questionID.includes('_03')){
        parseInt(value) < 5 ? measurementLevelColor = 'red' : parseInt(value) < 25 && parseInt(value) > 5 ? measurementLevelColor = 'orange' : measurementLevelColor = 'green'
      }
      if(item.questionID.includes('_04')){
        parseInt(value) < 70 && parseInt(value) > 40 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_05')){
        parseInt(value) < 40 && parseInt(value) > 15 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_06')){
        parseInt(value) < 65 && parseInt(value) > 30 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_07')){
        parseInt(value) < 45 && parseInt(value) > 20 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_08')){
        parseInt(value) < 25 && parseInt(value) > 5 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_09')){
        measurementLevelColor = 'green'
        setpigmentacionValue(parseInt(value))
      }
      if(item.questionID.includes('_10')){
        value == 'Normal' ? measurementLevelColor = 'green' : value == 'Muy seca' ? measurementLevelColor = 'red' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_11')){
        value == 'Normal' ? measurementLevelColor = 'green' : value == 'Grasa' ? measurementLevelColor = 'red' : measurementLevelColor = 'orange'
      }
    }

    const surveyData = {
      ...item,
      value,
      measurementLevelClass: measurementLevelColor,
      measurementLevelResult:
        measurementLevelColor === "green"
          ? 0
          : measurementLevelColor === "orange"
            ? 1
            : 2,
    };
    if(item.questionID.includes('_09')){
      surveyData.measurementLevelClass = ''
    }

    const exist = answers.find(
      (answer: { questionID: string }) => answer.questionID === item.questionID
    );
    if (exist && Object.keys(exist).length > 0) {
      const removeAnswer = answers.filter(
        (answer: { questionID: string }) =>
          answer.questionID !== item.questionID
      );
      surveyData.value === ""
        ? setAnswers([...removeAnswer])
        : setAnswers([...removeAnswer, surveyData]);
    } else {
      setAnswers([...answers, surveyData]);
    }
  };

  /**
   * Handles notes for a health measurement and updates the state.
   */
  const handleNotes = (type: string, value: string | boolean) => {



    type === "patient" && setPatientNote(value as string);
    type === "private" && setPrivateNote(value as string);
    type === "add" && setAddNote(value as boolean);
    handleMeasurement({
      health_measurement_notes: {
        patient: type === "patient" ? value as string : patientNote,
        private: type === "private" ? value as string : privateNote,
        add: type === "add" ? value as boolean : addNote,
      },
    });
  };

  const notificationMessage: INotification = {
    message: "Mediciones actualizadas correctamente",
    status: "success",
    show: true,
    type: "toast",
    icon: "ssp-eye-off font-l me-2",
  };

  useEffect(() => {
    //handleNotes("patient", measurement?.health_measurement_notes?.add ?? false);
    if (measurement.patientInfo && measurement.patientInfo.complete_name) {
      setCompleteName(measurement.patientInfo.complete_name)
    }
  }, [])

  useEffect(() => {
    if (measurement.patientInfo && measurement.patientInfo.complete_name) {
      setCompleteName(measurement.patientInfo.complete_name)
    }
  }, [measurement])


  useEffect(() => {
    const lengthServicesList = flattenDeep(servicesListPayload).filter((service) => service.serviceID != 'mediciones_adicionales').length;
    const lengthServicesListWithAdditional = flattenDeep(servicesListPayload).length;
    if (flattenDeep(answers).length === lengthServicesList || flattenDeep(answers).length === lengthServicesListWithAdditional) {
      handleMeasurement({
        measurement_step: 3,
      });
    } else {
      handleMeasurement({
        measurement_step: 2,
      });
    }
  }, [answers, servicesListPayload]);

  useEffect(() => {

    if (
      measurement.measurement_step === 3 ||
      measurement.measurement_step === 4
    ) {
      setStep(measurement.measurement_step);
    }
  }, [measurement.measurement_step]);

  /* Setting the payload object to be sent to the endpoint */
  useEffect(() => {
    if (step === 4 || measurement.measurement_step === 4) {
      const result = groupBy(answers, "serviceID");
      handleMeasurement({
        health_measurement_survey_answers: result,
      });
      const measurementPayload = {
        patient_id: measurement.patient,
        employee_id: measurement.employee_id,
        measurement_survey: result,
        measurement_notes: measurement.health_measurement_notes,
      };
      handleMeasurement({
        health_measurement_survey_payload: measurementPayload,
      });
    }
  }, [
    step,
    measurement.measurement_step,
    measurement.health_measurement_notes,
  ]);

  useEffect(() => {
    if (measurement.measurement_step === 5 && patientID) {
      updateMeasurementSurveyService(
        patientID,
        measurement.health_measurement_survey_payload,
        notificationMessage
      );
      handleMeasurement({
        measurement_step: 6,
      });
    }
  }, [measurement.measurement_step]);


  /* Dispatch the measurement survey endpoint */
  useEffect(() => {
    measurementName.forEach((serviceID: string) => {
      if (patientID && serviceID) {
        dispatch(
          getHealthMeasurementSurvey({
            patientID,
            serviceID,
          })
        );
        /*handleMeasurement({
          measurement_step: 2,
        });*/
      }
    });
  }, [measurementName]);

  // useEffect(() => {
  //   console.log()
  // }, [render]);


  const renderLoad = (measurement: any) => {
    if (
      measurement.health_measurement_survey &&
        measurement.health_measurement_survey ?.length > 0
    ) {
      const serviceID = measurement.health_measurement_survey[0].serviceID;

      const found =
        servicesListPayload.length > 0 &&
        measurement.health_measurement_survey &&
        flattenDeep(servicesListPayload).filter(
          (item: any) => item.serviceID === serviceID
        );
      let medicionAdicional = null
      /*if (servicesListPayload.length > 0){
        servicesListPayload.map((service) => {
          if(service[0].serviceID == 'mediciones_adicionales'){
            medicionAdicional = service
          }
        })
        if(medicionAdicional && servicesListPayload.indexOf(medicionAdicional) != -1){
          servicesListPayload.splice(servicesListPayload.indexOf(medicionAdicional), 1)
          servicesListPayload.push(medicionAdicional)
        }
      }*/
      if (found && found.length === 0) {
        setRender(false);
        if (measurement.health_measurement_survey[0].serviceID === 'mediciones_adicionales') {
          setServicesListPayload([
            ...servicesListPayload,
            measurement.health_measurement_survey
          ]);
        } else {
          setServicesListPayload([
            measurement.health_measurement_survey,
            ...servicesListPayload,
          ]);
        }
      } else if (servicesListPayload.length === 0) {
        setServicesListPayload(prevState => ([...prevState, measurement.health_measurement_survey]));
        setRender(true);
      }
    }
  }
  const getFPSValue = (item: any) => {
    let value;
    if(pigmentacionValue < 50){
      pigmentacionValue >= 1 && pigmentacionValue <= 10 ? value = 50 : pigmentacionValue >= 11 && pigmentacionValue <= 23 ? value = 40 : pigmentacionValue >= 24 && pigmentacionValue <= 35 ? value = 35 : value = 30
    }else{
      pigmentacionValue >= 50 && pigmentacionValue <= 62 ? value = 25 : pigmentacionValue >= 63 && pigmentacionValue <= 75 ? value = 20 : pigmentacionValue >= 76 && pigmentacionValue <= 94 ? value = 15 : value = 10
    }
    return value
  }

  /* Setting servicesListPayload from measurement.health_measurement_survey redux value*/
  useEffect(() => {
    if (measurement.health_measurement_survey &&
      measurement.health_measurement_survey ?.length > 0
    ) {
      const currentId: any = measurement.health_measurement_survey[0] ?.serviceID
      if (!measurementList.some((x: any) => x.some((y: any) => y.serviceID == currentId))) {
        setMeasurementList((prevState: any) => ([...prevState, measurement.health_measurement_survey]))
      }
    }
  }, [measurement]);

  useEffect(() => {
    console.log(measurementName)
    measurementName.forEach((name: any) => {
      if (measurementList.some((x: any) => x.some((y: any) => y.serviceID == name))) {
        const item = measurementList.find((x: any) => x.some((y: any) => y.serviceID == name))
        renderLoad({ health_measurement_survey: item })
      }
    });
  }, [measurementList, measurementName])


  // renderLoad(measurement)
  return (
    <>
      <Row className='align-items-end' ref={componentRef}>
        <Col className=''>
          <h3 className='font-xml fw-bold mb-4 color-sec print-none'>Mediciones</h3>
          {/* Titulo solo visible al Imprimir */}
          <h3 className='font-xml fw-bold mb-4 color-sec print-title'>
            <span>Mediciones</span>
            <span className="font-m"> <img src="/assets/img/logo-ps.png" alt="logo sandoz pacientes" /></span>
          </h3>
          <p className="print-title fw-bold mt-3">{completeName} {moment(new Date()).format('DD/MM/YYYY HH:mm')}h.</p>
          <p className="print-title fw-semibold mb-3 mt-2">{user.name}</p>
          {servicesListPayload.map((items: any, index: number) => {

            const filterAnswer =
              step === 4 &&
              answers.filter(
                (answer: { serviceID: string }) =>
                  answer.serviceID === items[0] ?.serviceID
              );
            const resultIndex =
              filterAnswer &&
              filterAnswer.length > 0 &&
              filterAnswer.reduce(
                (
                  prev: { measurementLevelResult: number },
                  current: { measurementLevelResult: number }
                ) =>
                  prev.measurementLevelResult > current.measurementLevelResult
                    ? prev
                    : current
              ).measurementLevelResult;

            const answerAditionalLength = flattenDeep(answers).filter((answer) => answer.questionID.includes('adicional_measurement')).length
            return (
              <div key={index} className={ `${answerAditionalLength == 0 && items[0].serviceID == 'mediciones_adicionales' ? 'print-none' : ''}`}>
                <div className='service-row' key={index}>
                  <div>
                    <h2 className='font-m fw-semibold'>
                      {items[0] && items[0].groupTitle}
                    </h2>
                    {items.map((item: any, index: number) => {
                      const { title, type, unit, isValuable, questionID } = item;
                      let measurementLevelClass = ''
                      if (step === 4) {
                        if (flattenDeep(answers).find((answer: { questionID: string }) => answer.questionID === item.questionID)) {
                          measurementLevelClass = flattenDeep(answers).find((answer: { questionID: string }) => answer.questionID === item.questionID).measurementLevelClass
                        }
                      }
                      return (
                        <div
                          className={`medition-input ${measurementLevelClass}`}
                          key={index}
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>{title}</Form.Label>
                            {type === "input" && (
                              <>
                                <Form.Control
                                  type='number'
                                  placeholder='Dato'
                                  disabled={step === 4}
                                  value={answers ?.find(x => x.questionID == item.questionID) ?.value || '' }
                                  onChange={({ target }) => {
                                    handlerSelectedAnswer(item, target.value);
                                  }}
                                />
                                <span className='unit'>{unit}</span>
                              </>
                            )}
                          </Form.Group>
                          {isValuable && (
                            <>
                              {type !== "select" && (
                                <span className='separator'></span>
                              )}
                              <Form.Group className='form-group'>
                                {type === "select" && (
                                  <span className='separator'></span>
                                )}
                                {type !== "select" && (
                                  <Form.Label>Valoración</Form.Label>
                                )}
                                <Form.Select
                                  disabled={step === 4}
                                  onChange={({ target }) => {
                                    handlerSelectedAnswer(item, target.value);
                                  }}
                                >
                                  { questionID == 'servicio_piel_01' && servicio_piel_01.map((option, key) => {
                                    let keyAux = 0
                                    answers.filter((answer) => answer.questionID == 'servicio_piel_01')[0]?.value === option ? keyAux = key : keyAux = 0
                                    return (
                                        <option disabled={key == 0} selected={key == keyAux} value={option}>{option}</option>
                                    )
                                  })}
                                  { questionID == 'servicio_piel_10' && servicio_piel_10.map((option, key) => {
                                    let keyAux = 0
                                    answers.filter((answer) => answer.questionID == 'servicio_piel_10')[0]?.value === option ? keyAux = key : keyAux = 0
                                    return (
                                        <option disabled={key == 0} selected={key == keyAux} value={option}>{option}</option>
                                    )
                                  })}
                                  { questionID == 'servicio_piel_11' && servicio_piel_11.map((option, key) => {
                                    let keyAux = 0
                                    answers.filter((answer) => answer.questionID == 'servicio_piel_11')[0]?.value === option ? keyAux = key : keyAux = 0
                                    return (
                                        <option disabled={key == 0} selected={key == keyAux} value={option}>{option}</option>
                                    )
                                  })}

                                  { questionID.includes('composicion_corporal') && baseOptions.map((option, key) => {
                                    return (
                                        <option disabled={key == 0} selected={key == 0} value={option}>{option}</option>
                                    )
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </>
                          )}
                        </div>
                      );
                    })}
                    {step === 4 && items && items[0] && items[0].questionID.includes('piel') && (
                        <div className='mt-4'>
                          <h4 className='text-uppercase font-s fw-normal'>
                            PIGMENTACIÓN: <span>{getFPSValue(items[8])} FPS</span>
                          </h4>
                        </div>
                    )}
                    {step === 4 && resultIndex >= 0 && resultLevel[resultIndex] && (
                      <div
                        className={`results ${resultLevel[resultIndex].color}`}
                      >
                        <h4 className='text-uppercase font-s fw-normal'>
                          {resultLevel[resultIndex].title}
                        </h4>
                        <p className='font-s'>
                          {resultLevel[resultIndex].description}
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <div>
                      <span className={`service-chip border-left-${items[0].serviceID}`}>
                        {/* {items[index] && items[index].serviceID.replace(/_/g, " ").toUpperCase()} */}
                        {items[0] && items[0] ?.serviceID}
                      </span>
                      {step === 3 && items[0] ?.serviceID !== 'mediciones_adicionales' && measurementName && items[0] ?.serviceID !== measurementName[0] && (
                        <Button
                          className='btn transparent color-dark50 mt-4'
                          onClick={() =>
                            handleDeleteService(items[0] ?.serviceID)
                          }
                        >
                          <i className='ssp-delete me-1'> </i>
                          <span className='font-s fw-normal'>
                            Eliminar servicio
                        </span>
                        </Button>
                      )}
                      {step === 4 && resultIndex >= 0 && (
                        <div className='medition-img mt-4'>
                          <img
                            src={`/assets/img/measurement-${resultIndex}.png`}
                            alt=''
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* ADICIONALES */}
          {/* <div className='service-row'>
            <div>
              <h2 className='font-m fw-semibold'>Mediciones adicionales</h2>
              {AdditionalMeasurementQuestions.map((question, index) => {
                const { title, type, unit, questionID } = question;
                let titleLabel = "";
                if (index > 0) {
                  if (
                    title !== AdditionalMeasurementQuestions[index - 1].title
                  ) {
                    titleLabel = title;
                  }
                }
                if (titleLabel === "" && index === 0) {
                  titleLabel = title;
                }
                return (
                  <div className='medition-input' key={index}>
                    <Form.Group className='form-group'>
                      <Form.Label>{title}</Form.Label>
                      {type === "input" && (
                        <>
                          <Form.Control
                            type='text'
                            placeholder='Dato'
                            onChange={({ target }) => {
                              handlerSelectedAnswer(question, target.value);
                            }}
                          />
                          <span className='unit'>{unit}</span>
                        </>
                      )}
                    </Form.Group>
                  </div>
                );
              })}
            </div>
            <div>
              <span className='service-chip border-left-dark-blue'>
                Chequeo General
              </span>
            </div>
          </div> */}

          {step === 3 && (
            <>
              <h4 className='font-l fw-bold mt-5 pt-4'>
                ¿Quieres añadir otras mediciones?
              </h4>
              {measurementName.length >= services.length + 1 &&
                <div className='bg-dark5 radius-12 p-3 d-flex align-items-center mt-2'>
                  <span className='font-s'>No hay más mediciones disponibles para añadir</span>
                </div>
              }

              <Button className='alt-btn mt-4' onClick={handleAddServiceShow} disabled={measurementName.length >= services.length + 1}>
                <span>Añadir nuevas mediciones</span>
                <i className='ssp-add icon-circle'></i>
              </Button>
            </>
          )}
        </Col>
        {step === 4 && (
          <>
            <Row className='mt-5 pt-4 print-nomargin'>
              <Col lg={6} className={addNote ? '' : 'print-none'}>
                <h4 className='font-ml fw-bold mb-4'>Nota para el paciente</h4>
                <Form.Group className='form-group'>
                  <Form.Control
                    as='textarea'
                    defaultValue={" "}
                    placeholder='Aquí iría escrito el consejo farmacéutico o las notas que el profesional considere.'
                    style={{ minHeight: "150px" }}
                    onChange={({ target }) => {
                      handleNotes("patient", target.value);
                    }}
                  />

                  <div className='textarea-footer px-3 print-none'>
                    <p className='fw-normal font-s lh-1'>¿Añadir al informe?</p>
                    <div>
                      <Form.Check
                        className='ms-4 me-0'
                        inline
                        reverse
                        label='Sí'
                        name='textarea'
                        type='radio'
                        checked={measurement ?.health_measurement_notes ?.add === true ? true : false}
                        onChange={({ target }) => {
                          handleNotes("add", true);
                        }}
                      />
                      <Form.Check
                        className='ms-4 me-0'
                        inline
                        reverse
                        label='No'
                        name='textarea'
                        type='radio'
                        // defaultChecked={true}
                        checked={measurement ?.health_measurement_notes ?.add === false ? true : false}
                        onChange={({ target }) => {
                          handleNotes("add", false);
                        }}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} className={'print-none'}>
                <h4 className='font-ml fw-bold mb-4'>Nota privada</h4>
                <Form.Group className='form-group'>
                  <Form.Control
                    as='textarea'
                    placeholder='Esta es la nota privada del farmacéutico.'
                    style={{ minHeight: "150px" }}
                    defaultValue={""}
                    name='privateNote'
                    onChange={({ target }) => {
                      handleNotes("private", target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

          </>
        )}
      </Row>
      {step === 3 && (
        <AddServiceModal
          AddServiceShow={AddServiceShow}
          handleAddServiceClose={handleAddServiceClose}
        />
      )}
      {step === 4 && displayPrintOption && (
        <>
          <PrintModal
            PrintShow={PrintShow}
            handlePrintClose={handlePrintClose}
            handlePrint={handlePrint}
          />
        </>
      )}
      {step === 4 && (
        <>
          <Row>
            <Col xs={12}>
              <h4 className='font-ml fw-bold mb-4 mt-4'>Imprimir resultados</h4>

              <div className='bg-dark5 radius-12 p-3 d-flex align-items-center mb-3'>
                <div className='icon-circle big bg-prim'>
                  <i className='ssp-print_outline font-xl color-white'></i>
                </div>
                <div className='ps-3 w-100'>
                  <p className='fw-bold font-m mb-2'>Informe de medición</p>
                  <p className='fw-light font-s'>
                    Para entregar el documento con los resultados al paciente
                  </p>
                </div>

                <Button
                  className='btn prim outline px-4'
                  onClick={handlePrintShow}
                >
                  <span className='text-nowrap'>Imprimir informe</span>
                  <i className='ssp-print_outline ms-2 font-l'></i>
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import { useAppointment } from "../../customHooks/useAppointment";
import { useAuth } from "../../customHooks/useAuth";
import { useDate } from "../../customHooks/useDate"
import { useReminder } from "../../customHooks/useReminder";
import {  Button } from "react-bootstrap"
import { useState } from "react";
import { ChooseActivityModal } from "../shared/modals/ChooseActivityModal";
import { AppointmentDetailModal } from "../shared/modals/AppointmentDetailModal";

export const WelcomeComponent = () => {
  const { fullDate } = useDate()
  const { urgent, general } = useReminder({})
  const total = urgent.length + general.length;
  const { appointments } = useAppointment({})
  const { user } = useAuth();
  const [ChooseActivityShow, ChooseActivitySetShow] = useState(false);
  const handleChooseActivityShow = () => ChooseActivitySetShow(true);
  const handleChooseActivityClose = () => ChooseActivitySetShow(false);

  const [AppointmentDetailShow, AppointmentDetailSetShow] = useState(false);
  const handleAppointmentDetailShow = () => AppointmentDetailSetShow(true);
  const handleAppointmentDetailClose = () => AppointmentDetailSetShow(false);
  return (
    <>
    <div className="card flex-row align-items-center justify-content-between bg-prim10 radius-20 p-4 px-lg-5 mb-4">
        <div>
          <h1 className="fw-bold font-xl mb-2">Hola, <span className="color-prim">{user.name}</span></h1>
          <p className="font-l color-prim70">Hoy es {fullDate()}</p>
        </div>
       
       <Button className="btn prim align-items-center px-3" onClick={handleChooseActivityShow}>
         <div className="bg-white icon-circle ">
           <i className="ssp-add color-prim font-l"></i>
         </div>
         <div className="ps-3 text-center">
           <p className="color-white mb-1 pt-2">Crear: Cita, medición o nota</p>
         </div>
       </Button>
      </div>

      <ChooseActivityModal ChooseActivityShow={ChooseActivityShow} handleChooseActivityClose={handleChooseActivityClose} />
      <AppointmentDetailModal AppointmentDetailShow={AppointmentDetailShow} handleAppointmentDetailClose={handleAppointmentDetailClose} />
      </>
  )
}

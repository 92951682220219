import * as yup from 'yup';
import i18next from 'i18next';

const phone = /^[679]{1}[0-9]{8}$/
const dni = /^([0-9-a-zA-Z])(\d{7})([0-9A-Z])$/;

export const employeeValidationSchema = yup.object().shape({
  name: yup.string().required(i18next.t(`employee.validationSchema.name`)),
  surname: yup.string().required(i18next.t(`employee.validationSchema.surname`)),
  nif: yup.string().required(i18next.t(`employee.validationSchema.nif`)).matches(dni, 'Debe introducir un DNI valido'),
  telephone: yup.string().required(i18next.t(`employee.validationSchema.telephone`)).matches(phone, 'Debe introducir un teléfono valido'),
  position: yup.string().required(i18next.t(`employee.validationSchema.position`)),
  email: yup.string().email(i18next.t(`register.validationSchema.beEmail`)).required(i18next.t(`register.validationSchema.email`)),
});

export const initialEmploueeValues = { email: "",name: "",surname: "",nif: "", telephone: "", position: "" }
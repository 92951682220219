import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json"
import es from "./locales/es.json"

const resources = {
  en: {
    global: en,
  },
  es: {
    global: es
  }
} as const;

i18n
.use(initReactI18next)
.init({
  returnNull: false,
  react: {
    useSuspense: false,
  },
  ns: ['global'],
  fallbackLng:['en', 'es'] ,
  lng: "es", // if you're using a language detector, do not define the lng option
  debug: true,
  interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});


import Modal from 'react-bootstrap/Modal';
import React, {Key, useEffect, useState} from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import moment from "moment";
import {capitalize} from "../../../../helpers";
import {measurementById} from "../../../../module/reducers/measurement/slice";
import {
  measurementByIdPatient,
  measurementByIdService,
  measurementByPatientService
} from "../../../../api/services/measurement.service";
import {usePatient} from "../../../customHooks/usePatient";
import {IPatient} from "../../../../interfaces";


interface IProps {
  SeeHealthServiceSurveyShow: boolean,
  handleSeeHealthServiceSurveyClose: () => void,
  serviceId: string;
  patient?: IPatient;
}

export const SeeHealthServiceSurveyModal: React.FC<IProps> = ({ SeeHealthServiceSurveyShow, handleSeeHealthServiceSurveyClose, serviceId, patient}) => {

    const [healthSurvey, setHealthSurvey] = useState<any[]>([])
    useEffect(() => {
        if(patient?.health_service_surveys){
            setHealthSurvey(patient?.health_service_surveys[serviceId])
        }
    }, [serviceId])


  return (
    <Modal className="modal-meditions" size='xl' show={SeeHealthServiceSurveyShow} onHide={handleSeeHealthServiceSurveyClose}>
      <Modal.Header className='justify-content-between' closeButton>
        <h5 className='fw-bold'>Encuesta servicio de salud {serviceId.charAt(0).toUpperCase()
            + serviceId.slice(1).replace('_', ' ')}</h5>
      </Modal.Header>
      <Modal.Body>
          <div className='bg-prim5 p-3 radius-8 mt-3'>
          <Row className='align-items-stretch'>
              {healthSurvey && healthSurvey.length > 0 && healthSurvey.map((question, key) => {
                  const { questionID, type, values, value, title } = question;
                  const answerValue = value
                  return (
                      <>
                          <Col xl={6} className='h-auto mb-2' key={key}>
                              <div
                                  key={key}
                                  className='h-100 radius-8 p-3 mb-2 bg-white d-flex align-items-center justify-content-between '
                              >
                                  <p className='fw-normal'>{title}</p>
                                  <div className='d-inline-flex' key={key}>
                                      {type === "radio" &&
                                          values?.map(
                                              (
                                                  values: { value: any; label: any },
                                                  key: Key | null | undefined
                                              ) => {
                                                  const { value, label } = values;
                                                  return (
                                                      <div key={key}>
                                                          <Form.Check
                                                              className='ms-4 me-0'
                                                              inline
                                                              reverse
                                                              readOnly={true}
                                                              label={`${label}`}
                                                              name={questionID}
                                                              type='radio'
                                                              id={`${questionID}-radio-${label.toLowerCase()}`}
                                                              key={key}
                                                              checked={value === answerValue}
                                                          />
                                                      </div>
                                                  );
                                              }
                                          )}
                                  </div>
                              </div>
                          </Col>
                      </>
                  );
              })}
          </Row>
          </div>
          <br></br>
      </Modal.Body>
    </Modal>
  );
}

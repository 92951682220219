

import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { NewAppointmentPage } from '../../../pages/appointments/NewAppointment';

interface IProps {
  patientID?: string;
  NewAppointmentShow: boolean;
  handleNewAppointmentClose: () => void;
}

export const NewAppointmentModal: React.FC<IProps> = ({
  patientID,
  NewAppointmentShow,
  handleNewAppointmentClose,
}): JSX.Element => {
  const { id } = useParams();
  return (
    <Modal
      size='lg'
      show={NewAppointmentShow}
      onHide={handleNewAppointmentClose}
    >
      <Modal.Header className='justify-content-between' closeButton>
        <h5 className='fw-bold'>Nueva cita</h5>
      </Modal.Header>
      <Modal.Body>
        <NewAppointmentPage
          patientID={patientID}
          handleNewAppointmentClose={handleNewAppointmentClose}
        />
      </Modal.Body>
    </Modal>
  );
};
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IFilterReminder, IReminder } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../module/hooks"
import { setNotification } from "../../module/reducers/notification";
import { updatePatientById } from "../../module/reducers/patient";
import { findPatientById } from "../../module/reducers/patient/slice";
import { newReminder, updateFilters } from "../../module/reducers/reminder";
import { completeReminder, deleteReminder, getGeneralReminders, getGeneralRemindersByPatient, getUrgentReminders, getUrgentRemindersByPatient, registerReminder, reminderById, updateReminder } from "../../module/reducers/reminder/slice";
import { routes } from "../../router/constants";

export const useReminder = ({ patient, id, fetch = false }: { patient?: string, id?: string; fetch?: boolean }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { list, status, reminder, filters, general, urgent } = useAppSelector(state => state.reminder)

  useEffect(() => {
    if(patient && id && status === 'nofetched') dispatch(reminderById({id, patient}))
  }, [dispatch, id, patient, status])

  useEffect(() => {
    if (patient && status === 'nofetched' && fetch) {
      dispatch(getGeneralRemindersByPatient(patient))
      dispatch(getUrgentRemindersByPatient(patient))
    }
    if (!patient && status === 'nofetched' && fetch) {
      dispatch(getGeneralReminders())
      dispatch(getUrgentReminders())
    }
  }, [patient, dispatch, filters, status, fetch])

  const handleCreateNewAppointment = async (fields: IReminder, setSubmitting: (isSubmitting: boolean) => void) => {
    const { code, message } = await dispatch(registerReminder(fields)).unwrap();
    setSubmitting(false);
    if (code! >= 400) return code! >= 500 ? dispatch(setNotification({ message: "Ha ocurrido un error al guardar el recordatorio", status: "danger", show: true, type: "toast" })) : dispatch(setNotification({ message: message!, status: "danger", show: true, type: "toast" }))
    navigate(routes.reminders)
    return dispatch(setNotification({ message: "Recordatorio agregado exitosamente", status: "success", show: true, type: "toast" }))
  }
  const handleReminder = async (reminder: Partial<IReminder>) => {
    if (Object.keys(reminder).includes('patient')) {
      const { content } = await dispatch(findPatientById(reminder.patient!)).unwrap();
      dispatch(updatePatientById({ id: reminder.patient, checked: true }))
      reminder.patientInfo = content;
    }
    return dispatch(newReminder(reminder))
  }
  const handleUpdateReminder = async (fields: Partial<IReminder>, setSubmitting?: (isSubmitting: boolean) => void) => {
    const { code, message } = await dispatch(updateReminder(fields)).unwrap();
    if (setSubmitting) setSubmitting(false);
    if (code! >= 400) return code! >= 500 ? dispatch(setNotification({ message: "Ha ocurrido un error al guardar el recordatorio", status: "danger", show: true, type: "toast" })) : dispatch(setNotification({ message: message!, status: "danger", show: true, type: "toast" }))
    navigate(-1)
    dispatch(setNotification({ message: "Recordatorio actualizado exitosamente", status: "success", show: true, type: "toast" }))
  }

  const handleCompleteReminder = async (fields: Partial<IReminder>, setSubmitting?: (isSubmitting: boolean) => void) => {
    const { code, message } = await dispatch(completeReminder(fields)).unwrap();
    if (setSubmitting) setSubmitting(false);
    if (code! >= 400) return code! >= 500 ? dispatch(setNotification({ message: "Ha ocurrido un error al guardar el recordatorio", status: "danger", show: true, type: "toast" })) : dispatch(setNotification({ message: message!, status: "danger", show: true, type: "toast" }))
    dispatch(setNotification({ message: "Recordatorio actualizado exitosamente", status: "success", show: true, type: "toast" }))
  }

  const del = async (reminder: IReminder) => {
    const { error, message, code } = await dispatch(deleteReminder(reminder)).unwrap();
    if (error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    dispatch(setNotification({ message: "Recordatorio eliminada exitosamente", status: "success", show: true, type: "toast" }))
  }

  const handleFilter = async (filter: keyof IFilterReminder, value: number | string) => dispatch(updateFilters({ ...filters, [filter]: value }))

  const nextDay = () => dispatch(updateFilters({ ...filters, start_date: moment(filters.start_date).add(1, 'd').format('YYYY-MM-DD'), end_date: moment(filters.end_date).add(1, 'd').format('YYYY-MM-DD') }))
  const previousDay = () => dispatch(updateFilters({ ...filters, start_date: moment(filters.start_date).subtract(1, 'd').format('YYYY-MM-DD'), end_date: moment(filters.end_date).subtract(1, 'd').format('YYYY-MM-DD') }))

  const validations = (step: number): boolean => {
    if(step === 1 && !reminder.patient) return true
    if(step === 2 && !reminder.reason) return true
    if(step === 3 && (!reminder.employee_id)) return true
    if(step === 4 && (!reminder.periodicity_hour)) return true
    return false
  }

  return {
    reminders: list,
    createReminder: handleCreateNewAppointment,
    general: general || [],
    urgent: urgent || [],
    handleReminder,
    handleFilter,
    nextDay,
    previousDay,
    handleUpdateReminder,
    handleCompleteReminder,
    handleDeleteReminder: del,
    validations,
    filters,
    reminder,
    status
  }
}
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Datepicker } from '../../../components/shared/Datepicker';
import { useAppointment } from '../../../customHooks/useAppointment';
import { IPatient } from '../../../../interfaces';
import { useAppDispatch } from '../../../../module/hooks';
import { useEffect, useState } from 'react';
import { serviceList } from '../../../../module/reducers/service/slice';
import { appointmentList } from "../../../../api/services/appointment.service";
import { clearStatus } from '../../../../module/reducers/reminder';
import { SimpleAppointmentCard } from '../../../components/appointment/SimpleAppointmentCard';
import moment from 'moment';
import {IAppointment} from "../../../../interfaces/appointment.interfaces";
import * as _ from "lodash";

export const NewAppointmentStep3 = ({ patient }: { patient?: IPatient }) => {
  const dispatch = useAppDispatch()
  const [next, setNext] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<String>(moment().format("YYYY-MM-DD"));
  const appointmentPerRow = 1;

  const [appointments, setAppointments] = useState<IAppointment[]>([])

  useEffect(()=>{
    appointmentList('perPage=100').then((response) =>{
      if(response.code == 200){
        const copiedAppointments = _.cloneDeep(response.content).sort((a, b) => a?.periodicity_hour && b?.periodicity_hour && (a?.periodicity_hour > b?.periodicity_hour) ? 1 : -1)
        setAppointments(copiedAppointments)
      }
    })
  }, [])

  const {
    filters,
    handleAppointment,
    appointment,
  } = useAppointment({ patient: patient?.id || undefined });
  
  useEffect(() => {
    if (!patient) dispatch(serviceList())
  }, [dispatch, patient, filters])
  useEffect(() => () => { dispatch(clearStatus()) }, [dispatch])
  const handleSelectDate = (date: string) => {
    //console.log("----",date)
    setSelectedDate(date)
    handleAppointment({ periodicity_start_date: date })
  }
  
  const handleMore: () => void = () => {
    setNext(next + appointmentPerRow);
  };
  
  const times = [];
  const start = 8 * 60; // 8am in minutes
  const end = 20 * 60; // 8pm in minutes
  for (let i = start; i <= end; i += 30) {
    const hour = Math.floor(i / 60);
    const minute = i % 60;
    const timeStr = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
    if(selectedDate === moment(new Date()).format('YYYY-MM-DD')){
      if(timeStr > moment(new Date()).format('HH:mm')){
        times.push(<option value={timeStr}>{timeStr}</option>);
      }
    }else{
      times.push(<option value={timeStr}>{timeStr}</option>);
    }
  }
  return (
    <Row>
      <Col lg={7}>
        <h3 className='font-ml fw-bold mb-3 color-sec'>Fecha y hora</h3>
        <label className='font-s color-dark70 fw-lighter'>
          Selecciona un día:
        </label>
        <Datepicker
          handleSelectDate={handleSelectDate}
          selected={appointment.periodicity_start_date}
        />

        <Form.Group
          className='form-group select with-icon mt-4'
          controlId='appointment-hour'
        >
          <Form.Label className='mb-4'>Selecciona una hora:</Form.Label>
          <Form.Select
            aria-label='appointment-motive'
            value={appointment.periodicity_hour}
            onChange={({ target }) =>
              handleAppointment({ periodicity_hour: target.value })
            }
          >
            <option>Elige la hora</option>
            {times}
          </Form.Select>
          <i className='ssp-time'></i>
        </Form.Group>
      </Col>
      <Col lg={5} className=''>
        <label className='font-s color-dark70 fw-lighter mt-4 pt-3 mb-4'>
          Disponibilidad de ese día:
        </label>

        {appointments.filter((appointment) => appointment.periodicity_start_date === selectedDate) && appointments.filter((appointment) => appointment.periodicity_start_date === selectedDate).length > 0 ? (
          appointments.filter((appointment) => appointment.periodicity_start_date === selectedDate).slice(0, next).map((appointment, key) => (
            selectedDate === appointment.periodicity_start_date && 
              (<div className='appointment-list' key={key}>
              <SimpleAppointmentCard
                key={key}
                patientData={patient}
                {...appointment}
                cardType='simple'
              />
            </div>)
          ))
        ) : (
          <div>
            <span className='appointment empty message d-flex align-items-center justify-content-center w-100'>No hay datos disponibles.</span>
           
          </div>
        )}
        {next < appointments.filter((appointment) => appointment.periodicity_start_date === selectedDate).length && (
          <Button
            onClick={handleMore}
            className='w-100 prim30 color-sec justify-content-center align-items-center font-s radius-8'
          >
            Ver más <i className='ssp-chevron-down ms-2 font-m'></i>
          </Button>
        )}
      </Col>
      <Col lg={12}>
        <Form.Group className='form-group' controlId='reminder-resume'>
          <Form.Label className='mb-4'>
            Puedes añadir una nota adicional:
          </Form.Label>
          <Form.Control
            as='textarea'
            placeholder='Escríbe aquí tu nota'
            style={{ minHeight: "60px" }}
            value={appointment.motive}
            onChange={({ target }) =>
              handleAppointment({ motive: target.value })
            }
          />
        </Form.Group>
      </Col>
    </Row>
  );
}



import Modal from 'react-bootstrap/Modal';
import {  Form, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useReminder } from '../../../customHooks/useReminder';
import { TablePatient } from '../../patient/TablePatient';
import { NewPatientModal } from './NewPatientModal';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { usePatient } from '../../../customHooks/usePatient';
import { IPatient } from '../../../../interfaces/patient.interfaces';
import { NewAppointmentModal } from './NewAppointmentModal';
import { useAppointment } from '../../../customHooks/useAppointment';
import { routes } from '../../../../router/constants';
import { useMeasurement } from '../../../customHooks/useMeasurement';
import { useNote } from '../../../customHooks/useNote';
import { NewNoteModal } from '../../../pages/patients/NewNoteModal';
import { TablePatientModal } from '../../patient/TablePatientModal';

interface IProps {
  title: string;
  type: string;
  NewActivityShow: boolean;
  handleNewActivityClose: () => void;
}

export const NewActivityModal: React.FC<IProps> = ({
  title,
  type,
  NewActivityShow,
  handleNewActivityClose,
}): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { handleAppointment } = useAppointment({});
  const { handleMeasurement } = useMeasurement();
  const { handleNote } = useNote();
  const [NewPatientShow, setNewPatientShow] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(6);
  const [patient, setPatient] = useState<IPatient>();
  const [titleButton, setTitleButton] = useState<string>("");
  const [NewAppointmentShow, setNewAppointmentShow] = useState<boolean>(false);
  const handleNewPatientShow = () => setNewPatientShow(true);
  const handleNewPatientClose = () => setNewPatientShow(false);
  const [NewNoteShow, NewNoteSetShow] = useState<boolean>(false);
  const handleNewNoteShow = () => NewNoteSetShow(true);
  const handleNewNoteClose = () => NewNoteSetShow(false);


  const handlePatientSelected = (patient: IPatient) => {

    if(type === 'visit'){
      setTimeout(() => {
        setPatient(patient)
      }, 1000)
    }else{
      setPatient(patient)
    }

    if(type === 'appointment'){
      handleAppointment({
        patient: patient.id,
        patientInfo: patient,
      });
    }
    if(type === 'visit'){
      handleMeasurement({
        patient: patient.id,
        patientInfo: patient,
      });
    }
    if(type === 'note'){
      handleNote({
        patient: patient.id,
        patientInfo: patient,
      });
    }
    

  };
  const handleNewAppointmentClose = () => setNewAppointmentShow(false);

  const checkModal = () => {
    if(document.getElementById('new-appointment-modal')){
      handleNewActivityClose()
    }else{
      setNewAppointmentShow(true)
    }
  }

  const auxHandlerNewActivityClose = () =>{
    setPatient(undefined)
    handleNewActivityClose()
  }

  useEffect(() => {
    type === 'visit' ? setTitleButton(`Empezar medición`) : setTitleButton(`Crear ${title.toLocaleLowerCase()}`);
  }, [type, title]);

  return (
    <>
      <Modal size='lg' show={NewActivityShow} onHide={auxHandlerNewActivityClose}>
        <Modal.Header className='justify-content-between' closeButton>
          <h5 className='fw-bold'>{`${title}`}</h5>
        </Modal.Header>
        <Modal.Body>
        <p className='text-left fw-light color-dark50 font-s mb-3'>
            Crea un nuevo paciente:
          </p>

          <div className='bg-dark5 radius-12 p-3 d-flex align-items-center '>
            <div className='icon-circle big bg-prim30'>
              <i className='ssp-accesibility color-prim font-xl'></i>
            </div>
            <div className='ps-3 w-100'>
              <p className='fw-bold font-m mb-2'>Nuevo paciente</p>
              <p className='fw-light font-s'>
              Añade un nuevo paciente
              </p>
            </div>

            <Button className=' alt-btn' onClick={handleNewPatientShow}>
              <span>Nuevo paciente</span>
              <i className='ssp-person-add icon-circle'></i>
            </Button>
          </div>
          <p className='text-left fw-light color-dark50 font-s mt-4'>
            O busca al paciente en tu listado:
          </p>

          {/* Controlar el numero de columnas que se muestran y los filtros de la tabla */}
          <TablePatientModal
            perPage={perPage}
            selectFilter={false}
            checkedOption={true}
            handlePatientSelected={handlePatientSelected}
          />

          
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button className='prim outline' onClick={auxHandlerNewActivityClose}>
            Cancelar
          </Button>
          {patient?.id ? (
            <Button
              className='prim'
              type='submit'
              onClick={() =>
                type === "appointment"
                  ? checkModal()
                  : type === "visit"
                  ? navigate(routes.newMedition)
                  : NewNoteSetShow(true)
              }
            >
              {titleButton}
            </Button>
          ) : (
            <Button className='prim' type='submit' disabled>
              {titleButton}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <NewPatientModal
        NewPatientShow={NewPatientShow}
        handleNewPatientClose={handleNewPatientClose}
      />
      <NewAppointmentModal
        patientID={patient?.id}
        NewAppointmentShow={NewAppointmentShow}
        handleNewAppointmentClose={handleNewAppointmentClose}
      />
      <NewNoteModal
        NewNoteShow={NewNoteShow}
        handleNewNoteClose={handleNewNoteClose}
      />
    </>
  );
};

import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { routes } from "../../../router/constants"

export const EmptyComponent = () => {
  return (
    <Container className="services-empty-state">
      
        <Row className="mt-3">
          <Col lg={12}>

          <div>
              <span className='appointment empty message d-flex align-items-center justify-content-center w-100 mb-0'>No hay datos disponibles.</span>
          </div>

          </Col>
         

        </Row>

    
    </Container>
  )
}

interface EmptyProps { to: string; buttonText: string; h1?: string; h2: string; h3?: string }

export const EmptyTextComponent = ({ to, buttonText, h1, h2, h3 }: EmptyProps) => {
  return (
    <Container fluid className="services-empty-state">
      <Container>
        <Row className="my-5">
          <Col lg={12} className="text-center mb-4">
            <div className="text-center font-xl fw-light lh-1 mb-3">{h1} <br /><span className="d-block fw-bold color-prim my-2">{h2}</span>{h3}</div>

            <Link className="btn prim my-2" to={to}>{buttonText}</Link>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

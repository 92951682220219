
import moment from 'moment';
import { useState } from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import es from 'date-fns/locale/es';
registerLocale('es', es)

export const Datepicker = ({ selected, handleSelectDate }: { selected: string, handleSelectDate: (date: string) => void}) => {
   const [startDate, setStartDate] = useState(new Date());
  return (
    <div className="datepicker-wrapper">
      <DatePicker
        locale={es}
        minDate={new Date()}
        calendarStartDay={1}
        selected={selected ? new Date(selected) : new Date()}
        onChange={(date: Date) => handleSelectDate(moment(date).format('YYYY-MM-DD'))}
        inline
      />
    </div>
  );
};
import { Container, Form, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { NewVisitStep1 } from './new-visit-steps/NewVisitStep1';
import { NewVisitStep2 } from './new-visit-steps/NewVisitStep2';
import { NewVisitStep3 } from './new-visit-steps/NewVisitStep3';
import { NewVisitStep4 } from './new-visit-steps/NewVisitStep4';
import { NewMeditionStep1 } from './new-medition-steps/NewMeditionStep1';
import { NewMeditionStep2 } from './new-medition-steps/NewMeditionStep2';
import { NewMeditionStep3 } from './new-medition-steps/NewMeditionStep3';
import { NewMeditionStep4 } from './new-medition-steps/NewMeditionStep4';
import { useEffect, useState } from 'react';
import { serviceList } from '../../../module/reducers/service/slice';
import { useAppDispatch } from '../../../module/hooks';
import { useMeasurement } from '../../customHooks/useMeasurement';
import { BasicLoader } from '../../components/shared/loaders';
import { clearStatus } from '../../../module/reducers/measurement';
import { MeasurementModal } from '../../components/shared/modals/MeasurementModal';
import { ChangePatientModal } from '../../components/shared/modals/ChangePatientModal';
import {IHealthSurveyAnswer} from "../../../interfaces";

export const NewMeditionPage = () => {
  const navigate = useNavigate();
  let [step, setStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false)
  const [measurementModalShow, setMeasurementModalShow] =
    useState<boolean>(false);
  const { patient, measurementId } = useParams()
  const {
    handleCreateNew,
    measurement,
    handleUpdateMeasurement,
    validations,
    handleMeasurement,
    updateHealthServiceSurvey
  } = useMeasurement(patient, measurementId);
  const endSteps = () => {
    // eslint-disable-next-line no-restricted-globals
    history.go(-1)
    //navigate(`/`)
    // setLoading(true)
    // patient && measurementId ? handleUpdateMeasurement(measurement, setLoading) : handleCreateNew(measurement, setLoading)
  }
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(serviceList());
    return () => {
      dispatch(clearStatus())
    }
  }, [dispatch]);

  const [healthServiceSurvey, setHealthServiceSurvey] = useState<{patientID: string, serviceID: string, answers: IHealthSurveyAnswer[]}>()
  const handleValueChange = (patientID: string, serviceID: string, answers: IHealthSurveyAnswer[]) => {
    setHealthServiceSurvey({patientID, serviceID, answers});
  };

  const handleMeasurementModalClose = () => setMeasurementModalShow(false);
  const nextStep = () => {
    if (measurement.visit_type === "primera_visita" && step === 1) {
      setMeasurementModalShow(true);
    } else if (measurement.measurement_step === 3 && step === 2) {
      handleMeasurement({
        measurement_step: 4,
      });
    } else if (measurement.measurement_step === 4 && step === 2) {
      if(healthServiceSurvey && healthServiceSurvey.patientID){
        updateHealthServiceSurvey(healthServiceSurvey.patientID, healthServiceSurvey.serviceID, healthServiceSurvey.answers)
      }
      handleMeasurement({
        measurement_step: 5,
      });
    } else if (measurement.measurement_step === 6 && step === 2) {
      // handleMeasurement({
      //   measurement_step: 5,
      // });
      setStep(step + 1);
    } else {
      setStep(step + 1);
    }
  };
  const prevStep = () => {

    if (step === 2){
      if(measurement.measurement_step == 3 || measurement.measurement_step == 2){ //only god know
        setStep(step - 1);
        handleMeasurement({
          measurement_step: measurement.measurement_step - 2,
        });
      }else{
        handleMeasurement({
          measurement_step: measurement.measurement_step - 1,
        });
      } 
    } else {
      setStep(step - 1);
    }
  };

  const { patientInfo } = measurement;

  useEffect(() => {
    !patientInfo && navigate('/')
  }, [patientInfo]);

  useEffect(() => {
    if (
      (step === 1 && measurement.measurement_step === 2) ||
      (step === 2 && measurement.measurement_step === 6)
    ) {
      setStep(step + 1);
    }
  }, [measurement.measurement_step]);




  const [NewPatientShow, setNewPatientShow] = useState<boolean>(false);
  const handleNewPatientShow = () => setNewPatientShow(true);
  const handleNewPatientClose = () => setNewPatientShow(false);

  return (
    <>
      <Container className='pb-5'>
        <div>
          <Link className='btn transparent' to='/'>
            <i className='ssp-chevron-left me-1'></i>
            <span className='font-s'>Volver</span>
          </Link>
        </div>
        <div className='d-flex align-items-center justify-content-between mt-4'>
          <h1 className='fw-bold font-xxml mb-4'>Nueva medición farmacéutica</h1>
        </div>

        <div className='d-flex align-items-center'>
          <label className='color-prim fw-light font-s'>Paciente:</label>

          <div className='d-flex bg-prim10 align-items-center radius-24 p-2 mx-3'>
            <div className='bg-prim30 icon-circle '>
              <i className='ssp-person color-prim font-l'></i>
            </div>
            <div className='px-2 text-start font-s'>
              <p className='color-dark fw-semibold lh-1'>
                {patientInfo?.name} {patientInfo?.surname}
              </p>
            </div>
          </div>

          <Button onClick={handleNewPatientShow} className='btn transparent color-prim font-xs p-0'>
            Editar
          </Button>
        </div>

        <div className='steps mb-5 mt-5 px-5 pb-4'>
          {/* El orden de los botones estÃ¡ al reves por necesidades de maquetacion, por css se invierte para asi poder aplicar estilos adecuadamente */}

          <div
            className={
              (measurement.measurement_step > 4 ? "completed " : "") +
              (measurement.measurement_step !== 4 && measurement.measurement_step < 4 ? "disabled" : "")
            }
          >
            <span className='dot'></span>
            <p className='fw-semibold font-s'>
              <i className='ssp-checkmark-circle'></i>
              <span>Finalizar medición</span>
            </p>
          </div>

          <div className='bar'></div>

          <div
            className={
              (measurement.measurement_step > 3 ? "completed " : "") +
              (measurement.measurement_step !== 3 && measurement.measurement_step < 3 ? "disabled" : "")
            }
          >
            <span className='dot'></span>
            <p className='fw-semibold font-s'>
              <i className='ssp-checkmark-circle'></i>
              <span>Resultados</span>
            </p>
          </div>

          <div className='bar'></div>

          <div
            className={
              (measurement.measurement_step > 2 ? "completed " : "") +
              (measurement.measurement_step !== 2 && measurement.measurement_step < 2 ? "disabled" : "")
            }
          >
            <span className='dot'></span>
            <p className='fw-semibold font-s'>
              <i className='ssp-checkmark-circle'></i>
              <span>Mediciones</span>
            </p>
          </div>

          <div className='bar'></div>

          <div
            className={
              (measurement.measurement_step > 1 ? "completed " : "") +
              (measurement.measurement_step !== 1 && measurement.measurement_step < 1 ? "disabled" : "")
            }
          >
            <span className='dot'></span>
            <p className='fw-semibold font-s'>
              <i className='ssp-checkmark-circle'></i>
              <span>Datos de la medición</span>
            </p>
          </div>
        </div>

        <Form>
          {step === 1 && <NewMeditionStep1 patient={patientInfo}/>}
          {step === 2 && <NewVisitStep2 />}
          {step === 3 && <NewVisitStep4 />}
          {/* {step === 4 && <NewVisitStep4 />} */}
        </Form>

        <div className='d-flex align-items-center justify-content-end mt-5'>
          {step > 1 &&
            step < 3
               && (
                <Button className='prim outline' onClick={prevStep}>
                  Anterior
                </Button>
              )}

          {/* Poner la clase disabled cuando corresponda */}
          {step < 3 && (
            <Button
              className='prim ms-2'
              disabled={validations(step)}
              onClick={nextStep}
            >
              Siguiente
            </Button>
          )}

          {step === 3 && (
            <Button className='prim ms-2' onClick={endSteps}>
              Terminar
            </Button>
          )}
        </div>
      </Container>
      {measurement.health_service_id &&
        measurement.health_service_id.length > 0 &&
        measurement.patient &&
        measurement.visit_type === "primera_visita" && (
          <>
            <MeasurementModal
              patientID={measurement.patient}
              measurementModalShow={measurementModalShow}
              handleMeasurementModalClose={handleMeasurementModalClose}
              serviceID={measurement.health_service_id[0]}
              onValueSurveyChange={handleValueChange}
            />
          </>
        )}

{measurement.patient && (
        <ChangePatientModal
          title={"Nueva medición"}
          type={"visit"}
          NewActivityShow={NewPatientShow}
          handleNewActivityClose={handleNewPatientClose}
        />
      )}


    </>
  );
}

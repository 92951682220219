import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { routes } from '../../../router/constants';
import { NoteList } from '../../components/note/NoteList';

export const AllNotesPage = () => {
  const { id } = useParams()
  return (
    <Container className="pb-5">
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="fw-bold font-xxml mb-4">Todas las notas</h1>
        <Link className="btn prim" to={routes.patientNewNote(id)}>Nueva nota</Link>
      </div>
      <div>
        <Link className="btn transparent" to={routes.patientArchive(id)}>
          <i className="ssp-chevron-left me-1"> </i>
          <span className="font-s">Volver a la ficha del paciente</span>
        </Link>
      </div>
      <div className="mt-4 pt-2">
        <NoteList />
      </div>
    </Container>
  )
}

import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { Key, useEffect, useState } from 'react';
import { ReminderCard } from '../../components/shared/ReminderCard';
import { useReminder } from '../../customHooks/useReminder';
import { usePatient } from '../../customHooks/usePatient';
import { routes } from '../../../router/constants';
import { FileMeasurementPatient } from '../../components/measurement/FileMeasurementPatient';
import { HomeAppointments } from '../../components/appointment/HomeAppointments';
import { NotePatientFile } from '../../components/note/NotePatientFile';
import { useAppDispatch } from '../../../module/hooks';
import { clearStatus } from '../../../module/reducers/reminder';
import { NoReminders } from '../../components/home/NoReminders';
import { getDaysBetweenDates } from '../../../helpers/dates';
import { ArchivePatientModal, DeletePatientModal } from '../../components/patient/PatientFileModals';
import { PatientInfo } from '../../components/patient/PatientFileInfo';
import { NewAppointmentModal } from '../../components/shared/modals/NewAppointmentModal';
import { SimpleAppointmentCard } from '../../components/appointment/SimpleAppointmentCard';
import { NewActivityModal } from '../../components/shared/modals/NewActivityModal';
import { HealthMeasurementSurveyQuestions } from '../../../api/services/data/measurementData';
import { groupBy } from 'lodash';
import { measurementById } from '../../../module/reducers/measurement/slice';
import DateAppointments from "../../components/appointment/DateAppointments";
import { useAppointment } from "../../customHooks/useAppointment";
import HealthSurveyModal from '../../components/shared/modals/HealthSurveyModal';
import { useMeasurement } from '../../customHooks/useMeasurement';
import { useNote } from '../../customHooks/useNote';
import { capitalize } from '../../../helpers';
import { measurementByPatientService, measuresByPatientService } from "../../../api/services/measurement.service";
import * as _ from 'lodash';
import moment from "moment";
import { SeeMeditionModal } from '../../components/shared/modals/SeeMeditionModal';
import { SeeHealthServiceSurveyModal } from "../../components/shared/modals/SeeHealthServiceSurveyModal";

export const PatientFilePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { patient, deletePatient } = usePatient(id);

  const { handleAppointment, appointment } = useAppointment({});
  const { handleMeasurement, measurement } = useMeasurement();
  const { handleNote, note } = useNote();

  const [deleteShow, deleteSetShow] = useState(false);
  const [archiveShow, archiveSetShow] = useState(false);
  const [healthSurveyShow, healthSurveySetShow] = useState(false);
  const [readOnlyHealthSurvey, setReadOnlyHealthSurvey] = useState(false);
  const [NewAppointmentShow, setNewAppointmentShow] = useState<boolean>(false);
  const [patientId, setPatientId] = useState(window.location.pathname.split('/').pop())
  const [measuresDetailList, setMeasuresDetialList] = useState<any[]>([])
  const handleDeleteShow = () => deleteSetShow(true);
  const handleDeleteClose = () => deleteSetShow(false);
  const handleArchiveShow = () => archiveSetShow(true);
  const handleArchiveClose = () => archiveSetShow(false);
  const handleNewAppointmentClose = () => setNewAppointmentShow(false);
  const handleNewAppointmentShow = () => setNewAppointmentShow(true);
  const handleSeeMeditionClose = () => setSeeMeditionShow(false);
  const handleSeeHealthServiceSurveyClose = () => setSeeHealthServiceSurveyShow(false);
  const handleSeeMeditionShow = (items: any) => {
    const listAux = measuresList.filter((measure) => measure.service_id !== 'mediciones_adicionales')
    const id = items[0].questionID.replace('servicio_', '').replace('_01', '')
    const measureAux = measuresList.filter((measure) => measure.service_id === id)
    let listAuxDetail = measuresList.filter((measure) => measure.updatedAt === measureAux[0].updatedAt)
    listAuxDetail.map((measure) => {
      const index = listAux.indexOf(measure)
      if (index != -1) {
        measure.survey = allServicesListPayload[index]
      }
    })
    setMeasuresDetialList(listAuxDetail)
    setSeeMeditionShow(true);
  }

  const handleSeeHealthServiceSurveyShow = (serviceId: string) => {
    setSelectedServiceId(serviceId)
    setSeeHealthServiceSurveyShow(true)
  }

  const [servicesListPayload, setServicesListPayload] = useState<any[]>([]);
  const [NewActivityShow, setNewActivityShow] = useState<boolean>(false);
  const [healthSurveyModalShow, setHealthSurveyModalShow] = useState<boolean>(false);
  const [SeeMeditionShow, setSeeMeditionShow] = useState<boolean>(false);
  const [SeeHealthServiceSurveyShow, setSeeHealthServiceSurveyShow] = useState<boolean>(false);
  const [selectedServiceId, setSelectedServiceId] = useState<string>('')
  const [title, setTitle] = useState<string>("");
  const [activityType, setActivityType] = useState<string>("");
  const [allServicesListPayload, setAllServicesListPayload] = useState<any[]>([]);
  const [measuresList, setMeasuresList] = useState<any[]>([]);
  const [measurementGrouped, setMeasurementGrouped] = useState<any[]>([])
  const [measurementFilter, setMeasurementFilter] = useState<string>(
    // HealthMeasurementSurveyQuestions[0].serviceID
    ''
  );

  const handleNewActivityShow = (title: string, type: string): void => {
    if (type === "appointment") {
      handleAppointment({
        patient: patient ?.id,
        patientInfo: patient,
      });
    }
    if (type === "visit") {
      handleMeasurement({
        patient: patient ?.id,
        patientInfo: patient,
      });
    }
    if (type === "note") {
      handleNote({
        patient: patient ?.id,
        patientInfo: patient,
      });
    }
    setTitle(title);
    setActivityType(type);
    // setNewActivityShow(true);
  };
  const handleNewActivityClose = () => setNewActivityShow(false);
  const handleHealthSurveyModalClose = () => setHealthSurveyModalShow(false);
  const { urgent, general, filters, nextDay, previousDay } = useReminder({
    patient: id,
    fetch: true,
  });
  const [hasMeasurements, setHasMeasurements] = useState<boolean>(false);
  const { appointments } = useAppointment({
    patient: patientId || undefined,
  });
  const [next, setNext] = useState<number>(2);
  const appointmentPerRow = 4;
  const handleMore: () => void = () => {
    setNext(next + appointmentPerRow);
  };

  /* Defining an array of objects which contains information about different levels of results for a medical service. */
  const resultLevel = [
    {
      title: "NIVEL 1: RESULTADOS CORRECTOS",
      description:
        "El resultado de todas las mediciones de este servicio están dentro de la normalidad.",
      color: "green",
    },
    {
      title: "NIVEL 2: NECESITA CONSEJO FARMACÉUTICO",
      description:
        "El resultado de al menos una de las mediciones requiere de seguimiento farmacéutico.",
      color: "orange",
    },
    {
      title: "NIVEL 3: DERIVAR AL MÉDICO",
      description:
        "El resultado de al menos una de las mediciones requiere atención médica.",
      color: "red",
    },
  ];

  const handlerMaxMin = (item: any, value: string) => {
    let { min, max, reverse } = item.limits;
    let measurementLevelColor = "";
    if (value !== "") {
      if (min && max) {
        if (reverse) {
          const temp = min;
          min = max;
          max = temp;
        }
        const valueNumber = parseInt(value);
        if (valueNumber < min) {
          reverse
            ? (measurementLevelColor = "red")
            : (measurementLevelColor = "green");
        }
        else if (valueNumber >= max) {
          reverse
            ? (measurementLevelColor = "green")
            : (measurementLevelColor = "red");
        } else {
          measurementLevelColor = "orange";
        }
      }
    }


    if(value !== '' && item.questionID.includes('piel')){
      if(item.questionID.includes('_01')){
        value == 'Normal' ? measurementLevelColor = 'green' : value == 'Media' ? measurementLevelColor = 'orange' : measurementLevelColor = 'red'
      }
      if(item.questionID.includes('_02')){
        parseInt(value) < 30 ? measurementLevelColor = 'red' : parseInt(value) < 55 && parseInt(value) > 30 ? measurementLevelColor = 'orange' : measurementLevelColor = 'green'
      }
      if(item.questionID.includes('_03')){
        parseInt(value) < 5 ? measurementLevelColor = 'red' : parseInt(value) < 25 && parseInt(value) > 5 ? measurementLevelColor = 'orange' : measurementLevelColor = 'green'
      }
      if(item.questionID.includes('_04')){
        parseInt(value) < 70 && parseInt(value) > 40 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_05')){
        parseInt(value) < 40 && parseInt(value) > 15 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_06')){
        parseInt(value) < 65 && parseInt(value) > 30 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_07')){
        parseInt(value) < 45 && parseInt(value) > 20 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_08')){
        parseInt(value) < 25 && parseInt(value) > 5 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_09')){
        measurementLevelColor = 'green'
      }
      if(item.questionID.includes('_10')){
        value == 'Normal' ? measurementLevelColor = 'green' : value == 'Muy seca' ? measurementLevelColor = 'red' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_11')){
        value == 'Normal' ? measurementLevelColor = 'green' : value == 'Grasa' ? measurementLevelColor = 'red' : measurementLevelColor = 'orange'
      }
    }
    return measurementLevelColor;
  }

  const handlerHealthSurvey = (edit: boolean) => {
    setReadOnlyHealthSurvey(edit);
    setHealthSurveyModalShow(true);
  }

  useEffect(
    () => () => {
      dispatch(clearStatus());
    },
    [dispatch]
  );

  useEffect(() => {
    measuresByPatientService(patientId).then((value) => {
      const array = value.content
      const groups = _.groupBy(array, 'service_id')
      const aux = Object.keys(groups).map((key) => _.get(groups, key).sort((a, b) => a ?.createdAt && b ?.createdAt && (a.createdAt < b.createdAt) ? 1 : -1)[0])
      setMeasuresList(aux)
    })
  }, [])

  useEffect(() => {
    let hasSurvey = false
    if (patient && patient.measurement_surveys) {
      var valores = Object.values(patient ?.measurement_surveys);
      var resultado = valores.some(function (valor) {
        return valor !== false;
      });
      setHasMeasurements(resultado)
    }
  }, [patient ?.measurement_surveys]);

  const getFilteredMeasurementOptions = () => { //FIX
    const allowKeysMeasure = Object.keys(patient ?.measurement_surveys || {})
    const measureFiltered = allowKeysMeasure.filter(x => Array.isArray(patient ?.measurement_surveys[x]) && patient ?.measurement_surveys[x] ?.length > 0)
    let result = measureFiltered.map(item => ({ serviceID: item }))
      .filter(x => x.serviceID != "mediciones_adicionales") // TODO se agrega el filtro para excluir mediciones_adicionales
    return result
  }

  useEffect(() => {
    const measurementOptions = getFilteredMeasurementOptions()
    const grouped = groupBy(
      measurementOptions,
      "serviceID"
    ) as any;
    setMeasurementGrouped(grouped)
    if (grouped.length > 0) setMeasurementFilter(grouped[0].serviceID)
  }, [patient ?.measurement_surveys])


  useEffect(() => {
    const servicesListAll: any[] = [];
    Object.keys(measurementGrouped).forEach((key: any) => {
      const item = patient ?.measurement_surveys[key] ?.map((x: any) => ({ ...x, serviceID: measurementFilter }))
      servicesListAll.push(item);
    });
    if (measurementFilter !== "todos") {
      // const measurementFound = HealthMeasurementSurveyQuestions?.filter(
      //   (el: any) => el.serviceID === measurementFilter
      // );
      const measurementFound = patient ?.measurement_surveys[measurementFilter] ?.map((x: any) => ({ ...x, serviceID: measurementFilter }))
      setServicesListPayload([measurementFound]);
    } else {
      // setServicesListPayload([]);
      setServicesListPayload(servicesListAll);
    }
    setAllServicesListPayload(servicesListAll)
  }, [measurementFilter, servicesListPayload]);

  useEffect(() => {
    setTimeout(() => setMeasurementFilter("todos"), 2000) // TODO Mejorar.
  }, [])

  useEffect(() => {
    if (patient) {
      activityType === "appointment" &&
        appointment.patientInfo &&
        setNewAppointmentShow(true);
      activityType === "visit" &&
        measurement.patientInfo &&
        navigate(routes.newMedition);
    }
    setPatientId(window.location.pathname.split('/').pop())
  }, [
    activityType,
    measurement.patientInfo,
    appointment.patientInfo,
    patient,
  ]);

  useEffect(() => {
    let hasSurvey = false
    patient ?.health_survey ?.forEach((item: { questionID: any; value: any }) => {
      //console.log("----",item)
      if (item.value !== '') {
        hasSurvey = true
      }
    });
    healthSurveySetShow(hasSurvey);
  }, [patient ?.health_survey]);

  const checkDate = (item: any) => {
    let date;
    const id = item.questionID.replace('servicio_', '').replace('_01', '')
    measuresList.map((measure) => {
      if (measure.service_id == id) {
        date = measure.createdAt
      }
    })
    if (date) {
      date = moment(new Date(date)).format('DD/MM/YYYY HH:mm')
    }
    return date
  }
  const getFPSValue = (item: any) => {
    let value;
    if(parseInt(item.value) < 50){
      parseInt(item.value) >= 1 && parseInt(item.value) <= 10 ? value = 50 : parseInt(item.value) >= 11 && parseInt(item.value) <= 23 ? value = 40 : parseInt(item.value) >= 24 && parseInt(item.value) <= 35 ? value = 35 : value = 30
    }else{
      parseInt(item.value) >= 50 && parseInt(item.value) <= 62 ? value = 25 : parseInt(item.value) >= 63 && parseInt(item.value) <= 75 ? value = 20 : parseInt(item.value) >= 76 && parseInt(item.value) <= 94 ? value = 15 : value = 10
    }
    return value
  }

  return (
    <>
      <Container className='pb-5'>
        <div>
          <Link className='btn transparent' to='/pacientes'>
            <i className='ssp-chevron-left me-1'></i>
            <span className='font-s'>Volver</span>
          </Link>
        </div>
        <div className='d-flex align-items-center justify-content-between mt-4'>
          <h1 className='fw-bold font-xxml mb-4'>Ficha de paciente</h1>
        </div>
        <PatientInfo id={id} />
        <Row>
          <Col xl={7}>
            <div className='card p-4 mb-4'>
              <div className='d-flex align-items-center justify-content-between'>
                <h3 className='font-xml fw-bold'>Próximas citas</h3>

                <Button
                  className=' alt-btn'
                  onClick={() =>
                    handleNewActivityShow("Nueva Cita", "appointment")
                  }
                >
                  <span>Nueva cita</span>
                  <i className='ssp-calendar-clean icon-circle'></i>
                </Button>
              </div>

              <Row className='mt-4'>
                {/* Descomentar y hacer funcionar el componente */}
                {appointments && appointments.length ? (
                  appointments.slice(0, next).map((appointment, key) => (
                    <Col xl={6}>
                      <div className='appointment-list' key={key}>
                        <SimpleAppointmentCard
                          key={key}
                          patientData={patient}
                          {...appointment}
                          cardType='home'
                        />
                      </div>
                    </Col>
                  ))
                ) : (
                    <Col xl={12}>

                      <div>
                        <span className='appointment empty message d-flex align-items-center justify-content-center w-100 mb-0'>No hay datos disponibles.</span>

                      </div>


                    </Col>
                  )}

              </Row>
              {next < appointments ?.length && (
                <Button
                  onClick={handleMore}
                  className='w-100 prim30 color-sec justify-content-center align-items-center font-s radius-8'
                >
                  Ver más <i className='ssp-chevron-down ms-2 font-m'></i>
                </Button>
              )}
            </div>

            <div className='card p-4  '>
              <div className='d-flex align-items-center justify-content-between mb-4'>
                <h3 className='font-xml fw-bold'>
                  Mediciones de Servicios de Salud
                </h3>

                <Button
                  className=' alt-btn'
                  onClick={() => handleNewActivityShow("Nueva medición", "visit")}
                >
                  <span>Nueva medición</span>
                  <i className='ssp-calendar-clean icon-circle'></i>
                </Button>
              </div>

{hasMeasurements}
              {servicesListPayload.length > 0 &&
                <div className='d-flex align-items-center flex-wrap mb-3'>
                  {/* Quitar/Poner la clase "blue" para que se muestre el botón como activo */}
                  {Object.keys(measurementGrouped).map((key, index) => {
                    const selected = measurementFilter === key ? "blue" : "";
                    return (
                      <Button
                        key={index}
                        className={`badge cursor-pointer ${selected}`}
                        onClick={() => {
                          setMeasurementFilter(key)
                        }}
                      >
                        {capitalize(key)}
                      </Button>
                    );
                  })}

                  <Button
                    onClick={() => setMeasurementFilter("todos")}
                    className={`badge cursor-pointer ${measurementFilter === "todos" && "blue"
                      }`}
                  >
                    Todos
                  </Button>
                </div>
              }

              {/* DIABETES */}
              {servicesListPayload.length > 0 ?
                servicesListPayload.map((items: any, index: number) => {
                  let resultIndex = -1;
                  let arrayAux: string[] = [];
                  return (
                    <>
                      <div className='service-row'>
                        <div>
                          {Array.isArray(items) &&
                            <>
                              <p className='font-s color-dark50 mb-1'>
                                {checkDate(items[0])}
                              </p>
                              <h2 className='font-m fw-semibold'>
                                {items[0] && items[0].groupTitle}
                              </h2>
                            </>
                          }
                          {items ?.map((item: any, index: number) => {
                            const { title, type, unit, value } = item;

                            const levelColor = handlerMaxMin(item, value);
                            arrayAux.push(levelColor)
                            arrayAux.includes('red') ? resultIndex = 2 : arrayAux.includes('orange') ?  resultIndex = 1 : resultIndex = 0
                            return (
                              <div
                                className={`medition-input ${ !item.questionID.includes('piel_09') ? levelColor : ''}`}
                                key={index}
                              >
                                <Form.Group className='form-group'>
                                  <Form.Label>{title}</Form.Label>
                                  {type === "input" && (
                                    <>
                                      <Form.Control
                                        type='text'
                                        placeholder='Dato'
                                        disabled
                                        value={value}
                                      />
                                      <span className='unit'>{unit}</span>
                                    </>
                                  )}
                                  {type === "select" && (
                                      <>
                                        <Form.Control
                                            type='select'
                                            disabled
                                            value={value}
                                        />
                                      </>
                                  )}
                                </Form.Group>
                              </div>
                            );
                          })}
                          {items && items[0] && items[0].questionID.includes('piel') && (
                              <div className='mt-4'>
                                  <h4 className='text-uppercase font-s fw-normal'>
                                  PIGMENTACIÓN: <span>{getFPSValue(items[8])} FPS</span>
                                  </h4>
                              </div>
                          )}
                          {resultIndex >= 0 && (
                            <div
                              className={`results ${resultLevel[resultIndex].color}`}
                            >
                              <h4 className='text-uppercase font-s fw-normal'>
                                {resultLevel[resultIndex].title}
                              </h4>
                              <p className='font-s'>
                                {resultLevel[resultIndex].description}
                              </p>
                            </div>
                          )}
                        </div>



                        <div>
                          {items && items[0] &&
                            <div>
                              <span className={`service-chip border-left-${items[0].serviceID}`}>
                                {items[0] && capitalize(items[0].serviceID)}
                              </span>

                              <div className='medition-img mt-4'>

                                <img
                                  src={`/assets/img/measurement-${resultIndex}.png`}
                                  alt=''
                                />

                                <p className='font-s color-prim fw-semibold text-decoration-underline mt-3 cursor-pointer text-end' onClick={() => handleSeeMeditionShow(items)} >
                                  Ver medición
                              </p>
                              </div>
                            </div>

                          }
                        </div>


                      </div>

                    </>
                  );
                })
                :

                <div>
                  <span className='appointment empty message d-flex align-items-center justify-content-center w-100 mb-0'>No hay datos disponibles.</span>
                </div>


              }
            </div>
          </Col>
          <Col xl={5}>
            <NotePatientFile {...patient!} />

            <div className='card p-4 mt-4 '>
              <div className='d-flex align-items-center justify-content-between mb-4'>
                <h3 className='font-xml fw-bold'>Estado de salud</h3>

                <Button
                  className='alt-btn'
                  onClick={() => handlerHealthSurvey(false)}
                >
                  <span>Actualizar encuesta de salud</span>
                  <i className='ssp-document icon-circle'></i>
                </Button>
              </div>

              {healthSurveyShow ?
                <div>
                  <div className='bg-dark5 radius-12 p-3 d-flex align-items-center mt-3'>
                    <div className='icon-circle big bg-prim'>
                      <i className='ssp-document font-xl color-white'></i>
                    </div>
                    <div className='ps-3 w-100'>
                      <p className='fw-light font-xs mb-2'></p>
                      <div
                        onClick={() => handlerHealthSurvey(true)}
                        className='fw-bold font-m color-sec text-decoration-underline cursor-pointer'
                      >
                        Ver encuesta de salud
                        </div>
                    </div>
                  </div>
                </div>
                :

                <div>
                  <br></br>
                  <span className='appointment empty message d-flex align-items-center justify-content-center w-100 mb-0'>No hay encuesta de salud disponible.</span>
                </div>
              }

              {patient && patient.health_service_surveys ?
                Object.entries(patient.health_service_surveys).map((value) => {
                  const name = value[0]
                  if (value[1]) {
                    return (
                      <>
                        <div className='bg-dark5 radius-12 p-3 d-flex align-items-center mt-3'>
                          <div className='icon-circle big bg-prim'>
                            <i className='ssp-document font-xl color-white'></i>
                          </div>
                          <div className='ps-3 w-100'>
                            <p className='fw-light font-xs mb-2'></p>
                            <div
                              onClick={() => handleSeeHealthServiceSurveyShow(name)}
                              className='fw-bold font-m color-sec text-decoration-underline cursor-pointer'
                            >
                              Encuesta de {name.charAt(0).toUpperCase()
                                + name.slice(1).replace('_', ' ')}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                  return (
                    <>
                    </>
                  )
                })
                :
                <div>
                  <span className='appointment empty message d-flex align-items-center justify-content-center w-100 mb-0'>No hay datos disponibles.</span>
                </div>
              }

            </div>

          </Col>
        </Row>
        <div className='text-end mt-5'>
          <Button className=' red outline me-3' onClick={handleDeleteShow}>
            Eliminar ficha
          </Button>
          {/*<Button className='prim' onClick={handleArchiveShow}>
            Archivar ficha
          </Button>*/}
        </div>
      </Container>

      {/* Modal de eliminar */}
      <DeletePatientModal
        deleteShow={deleteShow}
        handleDeleteClose={handleDeleteClose}
        deletePatient={deletePatient}
        id={id!}
      />
      {/* Modal de archivar */}
      <ArchivePatientModal
        archiveShow={archiveShow}
        handleArchiveClose={handleArchiveClose}
      />
      <NewAppointmentModal
        patientID={patient ?.id}
        NewAppointmentShow={NewAppointmentShow}
        handleNewAppointmentClose={handleNewAppointmentClose}
      />
      <NewActivityModal
        NewActivityShow={NewActivityShow}
        handleNewActivityClose={handleNewActivityClose}
        title={title}
        type={activityType}
      />
      <SeeMeditionModal
        SeeMeditionShow={SeeMeditionShow}
        handleSeeMeditionClose={handleSeeMeditionClose}
        measuresList={measuresDetailList}
        patient={patient}
      />
      <SeeHealthServiceSurveyModal
        SeeHealthServiceSurveyShow={SeeHealthServiceSurveyShow}
        handleSeeHealthServiceSurveyClose={handleSeeHealthServiceSurveyClose}
        patient={patient}
        serviceId={selectedServiceId}
      />
      {id && (
        <HealthSurveyModal
          title={"Encuesta de salud"}
          healthSurveyModalShow={healthSurveyModalShow}
          handleHealthSurveyModalClose={handleHealthSurveyModalClose}
          patientID={id}
          readOnly={readOnlyHealthSurvey}
        />
      )}
    </>
  );
}

import { get } from "../../helpers";
import { del, post, put } from "../../helpers/restClient";
import { IResponseList, IPatient, IResponseDetail, IHealthSurveyAnswer } from "../../interfaces";

export const patientByIdService = async (id: string): Promise<IResponseDetail<IPatient>> =>
  get(`/api/patient/${id}`);

export const patientListService = async (params?: string): Promise<IResponseList<IPatient>> =>
  get(`/api/patients?${params}`);

export const createPatient = async (patient: IPatient): Promise<IResponseDetail<IPatient>> =>
  post(`/api/patient`, patient);

export const updatePatientService = async (patient: IPatient): Promise<IResponseDetail<IPatient>> =>
  put(`/api/patient/${patient.id}`, patient);

export const deletePatientService = async (id: IPatient['id']): Promise<IResponseList<Partial<IPatient>>> =>
  del(`/api/patient/${id}`);

export const updateHealthSurveyPatientService = async (patientID: string, answers: IHealthSurveyAnswer[]): Promise<IResponseDetail<IHealthSurveyAnswer[]>> =>
  put(`/api/patient/${patientID}/health-survey`, answers);

import { Formik } from "formik"
import { useEffect, useState } from 'react';
import moment from "moment"
import { Button, Col, Form, Row } from "react-bootstrap"
import { IService } from "../../../interfaces"
import { useService } from "../../customHooks/useService"
import { InputField } from "../shared/fields"
import { BasicLoader } from "../shared/loaders"
import * as yup from 'yup';

export const CardComponent = (service: IService) => {
  const { handleChangeEdit, handleCheckedUpdateService, handleUpdateService } = useService()
  const validationSchema = yup.object().shape({
    name: yup.string().required("Nombre del servicio es requerido"),
    machine: yup.string().required("Maquinaria del servicio es requerido"),
  });
  const [expandService, setExpandService] = useState<boolean>(false)

  const handlerExpand = () => {
    setExpandService(true);
  };
  const handlerCloseExpand = () => {
    setExpandService(false);
  };

  const getServiceClass = (serv: any, chip?: boolean) => {
    console.log(serv)
    let serRetunr = ''
    switch (serv) {
      case ('osteoporosis'):
        serRetunr = 'pink'
        break;
      case ('diabetes'):
        if (chip) {
          serRetunr = 'dark-pink'
        } else {
          serRetunr = 'dark-pink'
        }

        break;
      case ('riesgo_cardiovascular'):
        serRetunr = 'red'
        break;
      case ('composicion_corporal'):
        serRetunr = 'green'
        break;
      case ('retorno_venoso'):
        serRetunr = 'granate'
        break;

      case('piel'):
        serRetunr = 'blue'
        break;
    }
    return serRetunr
  }
  console.log("......", service)


  const getServiceText = (serv: any) => {
  
    let serRetunr = ''
    switch (serv) {
      case ('osteoporosis'):
        serRetunr = 'Pacientes con riesgo de descalcificación ósea como son las personas de edad avanzada y las mujeres a partir del climaterio.'
        break;
      case ('diabetes'):
        serRetunr = 'Dirigido a pacientes de riesgo por edad, peso, perímetro abdominal y hábitos de vida no saludables para una detección precoz. Pacientes ya diagnosticados que necesiten hacer un seguimiento de estos'
        break;
      case ('riesgo_cardiovascular'):
        serRetunr = 'Dirigido a pacientes de riesgo por edad, peso, perímetro abdominal y hábitos de vida no saludables para una detección precoz. Pacientes ya diagnosticados que necesiten hacer un seguimiento de estos.'
        break;
      case ('composicion_corporal'):
        serRetunr = 'Dirigido a pacientes con inquietud o necesidad de conocer el posible desequilibrio de su composición corporal por diversas razones: sobrepeso, deportistas, personas mayores con posible pérdida de masa muscular, etc...'
        break;
      case ('retorno_venoso'):
        serRetunr = 'Pacientes con riesgo de varices, arañas vasculares e interesados en la prevención o corrección de la celulitis, retención de líquidos, piernas cansadas...'
        break;
      case ('piel'):
        serRetunr = 'Pacientes con necesidad de conocer lo que su piel necesita. Pacientes que presenten alguna anomalía en la piel'
        break;
    }
    return serRetunr
  }

  /* Cambiar la claseo por border-left-[color]. Los colores que puede tener: 
  blue, dark-blue, 
  red, light-pink, 
  dark-pink, green, 
  granate, purple, 
  grey
   */
  return (

    <Col xl={6}>
      {/* Los colores que puede tener: blue, dark-blue, red, light-pink, dark-pink, green, granate, purple, grey */}
      <div className={`service-card ${getServiceClass(service.service.id)}`}>

        <div className="image-wrapper">
          <img src={require(`../../../theme/images/${service.service.id}.png`)} />
        </div>

        <div className="p-3 d-flex align-items-stretch justify-content-between border-left card-content">
          <div className="pe-3">
            <p className="font-s fw-semibold mb-4">Servicio de Salud</p>
            <h4 className="font-m fw-bold color-prim mb-2">Sandoz Servicio de {service.service.label}</h4>
            <p className="font-xs color-dark50 mb-2">Equipo Utilizado</p>
            <p className="font-s fw-light">{service.service.label}</p>
          </div>

          <div className="justify-content-between d-flex flex-column align-items-end">
            <div className="d-inline-flex flex-column justify-content-end align-items-end">
              <span className={`service-chip border-left-${getServiceClass(service.service.id, true)}`}>{service.service.label}</span>
              <span className='service-chip '>Sandoz</span>
            </div>


            <span className='service-chip fw-semibold expandable cursor-pointer mt-3 p-0' >
              {!expandService ? <span className="d-flex p-2 lh-1" onClick={() => handlerExpand()}>¿A quién va dirigido?</span> : <span className="p-2 d-flex align-items-center justify-content-between lh-1">¿A quién va dirigido? <i className="ssp-close color-dark ms-2" onClick={() => handlerCloseExpand()}></i></span>}

              {expandService ?
                <div className="color-dark70 font-xs fw-light p-2 pt-0">
                  <p className="mb-0">{getServiceText(service.service.id)}</p>
                </div>
                :
                null}
            </span>
          </div>

        </div>

      </div>
    </Col>

  )
}

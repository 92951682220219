import moment from "moment";
import { Accordion, Col, Form, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { routes } from "../../../router/constants";
import { usePatient } from "../../customHooks/usePatient";
import { BasicLoader } from "../shared/loaders";
import { ChooseActivityModal } from "../shared/modals/ChooseActivityModal";
import { useState } from "react";
import { IContact } from "../../../interfaces";
import { NewPatientModal } from "../shared/modals/NewPatientModal";

export const PatientInfo = ({ id }: { id?: string }) => {
  const { patient, pathologies, patientStatus: status } = usePatient(id)
  const [ChooseActivityShow, ChooseActivitySetShow] = useState(false);
  const [NewPatientShow, setNewPatientShow] = useState<boolean>(false);

  const handleChooseActivityShow = () => ChooseActivitySetShow(true);
  const handleChooseActivityClose = () => ChooseActivitySetShow(false);
  const handleNewPatientClose = () => setNewPatientShow(false);
  const handleNewPatientShow = () => setNewPatientShow(true);



  const getPatientContact = (contacts: IContact[]) => {
    const filteredArray = contacts.filter((contact) => contact.name != '')
    if(filteredArray.length > 0){
      return (

          <div className='bg-dark5 radius-12 p-3'>
            <h4 className='font-s fw-bold color-dark30'>
              Contacto
            </h4>
            {!!filteredArray.length &&
                filteredArray?.map((patient) =>
                    <Row className="mt-4">
                      <Col lg={4}>
                        <div className="form-group readonly mb-0">
                          <label>Tipo de contacto</label>
                          <p><i className="ssp-person me-1"></i>{patient.role == 'FAMILY' ? "Famiiar" : "Cuidador"}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="form-group readonly mb-0">
                          <label>Nombre</label>
                          <p>{patient.name}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="form-group readonly mb-0">
                          <label>Teléfono</label>
                          <p>{patient.phone}</p>
                        </div>
                      </Col>
                    </Row>
                )}
          </div>
      )
    }else{
      return ""
    }
  }

  return (
    <>
      {status === "fetched" ? (
        <Accordion defaultActiveKey='0' className='mb-4'>
          <div className='fake-accordion-header'>
            <div className='d-flex align-items-center'>
              <div className='bg-prim10 rounded-circle p-3 d-inline-flex align-items-center justify-content-center pharmacy-logo'>
                <i className='lh-1 ssp-person color-prim font-xl'></i>
              </div>

              <div className='ps-4 d-inline-flex align-items-center'>
                <h2 className='font-xl fw-bold color-dark text-nowrap'>
                  {patient?.name} {patient?.surname}
                </h2>
                {patient?.health_card ? (
                  <p className='color-dark50 font-l fw-normal mt-2'>
                    {patient?.health_card}
                  </p>
                ) : null}
              </div>
            </div>

            <Button
              className='btn prim align-items-center px-3'
              onClick={handleChooseActivityShow}
            >
              <div className='bg-white icon-circle '>
                <i className='ssp-add color-prim font-l'></i>
              </div>
              <div className='ps-3 text-start'>
                <p className='color-white mb-1'>Crear nueva actividad</p>
                <p className='color-prim30 fw-light font-xs'>
                 Cita, medición o nota
                </p>
              </div>
            </Button>
          </div>
          <Accordion.Item eventKey='0'>
            <Accordion.Body>
              <div className='card p-4 no-shadow'>
                <div className='d-flex justify-content-between mb-4 align-items-center'>
                  <h4 className='fw-bold font-ml mb-4'>Datos de contacto</h4>

                  {/* <Link
                    className='btn prim10 color-prim align-items-center font-s px-2 py-1 text-nowrap fw-normal'
                    to={`${routes.editPatient}/${patient?.id}`}
                  >
                    <i className='ssp-brush font-m me-1'></i>
                    <span className='lh-1'>Editar</span>
                  </Link> */}
                  <Button
                    className='btn prim10 color-prim align-items-center font-s px-2 py-1 text-nowrap fw-normal'
                    onClick={handleNewPatientShow}
                  >
                    <span className='lh-1'>Editar</span>
                    <i className='ssp-brush font-m me-1'></i>
                  </Button>
                </div>

                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col lg={6}>
                        <div className='form-group readonly'>
                          <label>NIF</label>
                          <p>{patient?.nif}</p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className='form-group readonly'>
                          <label>Teléfono</label>
                          <p>{patient?.telephone}</p>
                        </div>
                      </Col>
                      {/*<Col lg={6}>
                        <div className='form-group readonly'>
                          <label>Dirección postal</label>
                          <p>{patient?.address}</p>
                        </div>
                      </Col>*/}
                      <Col lg={6}>
                        <div className='form-group readonly'>
                          <label>Correo electrónico</label>
                          <p>{patient?.email}</p>
                        </div>
                      </Col>
                      {/*<Col lg={6}>
                        <div className='form-group readonly'>
                          <label>Población</label>
                          <p>Ciudad</p>
                        </div>
                      </Col>*/}
                      <Col lg={6}>
                        <div className='form-group readonly mb-lg-0'>
                          <label>Preferencia de contacto</label>
                          <p>{patient?.contact_by}</p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className='form-group readonly'>
                          <label>Fecha de nacimiento</label>
                          <p>
                            {moment(patient?.birthdate).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                      {!!patient?.contacts?.length && patient?.contacts?.length > 0 &&
                          getPatientContact(patient?.contacts)
                      }
                  </Col>

                {/*   <Col lg={12}>
                    <div className='form-group readonly mb-lg-0'>
                      <label>Notas del paciente</label>
                      <div
                        className='p-3 border-dark30 radius-8'
                        style={{ minHeight: "30px" }}
                      >
                        <p>{patient?.note}</p>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Accordion.Body>
            <Accordion.Header>
              <span className='font-s fw-semibold lh-1 collapsed-shown'>
                Ver más
              </span>
              <span className='font-s fw-semibold lh-1'>Ver menos</span>
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      ) : (
        <div className='loader-center' style={{ height: 400 }}>
          <BasicLoader />
        </div>
      )}

      <ChooseActivityModal
        ChooseActivityShow={ChooseActivityShow}
        handleChooseActivityClose={handleChooseActivityClose}
        source='patientDetails'
        patientID={id}
      />
      {id && (
        <NewPatientModal
          NewPatientShow={NewPatientShow}
          handleNewPatientClose={handleNewPatientClose}
          patientID={id}
        />
      )}
    </>
  );
}

import { Container, Form, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { NewReminderStep1 } from './new-reminder-steps/NewReminderStep1';
import { NewReminderStep2 } from './new-reminder-steps/NewReminderStep2';
import { NewReminderStep3 } from './new-reminder-steps/NewReminderStep3';
import { NewReminderStep4 } from './new-reminder-steps/NewReminderStep4';
import { NewReminderStep6 } from './new-reminder-steps/NewReminderStep6';
import { useState } from 'react';
import { routes } from '../../../router/constants';
import { useReminder } from '../../customHooks/useReminder';
import { BasicLoader } from '../../components/shared/loaders';
export const NewReminderPage = () => {
  const [loading, setLoading] = useState(false)
  const { patient, reminderId: id } = useParams()
  let [step, setStep] = useState(1);
  const { createReminder, handleUpdateReminder, reminder, validations } = useReminder({patient, id})

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const endSteps = () => {
    setLoading(true)
    id ? handleUpdateReminder(reminder, setLoading) : createReminder(reminder, setLoading)
  }
  return (
    <Container className="pb-5">


      <div className="d-flex align-items-center justify-content-between">
        <h1 className="fw-bold font-xxml mb-4">Crear nuevo recordatorio</h1>
      </div>

      <div>
        <Link className="btn transparent" to={routes.reminders}>
          <i className="ssp-chevron-left me-1"></i>
          <span className="font-s">Volver a todos los recordatorios</span>
        </Link>
      </div>

      <div className="steps mt-4 mb-5 justify-content-between w-100 pt-3">
        <div className={"d-inline-flex align-items-center me-lg-4 me-3 " + (step > 1 ? 'completed ' : '') + (step !== 1 && step < 1 ? 'disabled' : '')}>
          <span className="square-number me-2">1</span>
          <p className="fw-semibold font-s">Seleccionar paciente</p>
        </div>
        <div className={"d-inline-flex align-items-center me-lg-4 me-3 " + (step > 2 ? 'completed ' : '') + (step !== 2 && step < 2 ? 'disabled' : '')}>
          <span className="square-number me-2">2</span>
          <p className="fw-semibold font-s">Motivo del recordatorio</p>
        </div>
        <div className={"d-inline-flex align-items-center me-lg-4 me-3 " + (step > 3 ? 'completed ' : '') + (step !== 3 && step < 3 ? 'disabled' : '')}>
          <span className="square-number me-2">3</span>
          <p className="fw-semibold font-s">Recomendación</p>
        </div>
        <div className={"d-inline-flex align-items-center me-lg-4 me-3 " + (step > 4 ? 'completed ' : '') + (step !== 4 && step < 4 ? 'disabled' : '')}>
          <span className="square-number me-2">4</span>
          <p className="fw-semibold font-s">Fecha del recordatorio</p>
        </div>
        <div className={"d-inline-flex align-items-center me-lg-4 me-3 " + (step > 6 ? 'completed ' : '') + (step !== 5 && step < 5 ? 'disabled' : '')}>
          <span className="square-number me-2">6</span>
          <p className="fw-semibold font-s">Confirmar nuevo recordatorio</p>
        </div>
      </div>

      <Form>
        {step === 1 && <NewReminderStep1 />} 
        {step === 2 && <NewReminderStep2 />} 
        {step === 3 && <NewReminderStep3 />} 
        {step === 4 && <NewReminderStep4 />} 
        {step === 5 && <NewReminderStep6 />} 
      </Form>

      <div className="d-flex align-items-center justify-content-end mt-5">

        {step > 1 && <Button className="prim outline" onClick={prevStep}>Anterior</Button>}

        {/* Poner la clase disabled cuando corresponda */}
        {step < 5 &&  <Button className="prim ms-2" disabled={validations(step)} onClick={nextStep}>Siguiente</Button>} 


        {step === 5 &&  <Button className="prim ms-2" onClick={endSteps}>{loading ? <BasicLoader /> : id ? 'Actializar' : 'Terminar'}</Button>} 
       

      </div>
    </Container>

  )
}

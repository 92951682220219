import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { routes } from '../../../router/constants';
import { FilterTable } from '../../components/employee/FilterTable';
import { TableEmployee } from '../../components/employee/TableEmployee';
import { useAuth } from '../../customHooks/useAuth';
import { useState } from 'react';
import { ExternalModal } from '../../components/shared/modals/ExternalModal';
import { Button } from 'react-bootstrap';

export const ProfilePage = () => {
  const { user } = useAuth()
  const handleExternalWorkerShow = () => setExternalWorkerShow(true);
  const handleExternalWorkerClose = () => setExternalWorkerShow(false);
  const handleExternalEditShow = () => setExternalEditShow(true);
  const handleExternalEditClose = () => setExternalEditShow(false);
  const [externalWorkerShow, setExternalWorkerShow] = useState<boolean>(false);
  const [externalEditShow, setExternalEditShow] = useState<boolean>(false);
  const { REACT_APP_NEW_WORKER, REACT_APP_EDIT_PHARMACY } = process.env;

  return (
    <>
      <Container className="pb-5">
        <div className="card flex-row align-items-center justify-content-between bg-prim5 radius-20 mb-4 pharmacy-header">
          <div className="d-flex align-items-center justify-content-between">
            <div className="bg-prim10 rounded-circle p-3 me-3 d-inline-flex align-items-center justify-content-center pharmacy-logo">
              <i className="lh-1 ssp-cross color-prim font-xxml"></i>
            </div>

            <div className="pe-xl-5 pe-3">

              <p className="fw-light font-m text-start color-dark70 text-nowrap color-prim50 mb-1">{user.address}</p>
              <h1 className="fw-bold font-xxml mb-0 text-start color-prim">{user.name}</h1>
            </div>

            <div className="border-left">
              <label className="color-prim50 fw-semibold font-s mb-2">Teléfono</label>
              <p className="font-s fw-light">{user.telephone}</p>
            </div>
            <div className="border-left">
              <label className="color-prim50 fw-semibold font-s mb-2">Nombre del titular</label>
              <p className="font-s fw-light">{user.owner_name} {user.owner_surname}</p>
            </div>
            <div className="border-left">
              <label className="color-prim50 fw-semibold font-s mb-2">Correo electrónico</label>
              <p className="font-s fw-light">{user.email}</p>
            </div>
          </div>
          <div className="d-inline-flex align-items-center">
            <Button onClick={handleExternalEditShow} className="btn prim ms-3 px-4" >Editar datos</Button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mt-5">
          <h1 className="fw-bold font-xxml mb-0">Equipo de la farmacia <span className="color-dark30 font-xl fw-light ps-1">(1)</span></h1>

          <Button className='btn alt-btn' onClick={handleExternalWorkerShow}>
            <span>Añadir nuevo trabajador</span>
            <i className='ssp-person-add icon-circle'></i>
          </Button>

        </div>
        <TableEmployee />
      </Container>


    {/* Habría que duplicar el ExternalModal pero con distintos titulo/texto, y que funcionen independientemente */}
      <ExternalModal
        title={'¿Quieres añadir un nuevo trabajador?'}
        text={'Puedes realizarlo desde tu perfil de Sandoz Conectafarm'}
        externalShow={externalWorkerShow}
        handleExternalClose={handleExternalWorkerClose}
        url={REACT_APP_NEW_WORKER ? REACT_APP_NEW_WORKER : ''}
      />
 
      <ExternalModal
        title={'¿Quieres editar datos?'}
        text={'Puedes realizarlo desde tu perfil de Sandoz Conectafarm'}
        externalShow={externalEditShow}
        handleExternalClose={handleExternalEditClose}
        url={REACT_APP_EDIT_PHARMACY ? REACT_APP_EDIT_PHARMACY : ''}
      />
    </>
  )
}

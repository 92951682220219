import { useTranslation } from "react-i18next"
import 'moment/locale/es'
import 'moment/locale/en-in'
import moment from "moment";
export const useDate = () => {
  const { i18n } = useTranslation();
  const getFullDate = () => {
    moment.locale(i18n.language);
    return moment().format('LL')
  }
  return {
    lang: i18n.language,
    fullDate: getFullDate
  }
}

import { AxiosResponse } from "axios";
import { del, get, post, put } from "../../helpers";
import { DataErrorResponse, IHealthSurvey, IPatient, IResponseDetail, IResponseList } from "../../interfaces";
import { IMeasurement } from "../../interfaces";

export const measurementByIdService = async (measurement: Partial<IMeasurement>): Promise<IResponseDetail<IMeasurement>> =>
  get(`/api/patient/${measurement.patient}/measurement/${measurement.id}`);

export const measurementByIdPatient = async (patient: string, measurementId: string): Promise<IResponseDetail<IMeasurement>> =>
    get(`/api/patient/${patient}/measurement/${measurementId}`);

export const measurementListService = async (): Promise<IResponseList<Partial<IMeasurement>>> =>
  get(`/api/measurements`);

export const measurementByPatientService = async (patient: IPatient['id']): Promise<IResponseList<IMeasurement>> =>
  get(`/api/patient/${patient}/measures`);

export const measuresByPatientService = async (patient: IPatient['id']): Promise<IResponseList<IMeasurement>> =>
    get(`/api/patient/${patient}/list-measures`);

export const createMeasurementService = async (measurement: Partial<IMeasurement>): Promise<IResponseList<Partial<IMeasurement>>> =>
  post(`/api/patient/${measurement.patient}/measurement`, measurement);

export const updateMeasurementService = async (measurement: Partial<IMeasurement>): Promise<IResponseList<Partial<IMeasurement>>> =>
  put(`/api/patient/${measurement.patient}/measurement/${measurement.id}`, measurement);

export const deleteMeasurementService = async (measurement: Partial<IMeasurement>): Promise<DataErrorResponse | AxiosResponse<any, any>> =>
  del(`/api/patient/${measurement.patient}/measurement/${measurement.id}`);

export const getHealthServiceSurveyService = async (patientID: string,
  serviceID: string): Promise<IResponseList<IHealthSurvey[]>> =>
  get(`/api/patient/${patientID}/health-service-survey/${serviceID}`);
  
export const updateHealthServiceSurveyService = async (patientID: string,
  serviceID: string, answers: any[]): Promise<IResponseList<any>> =>
  put(`/api/patient/${patientID}/health-service-survey/${serviceID}`, answers);

export const getHealthMeasurementSurveyService = async (patientID: string,
  serviceID: string): Promise<IResponseList<IHealthSurvey[]>> =>
  get(`/api/patient/${patientID}/measure/${serviceID}`);

export const updateHealthMeasurementSurveyService = async (patientID: string, answers: any[]): Promise<IResponseList<any>> =>
  post(`/api/patient/${patientID}/measurement`, answers);

import { Table } from "react-bootstrap"
import { Link } from "react-router-dom"

interface EmptyTableProps { to?: any; buttonText?: string; h1?: string; h2?: string; h3?: string }

export const EmptyTable = ({ to, buttonText, h1, h2, h3 }: EmptyTableProps) => {
  return (
    <>
      <div className="table-wrapper empty mt-4">
        <Table striped>
          <thead>
            <tr>
              <th className="bg-dark50"></th>
              <th className="bg-dark50"></th>
              <th className="bg-dark50"></th>
              <th className="bg-dark50"></th>
              <th className="bg-dark50"></th>
              <th className="bg-dark50"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span>

              </td>
              <td>
                <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span>
              </td>
              <td>
                <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span>
              </td>
              <td>
                <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span>
              </td>
              <td>
                <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span> <span className="empty-bar bg-dark30 p-1"></span>
              </td>
              <td>
                <span className="empty-bar bg-dark70 p-2"></span> <span className="empty-bar bg-dark70 p-2"></span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="text-center mt-3 font-xml fw-bold lh-1 mb-4">{h1 || 'Aquí se verán los'} <br /><span className="d-block fw-bold color-prim my-2">{h2}</span>{h3}</div>

   
       {/*  <Link className="btn prim my-3" to={to}>{buttonText}</Link> */}
     


    </>
  )
}

import { useEffect } from "react"
import { Button, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { IFilter } from "../../../interfaces"
import { useAppDispatch } from "../../../module/hooks"
import { employeeList } from "../../../module/reducers/employee/slice"
import { routes } from "../../../router/constants"
import { useEmployee } from "../../customHooks/useEmployee"
import moment from "moment"
import { EmptyTable } from "../shared/EmptyTable"
import { FilterTable } from "./FilterTable"
import { IEmployee } from "../../../interfaces/employee.interfaces"

interface IProps {
  perPage?: number;
  selectFilter?: boolean;
  checkedOption?: boolean;
  handleEmployeeSelected?: (patient: IEmployee) => void;
}

export const TableEmployee: React.FC<IProps> = ({
  perPage,
  selectFilter = true,
  checkedOption = false,
  handleEmployeeSelected,
}): JSX.Element => {

//export const TableEmployee = () => {
  const { employees, filters, handleFilter, deleteEmployee } = useEmployee()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(employeeList())
  }, [dispatch, filters])
  return (
    <>
      {employees.length || (filters.search) ? (
          <>
          <FilterTable selectFilter={selectFilter} />
          <div className="table-wrapper mt-4 m-shadow">
            <Table striped>
              <thead>
                <tr>
                  <th>Nombre del trabajador <Button className='sort-btn'><i className='ssp-swap ms-1 font-m'></i></Button></th>
                  {/* <th>DNI</th>
                  <th>Especialidad <Button className='sort-btn'><i className='ssp-swap ms-1 font-m'></i></Button></th>
                  <th>Cargo <Button className='sort-btn'><i className='ssp-swap ms-1 font-m'></i></Button></th> */}
                  <th>Fecha alta <Button className='sort-btn'><i className='ssp-swap ms-1 font-m'></i></Button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  !!employees.length ? employees.map((employee, key) => (
                    <tr key={key}>
                      <td>
                        <b className="color-prim">{employee.name} {employee.surname}</b>
                      </td>
                      {/*<td>{employee.nif}</td>
                      <td>
                        <div className="d-inline-flex">
                          <span className='service-chip border-left-orange'>
                            test
                          </span>
                        </div>
                      </td>
                      <td>Rol</td>*/}
                      <td>{moment(employee.created_at).format('DD/MM/YYYY')}</td>
                      <td width="150" className="text-nowrap">
                        {/*<Link to={`${routes.editTeammate}/${employee.id}`} className="px-0 btn transparent color-dark50 align-items-center font-s px-3 text-nowrap lh-1 fw-normal"><i className="ssp-brush font-ml me-1"></i><span>Editar perfil</span> </Link>
                        <Button onClick={() => deleteEmployee(employee.id)} className="btn transparent px-0 color-red"><i className="ssp-delete font-ml"></i></Button>*/}
                      </td>
                    </tr>
                  )) : (<tr><td colSpan={5} align="center" className='pt-4'>No tienes pacientes registrados</td></tr>)
                }
              </tbody>
            </Table>
            <div className="pagination">
              <div>
                <label>Filas por página</label>
                <select defaultValue={filters.perPage} name="perPage" onChange={({ target }) => handleFilter(target.name as keyof IFilter, target.value)}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>
              <div>
                <label>1-10 of 100</label>
                <Button disabled><i className="ssp-chevron-left"></i></Button>
                <Button><i className="ssp-chevron-right"></i></Button>
              </div>


            </div>

          </div>
          </>
        ) :
          <div className="text-center">
            <EmptyTable buttonText='Añadir nuevo trabajador' to={routes.newTeammate} h1='Aquí se verá el listado de' h2='los trabajadores' h3='de la farmacia' />
          </div>
      }
    </>
  )
}

import { del, get, post, put } from "../../helpers";
import { IPatient, IReminder, IResponseDetail, IResponseList } from "../../interfaces";

export const reminderByIdService = async (reminder: Partial<IReminder>): Promise<IResponseDetail<IReminder>> =>
  get(`/api/patient/${reminder.patient}/reminder/${reminder.id}`);

export const generalRemindersService = async (params?: string): Promise<IResponseList<IReminder>> =>
  get(`/api/general-reminders${params && '?'+params}`);

export const urgentRemindersService = async (params?: string): Promise<IResponseList<IReminder>> =>
  get(`/api/urgent-reminders${params && '?'+params}`);

export const generalRemindersByPatientService = async (patient: IPatient['id'], params?: string): Promise<IResponseList<IReminder>> =>
  get(`/api/patient/${patient}/general-reminders${params && '?'+params}`);

export const urgentRemindersByPatientService = async (patient: IPatient['id'], params?: string): Promise<IResponseList<IReminder>> =>
  get(`/api/patient/${patient}/urgent-reminders${params && '?'+params}`);
 
export const createReminder = async (reminder: IReminder): Promise<IResponseDetail<IReminder>> =>
  post(`/api/patient/${reminder.patient}/reminder`, reminder);

export const updateReminderService = async (reminder: Partial<IReminder>): Promise<IResponseList<Partial<IReminder>>> =>
  put(`/api/patient/${reminder.patient}/reminder/${reminder.id}`, reminder);

export const completeReminderService = async (reminder: Partial<IReminder>): Promise<IResponseDetail<IReminder>> =>
  put(`/api/patient/${reminder.patient}/reminder/${reminder.id}/completed`, reminder);

export const deleteReminderService = async (reminder: Partial<IReminder>): Promise<IResponseList<Partial<IReminder>>> =>
  del(`/api/patient/${reminder.patient}/reminder/${reminder.id}`);

  ///patient/6a660672-0f07-4eac-8b0f-c2adb262e7a5/reminder/f8362d85-86e7-47c7-bd56-43d6413750f0/completed
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IReminder, IReminderState } from "../../../interfaces/reminder.interfaces";
import { completeReminder, deleteReminder, getGeneralReminders, getGeneralRemindersByPatient, getUrgentReminders, getUrgentRemindersByPatient, reminderById } from "./slice";

export const reminderExtraReducers = (builder: ActionReducerMapBuilder<IReminderState>) => {
  builder
    .addCase(getGeneralReminders.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(getGeneralReminders.fulfilled, (state, action) => {
      state.general = action.payload.content
      state.status = 'fetched'
    })
    .addCase(getUrgentReminders.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(getUrgentReminders.fulfilled, (state, action) => {
      state.urgent = action.payload.content
      state.status = 'fetched'
    })
    .addCase(getGeneralRemindersByPatient.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(getGeneralRemindersByPatient.fulfilled, (state, action) => {
      state.general = action.payload.content
      state.status = 'fetched'
    })
    .addCase(getUrgentRemindersByPatient.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(getUrgentRemindersByPatient.fulfilled, (state, action) => {
      state.urgent = action.payload.content
      state.status = 'fetched'
    })
    .addCase(reminderById.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(reminderById.fulfilled, (state, action) => {
      state.reminder = action.payload.content
      state.status = 'fetched'
    })
    .addCase(deleteReminder.fulfilled, (state, action) => {
      const { id, type } =  action.meta.arg
      const currentType = type === 'Urgente' ? 'urgent' : 'general';
      state[currentType] = state[currentType].filter((reminder: IReminder) => reminder.id !== id)
    })
    .addCase(completeReminder.fulfilled, (state, action) => {
      const currentReminder = action.payload.content;
      const type = currentReminder.type === 'Urgente' ? 'urgent' : 'general';
      state[type] = state[type].map((reminder: IReminder) => reminder.id === currentReminder.id ? {...reminder, completed: currentReminder.completed} : reminder)
    })
}//deleteReminder

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, FC } from 'react';
import { useAuth } from '../../../customHooks/useAuth';

interface IProps {
 title:string,
 text:string,
 externalShow:boolean,
 handleExternalClose:() => void,
 url: string
}

export const ExternalModal: React.FC<IProps> = ({
  title, text, externalShow, handleExternalClose, url
}): JSX.Element => {
  
  
  return <Modal show={externalShow} onHide={handleExternalClose}>
    <Modal.Header className="justify-content-between" closeButton>
      <h5 className="fw-bold">{title}</h5>
    </Modal.Header>
    <Modal.Body>
      <p className="text-left fw-light color-dark50 font-s my-3">{text}</p>
    </Modal.Body>
    <Modal.Footer className="justify-content-end">
    <Button className="btn prim outline" onClick={handleExternalClose}>
          Cancelar</Button>
        <Button className="btn prim">
        <a href={url} target='_blank' className='color-white' rel="noreferrer">Ir a Conectafarm</a></Button>
    </Modal.Footer>
  </Modal>;
}
import { useState, useRef, useEffect } from "react";
import { IAppointment } from "../../../interfaces/appointment.interfaces";
import { IPatient } from "../../../interfaces/patient.interfaces";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../router/constants";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import { capitalize } from "../../../helpers";
import { PrintModal } from "../shared/modals/PrintModal";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { NewAppointmentModal } from "../shared/modals/NewAppointmentModal";
import { useMeasurement } from "../../customHooks/useMeasurement";
import { useAuth } from "../../customHooks/useAuth";


export const SimpleAppointmentCard = (
  appointment: IAppointment & { patientData?: IPatient, type: string },
) => {
  const color = appointment.health_service_name;
  const currentDate = moment(new Date()).format("YYYY-MM-DD")
  const currentHour = moment(new Date()).format("HH:mm")
  const [outdated, setOutdated] = useState<string>('');

  const navigate = useNavigate();
  const { user } = useAuth();
  const [AppointmentDetailShow, AppointmentDetailSetShow] = useState<boolean>(false);
  const [activityType, setActivityType] = useState<string>("");

  const handleAppointmentDetailClose = () => AppointmentDetailSetShow(false);
  const handleAppointmentDetailShow = () => AppointmentDetailSetShow(true);
  const { handleMeasurement, measurement } = useMeasurement();

  const [PrintShow, PrintSetShow] = useState<boolean>(false);
  const [displayPrintOption, setDisplayPrintOption] = useState<boolean>(true);

  const handlePrintShow = () => PrintSetShow(true);
  const handlePrintClose = () => PrintSetShow(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setDisplayPrintOption(false),
  });

  const handlerMeasurementModal = (type: string) => {
    setActivityType(type);
    handleMeasurement({
      patient: appointment ?.patient,
      patientInfo: appointment.patientInfo,
      health_service_id: [appointment.health_service_id, 'mediciones_adicionales'],
      health_service_name: appointment.health_service_name.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase()),
      employee_id: appointment.owner_id,
      employee_complete_name: appointment.owner_complete_name,
    });
    measurement.patientInfo && navigate(routes.newMedition);
  };

  useEffect(() => {
    activityType === "visit" && measurement.patientInfo && navigate(routes.newMedition);
  }, [measurement.patientInfo]);

  useEffect(() => {
    if (appointment.periodicity_start_date < currentDate) {
      setOutdated('outdated')
    } else {
      if (appointment.periodicity_start_date == currentDate && appointment.periodicity_hour < currentHour) {
        setOutdated('outdated')
      } else {
        setOutdated('')
      }
    }
  }, [appointment])

  return (
    <>
      {/* Poner debajo la clase 'outdated' para citas pasadas de fecha */}
      <div
        className={`appointment ${color} ${outdated}`} onClick={handleAppointmentDetailShow}
      >
        <div className='d-flex align-items-center justify-content-between w-100'>
          <p className='font-s fw-semibold'>
            {moment(appointment.periodicity_start_date).format("DD/MM/YYYY")} -{" "}
            {appointment.periodicity_hour}h
          </p>

          <span className={`print-none service-chip border-left-${color}`}>
            {appointment.health_service_name &&
              capitalize(appointment.health_service_name)}
          </span>
        </div>

        <div className='mt-2'>
          <Link
            className='font-m color-prim fw-bold mb-1 text-decoration-underline'
            to={`${routes.patients}/archivo/${appointment ?.patient}`}
          >
            {appointment.patient_name ||
              `${appointment.patientInfo ?.name} ${appointment.patientInfo ?.surname}`}
          </Link>
          <p className='font-s color-dark70 truncate-2rows'></p>
        </div>

        <div className='d-flex align-items-center justify-content-between mt-2 w-100'>
          <div className='d-inline-flex align-items-center'>
            <div className='d-inline-flex color-dark50 me-3'>
              <i className='ssp-storefront font-m me-1'></i>
              <span className='font-xs text-truncate'>
                {capitalize(appointment.type)}
              </span>
            </div>
            <div className='d-inline-flex color-dark50 me-3'>
              <i className='ssp-person font-m me-1'></i>
              <span className='font-xs text-truncate'>
                {appointment.owner_complete_name}
              </span>
            </div>
          </div>

          {/* No mostrar en la modal de AppointmentDetailModal */}
          {appointment.cardType === "home" && outdated !== 'outdated' && (
            <>
              <div
                role='button'
                className='font-xs fw-bold color-prim d-inline-flex'
                onClick={() => handlerMeasurementModal("visit")}
              >
                Iniciar medición <i className='ssp-arrow-right ms-1 font-m'></i>
              </div>
            </>
          )}
        </div>

        {/* Mostrar solo en la modal de AppointmentDetailModal */}
        {appointment.cardType !== "home" && (
          <div className='d-flex align-items-center justify-content-start mt-3 w-100'>
            <div className='form-group readonly mb-0'>
              <label className='fw-normal font-xs mb-0'>
                Quién tomó la cita
              </label>
              <div className='d-inline-flex color-dark50 me-3'>
                <i className='ssp-person font-m me-1'></i>
                <span className='font-xs text-truncate'>
                  {appointment.employee_complete_name}
                </span>
              </div>
            </div>

            <div className='form-group readonly mb-0 ms-4'>
              <label className='fw-normal font-xs mb-0'>
                Fecha en la que se tomó la cita
              </label>
              <div className='d-inline-flex color-dark50 me-3'>
                <span className='font-xs text-truncate'>
                  {moment(appointment.createdAt).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </div>
            </div>
            <div className='form-group readonly mb-0 ms-4 print-show'>
              <label className='fw-normal font-xs mb-0'>
                Farmacia
              </label>
              <div className='d-inline-flex color-dark50 me-3'>
                <span className='font-xs text-truncate'>
                  {user.name}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* LA NUEVA MODAL DE DETALLE CON EL DISEÑO NUEVO, TAMBIEN ESTA EN AppointmentDetailModal.tsx */}

      <Modal
        size='lg'
        show={AppointmentDetailShow}
        onHide={handleAppointmentDetailClose}
      >
        <Modal.Header className='justify-content-between' closeButton>
          <h5 className='fw-bold'>Detalle Cita</h5>
        </Modal.Header>
        <Modal.Body className='pt-2'>
          <div
            className={`appointment ${color} ${outdated}`} /* onClick={AppointmentDetailShow} */
            style={{cursor:'initial'}}
          >
            <div className='d-flex align-items-center justify-content-between w-100'>
              <p className='font-s fw-semibold'>
                {moment(appointment.periodicity_start_date).format(
                  "DD/MM/YYYY"
                )}{" "}
                - {appointment.periodicity_hour}h
              </p>

              <span className={`print-none service-chip border-left-${color}`}>
                {appointment.health_service_name &&
                  capitalize(appointment.health_service_name)}
              </span>
            </div>

            <div className='mt-2'>
              <Link
                className='font-m color-prim fw-bold mb-1 text-decoration-underline'
                to={`${routes.patients}/archivo/${appointment ?.patient}`}
              >
                {appointment.patient_name ||
                  `${appointment.patientInfo ?.name} ${appointment.patientInfo ?.surname}`}
              </Link>
              <p className='font-s color-dark70 truncate-2rows'></p>
            </div>

            <div className='d-flex align-items-center justify-content-between mt-2 w-100'>
              <div className='d-inline-flex align-items-center'>
                <div className='d-inline-flex color-dark50 me-3'>
                  <i className='ssp-storefront font-m me-1'></i>
                  <span className='font-xs text-truncate'>
                    {capitalize(appointment.type)}
                  </span>
                </div>
                <div className='d-inline-flex color-dark50 me-3'>
                  <i className='ssp-person font-m me-1'></i>
                  <span className='font-xs text-truncate'>
                    {appointment.owner_complete_name}
                  </span>
                </div>
              </div>


            </div>

            {/* Mostrar solo en la modal de AppointmentDetailModal */}

            <div className='d-flex align-items-center justify-content-start mt-3 w-100'>
              <div className='form-group readonly mb-0'>
                <label className='fw-normal font-xs mb-0'>
                  Quién tomó la cita
                  </label>
                <div className='d-inline-flex color-dark50 me-3'>
                  <i className='ssp-person font-m me-1'></i>
                  <span className='font-xs text-truncate'>{appointment.employee_complete_name}</span>
                </div>
              </div>
              <div className='form-group readonly mb-0 ms-4'>
                <label className='fw-normal font-xs mb-0'>
                  Fecha en la que se tomó la cita
                  </label>
                <div className='d-inline-flex color-dark50 me-3'>
                  <span className='font-xs text-truncate'>{moment(appointment.createdAt).format(
                      "DD/MM/YYYY"
                  )}</span>
                </div>
              </div>
            </div>

          </div>

          <h4 className="font-ml fw-bold mb-3 mt-4">
            Nota de la cita
          </h4>
          <Form.Group className="form-group mb-0" >
            <Form.Control readOnly={true} as="textarea" value={appointment.motive} style={{ minHeight: '100px' }} />
          </Form.Group>


        </Modal.Body>
          <Modal.Footer className='justify-content-end'>
              {/*
          <Button className='red outline ms-2'>Eliminar cita</Button>
          <Link className='btn prim outline ms-2' to='/'>
            Editar
          </Link>
          <Button className='prim ms-2 align-items-center d-inline-flex' onClick={() => handlerMeasurementModal("visit")}>
            Iniciar medición <i className='ssp-chevron-right ms-1'></i>
          </Button>*/}
        </Modal.Footer>
      </Modal>

      <PrintModal
        PrintShow={PrintShow}
        handlePrintClose={handlePrintClose}
        handlePrint={handlePrint}
      />
    </>
  );
};

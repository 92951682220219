import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IFilter } from "../../interfaces";
import { IEmployee, IFilterEmployee } from "../../interfaces/employee.interfaces";
import { useAppDispatch, useAppSelector } from "../../module/hooks"
import { updateFilters, updateFiltersModal } from "../../module/reducers/employee";
import { registerEmployee, updateEmployee, findEmployeeById, deleteEmployee, employeeList } from "../../module/reducers/employee/slice";
import { setNotification } from "../../module/reducers/notification";
import { routes } from "../../router/constants";

export const useEmployee = (id?: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { list, total, filters, status } = useAppSelector(state => state.employee)
  const [currentEmployee, setEmployee] = useState<IEmployee>()
  useEffect(() => {
    const employeeById = async (id: string) => {
      const { code, content } = await dispatch(findEmployeeById(id)).unwrap();
      if (code === 200) return setEmployee(content);
      return navigate(routes.profile)
    }
    if (id) employeeById(id)
  }, [dispatch, id, navigate])

  const handleCreateEmployee = async (fields: Partial<IEmployee>, setSubmitting: (isSubmitting: boolean) => void) => {
    const { error, code, message } = await dispatch(registerEmployee(fields)).unwrap();
    setSubmitting(false);
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    navigate(routes.profile)
    dispatch(setNotification({ message: "Trabajador agregado exitosamente", status: "success", show: true, type: "toast" }))   
  }
  const handleUpdateEmployee = async (fields: Partial<IEmployee>, setSubmitting: (isSubmitting: boolean) => void) => {
    const { code, error, message } = await dispatch(updateEmployee(fields)).unwrap();
    setSubmitting(false);
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    navigate(routes.profile)
    dispatch(setNotification({ message: "Trabajador actualizado exitosamente", status: "success", show: true, type: "toast" }))
  }


  //FILTER
  const handleFilter = async (filter: keyof IFilterEmployee, value: number | string, isArray: boolean = false) => {
    if (!isArray) return dispatch(updateFilters({ ...filters, [filter]: value }))
    const getFilters = filters[filter] as string[];
    if (getFilters.find(filterValue => filterValue === value)) return;
    return dispatch(updateFilters({ ...filters, [filter]: [...getFilters, value] }))
  }

  const handleRemoveFilter = async (filter: keyof IFilterEmployee, value: number | string) => {
    const getFilters = filters[filter] as string[];
    return dispatch(updateFilters({ ...filters, [filter]: getFilters.filter(filterName => filterName !== value) }))
  }

  //FILTERMODAL
  const handleFilterModal = async (filter: keyof IFilterEmployee, value: number | string, isArray: boolean = false) => {
    if (!isArray) return dispatch(updateFiltersModal({ ...filters, [filter]: value }))
    const getFilters = filters[filter] as string[];
    if (getFilters.find(filterValue => filterValue === value)) return;
    return dispatch(updateFiltersModal({ ...filters, [filter]: [...getFilters, value] }))
  }

  const handleRemoveFilterModal = async (filter: keyof IFilterEmployee, value: number | string) => {
    const getFilters = filters[filter] as string[];
    return dispatch(updateFiltersModal({ ...filters, [filter]: getFilters.filter(filterName => filterName !== value) }))
  }

  const del = async (id: IEmployee['id']) => {
    const { error, message, code } = await dispatch(deleteEmployee(id)).unwrap();
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    dispatch(employeeList())
    dispatch(setNotification({ message: "Trabajador eliminado exitosamente", status: "success", show: true, type: "toast" }))
  }


  const findCompleteNameById = (id: IEmployee['id']) => {
    const employee = list.find(employee => employee.id === id)
    return `${employee?.name} ${employee?.surname}`
  }


  return {
    employeeStatus: status,
    employees: list,
    total,
    filters,
    handleRemoveFilter,  handleRemoveFilterModal,
    createEmployee: handleCreateEmployee,
    updateEmployee: handleUpdateEmployee,
    handleFilter,    handleFilterModal,
    currentEmployee,
    deleteEmployee: del,
    findCompleteNameById
  }
}
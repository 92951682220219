import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { appointmentByIdService, appointmentByPatientService, appointmentListService, createAppointmentService, deleteAppointmentService, updateAppointmentService } from "../../../api/services/appointment.service";
import { dateToString } from "../../../helpers";
import { getFilters } from "../../../helpers/restClient";
import { IPatient } from "../../../interfaces";
import { IAppointment, IAppointmentState } from "../../../interfaces/appointment.interfaces";
import { RootState } from "../../store";
import { reminderExtraReducers } from "./extraReducers";
import { NewAppointmentModal } from '../../../core/components/shared/modals/NewAppointmentModal';

export const initialAppointment = { periodicity_start_date : dateToString(new Date(), false), periodicity_hour : "", periodicity_routinely : false, periodicity_type : "Puntual", appointmentType: "INPERSON",type : "General", health_service_id : "", health_service_name : "", motive : "", employee_id : "", employee_complete_name : "", owner_id : "", owner_complete_name : "", recommendation : "", patient: ""}
const initialFilters = { start_date: moment().format('YYYY-MM-DD'), end_date: moment().add(1, 'd').format('YYYY-MM-DD') }

export const initialState: IAppointmentState = {
  appointment: initialAppointment,
  list: [],
  filters: initialFilters,
  status: 'nofetched',
  newAppointmentID: '',

};

export const appointmentSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    newAppointment: (state, action: PayloadAction<Partial<IAppointment>>) => {
      state.appointment = { ...state.appointment, ...action.payload }
    },
    updateFilters: (state, action: PayloadAction<IAppointmentState['filters']>) => {
      state.filters = action.payload
    },
    updateFiltersModal: (state, action: PayloadAction<IAppointmentState['filters']>) => {
      state.filters = action.payload
    },
    clearStatus: (state) => { 
      state = initialState
      return state
     },
     setNewAppointmentID: (state, action: PayloadAction<string>) => {
      state.newAppointmentID = action.payload
     }
  },
  extraReducers: (builder) => reminderExtraReducers(builder),
});

export const serviceList = createAsyncThunk(
  'appointments/list',
  async (_, { getState }) => {
    const rootState = getState() as RootState;
    const params = getFilters(rootState.appointment)
    return appointmentListService(params);
  }
);

export const listByPatient = createAsyncThunk(
  'appointments/listByPatient',
  async (patient: IPatient['id'], { getState }) => {
        const rootState = getState() as RootState;
        //const params = getFilters(rootState.appointment)
        const data = (await appointmentByPatientService(patient))
        if(data.content.length > 0){
            if(data.content[0].patient !== patient){
                data.content = []
            }
            data.content.reverse()
        }

        return data;
  }
);

export const AppointmentById = createAsyncThunk(
  'appointments/listById',
  async (appointment: Partial<IAppointment>) => appointmentByIdService(appointment)
);

export const registerAppointment = createAsyncThunk(
  'appointments/register',
  async (appointment: Partial<IAppointment>) => createAppointmentService(appointment)
);

export const updateAppointment = createAsyncThunk(
  'appointments/update',
  async (appointment: Partial<IAppointment>) => updateAppointmentService(appointment)
);

export const deleteAppointment = createAsyncThunk(
  'appointments/delete',
  async (appointment: Partial<IAppointment>) => deleteAppointmentService(appointment)
);

import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { IFilterPatient, IPatient } from '../../../interfaces'
import { useAppDispatch } from '../../../module/hooks'
import { patientList } from '../../../module/reducers/patient/slice'
import { usePatient } from '../../customHooks/usePatient'
import { BasicLoader } from '../shared/loaders'

export const FilterSelect = ({ handleSelect, value }: { handleSelect: (data: any) =>  Promise<{payload: Partial<any>; type: string;}>, value: string}) => {
  const dispatch = useAppDispatch()
  const { handleFilter, patients, patientStatus, filters } = usePatient()
  useEffect(() => { dispatch(patientList()) }, [dispatch, filters])
  return (
    <>
      <h3 className="font-ml fw-bold mb-2 mt-4 pt-2">Selecciona paciente</h3>
      <p className="fw-light mb-4">Selecciona al paciente sobre el que quieras hacer el recordatorio buscándolo directamente en tu listado de pacientes.</p>
      <div className="searchbar-wrapper">
        <Form.Group className="form-group searchbar" controlId="searchbar">
          <i className="ssp-search color-prim font-xml"></i>
          <Form.Control type="text" placeholder="Buscar nombre de paciente" name="search" onChange={({ target }) => handleFilter(target.name as keyof IFilterPatient, target.value)} />
        </Form.Group>
      </div>
      <div>
        {
          patientStatus === 'fetching' ?
            <LoaderPatient>
              <BasicLoader />
            </LoaderPatient> :
            !patients.length ?
            <LoaderPatient>
              <p>No se encontraron pacientes</p>
            </LoaderPatient>:  
            patients.map((patient, key) => <ItemSelector handleSelect={handleSelect} value={value} {...patient} key={key} />)
        }
      </div>
    </>
  )
}

const ItemSelector = (patient: IPatient & { handleSelect: (data: any) =>  Promise<{payload: Partial<any>; type: string;}>, value: string}) => {
  return (
    <div className="radius-8 p-4 mb-2 bg-prim5 d-flex align-items-center justify-content-between">
      <p className="fw-normal lh-1">{patient.name} {patient.surname}</p>
      <div key="paciente" className="">
        <Form.Check className="ms-4 me-0"
          label=""
          name="patient"
          type="checkbox"
          checked={patient.checked || patient.value === patient.id}
          value={patient.id}
          onChange={() => patient.handleSelect({ patient: patient.id })
          }
        />
      </div>
    </div>
  )
}

const LoaderPatient = ({ children }: { children: React.ReactElement}) => {
  return (
    <div className="radius-8 p-4 mb-2 bg-prim5 d-flex align-items-center justify-content-center">
      { children }
    </div>
  )
}
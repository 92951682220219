import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { completeReminderService, createReminder, deleteReminderService, generalRemindersByPatientService, generalRemindersService, reminderByIdService, updateReminderService, urgentRemindersByPatientService, urgentRemindersService } from "../../../api/services/reminder.service";
import { getFilters } from "../../../helpers/restClient";
import { IPatient } from "../../../interfaces";
import { IReminder, IReminderState } from "../../../interfaces/reminder.interfaces";
import { RootState } from "../../store";
import { reminderExtraReducers } from "./extraReducers";

const initialReminder = {
  periodicity_start_date : "",
  periodicity_hour : "",
  periodicity_routinely : false,
  periodicity_type : "Mensual", 
  type : 'General',
  reason : "",
  motive : "",
  employee_id : "",
  employee_complete_name : "",
  recommendation : "",
  pharmacy: "",
  employee_name: ""
}

const initialFilters = { employee: "", reason: "", search: "", start_date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD')}

export const initialState: IReminderState = { urgent: [], general: [], reminder: initialReminder, list: [], filters: initialFilters, status: 'nofetched' };

export const reminderSlice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {
    newReminder: (state, action: PayloadAction<Partial<IReminder>>) => { state.reminder = { ...state.reminder, ...action.payload } },
    updateFilters: (state, action: PayloadAction<IReminderState['filters']>) => {
      state.filters = action.payload
      state.status = 'nofetched'
    },
    updateFiltersModal: (state, action: PayloadAction<IReminderState['filters']>) => {
      state.filters = action.payload
      state.status = 'nofetched'
    },
    clearStatus: (state) => { 
      state = initialState
      return state
     },
    clearFilters: (state) => { state.filters = initialFilters }
  },
  extraReducers: (builder) => reminderExtraReducers(builder),
});

export const getGeneralReminders = createAsyncThunk(
  'reminders/generalReminders',
  async (_,{ getState }) => {
    const rootState = getState() as RootState;
    const params = getFilters(rootState.reminder)
    return generalRemindersService(params)
  }
);

export const getUrgentReminders = createAsyncThunk(
  'reminders/urgentReminders',
  async (_,{ getState }) => {
    const rootState = getState() as RootState;
    const params = getFilters(rootState.reminder)
    return urgentRemindersService(params)
  }
);

export const getGeneralRemindersByPatient = createAsyncThunk(
  'reminders/getGeneralRemindersByPatient',
  async (patientId: IPatient['id'],{ getState }) => {
    const rootState = getState() as RootState;
    const params = getFilters(rootState.reminder)
    return generalRemindersByPatientService(patientId, params)
  }
);

export const getUrgentRemindersByPatient = createAsyncThunk(
  'reminders/getUrgentRemindersByPatient',
  async (patientId: IPatient['id'], { getState }) => {
    const rootState = getState() as RootState;
    const params = getFilters(rootState.reminder)
    return urgentRemindersByPatientService(patientId, params)
  }
);

export const reminderById = createAsyncThunk(
  'reminders/reminderById',
  async (reminder: Partial<IReminder>) => reminderByIdService(reminder)
);

export const registerReminder = createAsyncThunk(
  'reminders/register',
  async (reminder: IReminder) => createReminder(reminder)
);

export const updateReminder = createAsyncThunk(
  'reminders/update',
  async (reminder: Partial<IReminder>) => updateReminderService(reminder)
);

export const completeReminder = createAsyncThunk(
  'reminders/complete',
  async (reminder: Partial<IReminder>) => completeReminderService(reminder)
);

export const deleteReminder = createAsyncThunk('reminders/delete', async (reminder: IReminder) => deleteReminderService(reminder));

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { useAuth } from '../../../customHooks/useAuth';


/* export const LogoutModal: React.FC = (): JSX.Element => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { logout } = useAuth()

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        ¿Quieres cerrar sesión?
            </Modal.Header>
      <Modal.Body>
        <p className="text-center fw-bold font-ml mb-3">Puedes volver a entrar con tus credenciales cuando quieras</p>
        <p className="text-center fw-bold font-ml color-prim">email@mail.com</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="prim-outline" onClick={handleClose}>
          Cancelar
          </Button>
        <Button className="danger" onClick={logout}>
          Cerrar sesión
          </Button>
      </Modal.Footer>
    </Modal>

  );
}; */

export const LogoutModal = ({ logoutShow, handleLogoutClose }: { logoutShow: boolean, handleLogoutClose: () => void }) => {
  const { logout } = useAuth();
  
  return <Modal show={logoutShow} onHide={handleLogoutClose}>
    <Modal.Header className="justify-content-between" closeButton>
      <h5 className="fw-bold">¿Quieres cerrar sesión?</h5>
    </Modal.Header>
    <Modal.Body>
      <p className="text-left fw-light color-dark50 font-s mt-3">Puedes volver a entrar con tus credenciales cuando quieras.</p>
    </Modal.Body>
    <Modal.Footer className="justify-content-end">
    <Button className="btn prim outline" onClick={handleLogoutClose}>
          Cancelar</Button>
        <Button className="btn red" onClick={logout}>
          Cerrar sesión</Button>
    </Modal.Footer>
  </Modal>;
}
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IReminder } from '../../../interfaces';
import moment from 'moment'
import { dateToString } from '../../../helpers';
import { useReminder } from '../../customHooks/useReminder';
import { routes } from '../../../router/constants';

export const ReminderCard: React.FC<IReminder> = (reminder: IReminder) => {
  const [contactPatientShow, contactPatientSetShow] = useState(false);
  const [postponeShow, postponeSetShow] = useState(false);
  const urgent = reminder.type === "Urgente"
  const handleContactPatientClose = () => contactPatientSetShow(false);
  const handleContactPatientShow = () => contactPatientSetShow(true);
  const handlePostponeClose = () => postponeSetShow(false);
  const handlePostponeShow = () => postponeSetShow(true);
  const { handleDeleteReminder, handleCompleteReminder } = useReminder({})
  return (
    <>
      <div className="reminder-card">
        <div className="d-flex align-items-center justify-content-between">
          <p className="color-prim font-s fw-semibold">Recordatorio</p>

          <div className="d-flex align-items-center">

            {
              reminder.type === 'Urgente' && <span className={`badge red`}>{reminder.type}</span>
            }
            <span className={`badge green`}>{reminder.reason}</span>
          </div>
        </div>

        <div className="mt-2 mb-1">
          <p className="fw-bold">{reminder.patientInfo?.name} {reminder.patientInfo?.surname}</p>
        </div>
        <div className="d-flex align-items-center justify-content-start color-dark70 font-s">
        <i className="ssp-time font-m me-1"></i>
          <span>{reminder.hour || reminder.periodicity_hour}</span>
          <i className="ssp-calendar font-m me-1 ms-4"></i>
          <span>{moment(reminder.startDate! || reminder.periodicity_start_date).format('DD/MM/YYYY')}</span>
        </div>

        <Row className="mt-3">
          <Col lg={6}>
            <div className="form-group readonly mb-0">
              <label>Motivo</label>
              <p className="font-s">{ reminder.reason }</p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-group readonly mb-0">
              <label>Recomendación</label>
              <p className="font-s">{reminder.recommendation}</p>
            </div>
          </Col>
        </Row>

        <div className="d-flex align-items-center justify-content-end mt-4">
          {!urgent &&
            <Button className="prim outline" onClick={handlePostponeShow}>Posponer</Button>
          }

          <Button className="prim ms-2" onClick={handleContactPatientShow}>Contactar</Button>

        </div>
      </div>


      {/* Modal de contactar paciente */}
      <Modal size="lg" show={contactPatientShow} onHide={handleContactPatientClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <p className="color-prim font-s fw-semibold">Recordatorio</p>

            <div className="d-flex align-items-center">
            <span className="badge light-blue">Rutinario</span>
              {urgent ?
                <span className="badge red">Urgente</span> : <span className="badge green">{reminder.type}</span>
              }

              
            </div>
          </div>

          <div className="mt-2 mb-1">
            <p className="fw-bold">{reminder.patient_info?.name} {reminder.patient_info?.surname}</p>
          </div>
          <div className="d-flex align-items-center justify-content-start color-dark70 font-s">
          <i className="ssp-time font-m me-1"></i>
            <span>{reminder.hour || reminder.periodicity_hour}</span>
            <i className="ssp-calendar font-m me-1 ms-4"></i>
            <span>{dateToString(reminder.startDate! || reminder.periodicity_start_date)}</span>
          </div>

          <Row className="mt-3">
            <Col lg={6}>
              <div className="form-group readonly mb-0">
                <label>Motivo</label>
                <p className="font-s">{reminder.motive}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group readonly mb-0">
                <label>Recomendación</label>
                <p className="font-s">{reminder.recommendation}</p>
              </div>
            </Col>
          </Row>

          <hr className="my-4" />

          <p className="color-prim font-s fw-semibold mb-2">Contactar</p>
          <p className="font-s fw-light mb-2">Este paciente prefiere el contacto por <b>{reminder.patient_info?.contact_by}</b></p>
          <p className="font-m fw-bold mb-2">{reminder.patient_info?.telephone}</p>
          <p className="font-s fw-normal mb-2">{reminder.patient_info?.email}</p>

          <div className="d-flex align-items-center justify-content-between my-4">
            <Link className="btn prim w-100 px-3 text-nowrap" to="">
              <img className="me-2" src="/assets/img/phone-icon.png" alt="phone icon" style={{width: '24px'}}/>
              Llamar por teléfono
            </Link>
            <Link className="btn prim ms-2 w-100 px-3 text-nowrap" to="">
              <img className="me-2" src="/assets/img/whatsapp-icon.png" alt="whatsapp icon" style={{width: '24px'}}/>
              Contactar por WhatsApp
            </Link>
            <Link className="btn prim ms-2 w-100 px-3 text-nowrap" to="">
              <img className="me-2" src="/assets/img/mail-icon.png" alt="mail icon" style={{width: '24px'}}/>
              Contactar por email
            </Link>
          </div>

          <Form>
            <div className="bg-prim5 radius-20 p-4 d-flex align-items-baseline mb-4">

              <Form.Check className="ms-3"
                type="checkbox"
                id="reminder-complete"
                name="reminder-complete"
                checked={reminder.completed}
                onChange={({target}) => handleCompleteReminder({...reminder, completed: target.checked })}
              />
              <div className="ps-3">
                <label className="fw-bold mb-2">Recordatorio completado</label>
                <p className="font-s fw-light">El contacto con este paciente se ha realizado correctamente.</p>
              </div>
            </div>
          </Form>

          <hr className="mb-0" />
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
        {!urgent &&
          <Button className="prim outline ms-2" >Posponer</Button>
        }
          <Button className="red outline ms-2"  onClick={() => {
            handleDeleteReminder(reminder)
            handleContactPatientClose()
            }}>Eliminar</Button>
          <Link className="btn prim ms-2" to={routes.editReminder(reminder.patient, reminder.id)}>Editar</Link>


        </Modal.Footer>
      </Modal>


      {/* Modal de posponer cita */}
      <Modal show={postponeShow} onHide={handlePostponeClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>

          <p className="text-center fw-bold font-ml mb-3">¿Quieres posponer este recordatorio?</p>
          <p className="text-left fw-light">Puedes elegir aplazar este recordatorio para otro momento. Elige a cuándo quieres posponer este recordatorio:</p>

          <div className="d-flex align-items-center justify-content-start mb-4 mt-3">

            <Button className="pill">A mañana</Button>
            <Button className="pill">A dentro de 3 días</Button>
            <Button className="pill">A la próxima semana</Button>
          </div>

          <Form.Group className="form-group" controlId="periodicity">
            <Form.Control type="text" placeholder="Elegir otra periodicidad" />
          </Form.Group>
          
        </Modal.Body>
        <Modal.Footer>
          <Button className="prim outline" onClick={handlePostponeClose}>
            Cancelar</Button>
          <Button className="prim ms-2" onClick={handlePostponeClose}>
            Aceptar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IAppointment } from "../../interfaces/appointment.interfaces";
import { useAppDispatch, useAppSelector } from "../../module/hooks";
import {newAppointment, updateFilters, setNewAppointmentID, clearStatus} from "../../module/reducers/appointment";
import { AppointmentById, deleteAppointment, listByPatient, registerAppointment, updateAppointment } from "../../module/reducers/appointment/slice";
import { setNotification } from "../../module/reducers/notification";
import { updatePatientById } from "../../module/reducers/patient";
import { findPatientById } from "../../module/reducers/patient/slice";
import { routes } from "../../router/constants";

export const useAppointment = ({ patient, appointmentId }: { patient?: string; appointmentId?: IAppointment['id']}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { list, total, status, appointment, filters, current, newAppointmentID } = useAppSelector(state => state.appointment)
  
  const handleAppointment = async (appointment: Partial<IAppointment>) => {
    if(Object.keys(appointment).includes('patient')) {
      dispatch(clearStatus())
      const { content } = await dispatch(findPatientById(appointment.patient!)).unwrap();
      dispatch(updatePatientById({id: appointment.patient, checked: true}))
      console.log("CONTENT::",content)
      appointment.patientInfo = content;
    }
    return dispatch(newAppointment(appointment))
  }
  useEffect(() => {
    if(patient && !appointmentId) dispatch(listByPatient(patient))
    if(patient && appointmentId) {
      dispatch(AppointmentById({ patient, id: appointmentId })).then(() => handleAppointment({patient }))
    }
  }, [patient, dispatch, filters, appointmentId]) // eslint-disable-line react-hooks/exhaustive-deps
  const handleCreateNewAppointment = async (fields: IAppointment, setSubmitting: (isSubmitting: boolean) => void, redirect?: boolean) => {
    const { code, message, content } = await dispatch(registerAppointment(fields)).unwrap();
    setSubmitting(false);
    if(code! >= 400)
      return  code! >=500
      ? dispatch(setNotification({ message: "Ha ocurrido un error al guardar la cita", status: "danger", show: true, type: "toast" })) : dispatch(setNotification({ message: message!, status: "danger", show: true, type: "toast" }))

    //redirect && navigate('/')
    //dispatch(setNewAppointmentID(content.id!))

    return dispatch(setNotification({ message: "Cita agregada exitosamente", status: "success", show: true, type: "toast" }))
  }
  const handleUpdateAppointment = async (fields: IAppointment, setSubmitting: (isSubmitting: boolean) => void) => {
    const { error, message, code } = await dispatch(updateAppointment(fields)).unwrap();
    setSubmitting(false);
    if (error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    navigate(-1)
    dispatch(setNotification({ message: "Cita actualizada exitosamente", status: "success", show: true, type: "toast" }))
  }
  const del = async (appointment: IAppointment) => {
    const { error, message, code } = await dispatch(deleteAppointment(appointment)).unwrap();
    if (error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    dispatch(setNotification({ message: "Cita eliminada exitosamente", status: "success", show: true, type: "toast" }))
  }

  const nextDay = () => dispatch(updateFilters({ ...filters, start_date: moment(filters.start_date).add(1, 'd').format('YYYY-MM-DD'), end_date: moment(filters.end_date).add(1, 'd').format('YYYY-MM-DD') }))

  const previousDay = () => dispatch(updateFilters({ ...filters, start_date: moment(filters.start_date).subtract(1, 'd').format('YYYY-MM-DD'), end_date: moment(filters.end_date).subtract(1, 'd').format('YYYY-MM-DD') }))

  const filterByDate = (startDate: string, endDate: string) => dispatch(updateFilters({ ...filters, start_date: startDate, end_date: endDate }))


  const validations = (step: number): boolean => {
     // todo: enable it once all the endpoints are ready
    // if(step === 1 && appointment.employee_id && appointment.employee_doer_id) return false
    // if(step === 2 && appointment.type && appointment.health_service_id) return false
    // if(step === 3 && appointment.periodicity_hour && appointment.periodicity_start_date) return false
    // return true

    return false
  }
  return {
    filters,
    appointment,
    handleAppointment,
    nextDay,
    previousDay,
    appointments: list,
    total,
    createAppointment: handleCreateNewAppointment,
    updateAppointment: handleUpdateAppointment,
    status,
    validations,
    current,
    deleteAppointment: del,
    filterByDate,
    newAppointmentID
  }
}

import Container from 'react-bootstrap/Container';
import { Button } from 'react-bootstrap';
import { usePatient } from '../../customHooks/usePatient';
import { TablePatient } from '../../components/patient/TablePatient';
import {useEffect, useState} from 'react';
import { NewPatientModal } from '../../components/shared/modals/NewPatientModal';
import {clearFilters} from "../../../module/reducers/patient";
import {useAppDispatch} from "../../../module/hooks";

export const PatientsPage = () => {
  const { total, handlerPerPageNoUpdate } = usePatient()
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearFilters());
    //handlerPerPageNoUpdate(10);
  }, []);
  const handleNewPatientShow = () => setNewPatientShow(true);
  const handleNewPatientClose = () => setNewPatientShow(false);
  const [NewPatientShow, setNewPatientShow] = useState<boolean>(false);
  return (
    <>
      <Container className="pb-5">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="fw-bold font-xxml mb-0">Pacientes <span className="color-dark30 font-xl fw-light ps-1">({total || 0})</span></h1>
          <div className="d-inline-flex align-items-center">
            <Button className=' alt-btn' onClick={handleNewPatientShow}>
              <span>Nuevo paciente</span>
              <i className='ssp-person-add icon-circle'></i>
            </Button>
          </div>
        </div>
        <TablePatient perPage={10} />
      </Container>

      <NewPatientModal
        NewPatientShow={NewPatientShow}
        handleNewPatientClose={handleNewPatientClose}
      />
    </>
  )
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  changePasswordService,
  checkAuthService,
  checkTermsService,
  confirmTermsService,
  loginService,
  recoveryService,
  registerService,
  updateUserService
} from "../../../api/services/auth.service";
import { initialRegisterValues } from "../../../helpers/schemas/authSchema";
import { IAuthActions, ILoginUser, IRecoveryUser, IRegisterUser } from "../../../interfaces/auth.interfaces";
import { IUserInfo, IUserPassword, IUserState } from "../../../interfaces/user.interfaces";
import { RootState } from "../../store";
import { userExtraReducers } from "./extraReducers";

export const initialState: IUserState = {
  info: { ...initialRegisterValues, token: ""},
  status: localStorage.getItem("token") ? "checking" : "guest"
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setstatus: (state, action: PayloadAction<IUserState['status']>) => { state.status = action.payload },
    logout: (state) => {
      state = {...initialState, status: "guest"}
      return state
    }
  },
  extraReducers: (builder) => userExtraReducers(builder),
});

// Selectors
export const isAuthenticatedSelector =  (state: RootState) => state.user.status === 'logged';
export const localToken = localStorage.getItem("token");

// Actions
export const login = createAsyncThunk(
  'user/login',
  async ({credentials}: IAuthActions<ILoginUser>) => {
    let data:any = await loginService(credentials);
    if(!data.error) localStorage.setItem('token', data.content.token!);
    return data;
  }
);

export const register = createAsyncThunk(
  'user/register',
  async ({credentials}: IAuthActions<IRegisterUser>) => registerService(credentials)
);

export const recovery = createAsyncThunk(
  'user/recovery',
  async ({credentials}: IAuthActions<IRecoveryUser>) => recoveryService(credentials)
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (credentials: IUserPassword) => changePasswordService(credentials)
);

export const updateProfile = createAsyncThunk(
  'user/updateProfile',
  async (credentials: IUserInfo) => updateUserService(credentials)
);

export const checkAuth = createAsyncThunk(
    'user/checkAuth',
    async () => checkAuthService()
);

export const checkTerms = createAsyncThunk(
  'user/checkTerms',
  async () => checkTermsService()
);

export const confirmTerms = createAsyncThunk(
    'user/confirmTerms',
    async () => confirmTermsService()
);

export const selectTokenUser = (state: RootState) => state.user.info.token;
export const userInfo = (state: RootState) => state.user.info;
export const isAuthSelector = (state: RootState) => state.user.status === 'logged';

import { Button, Modal } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import styled from 'styled-components';
import { INotification } from '../../../../interfaces/notification.interfaces';
import { useAppDispatch, useAppSelector } from '../../../../module/hooks';
import { setNotification } from '../../../../module/reducers/notification';
import { Link } from 'react-router-dom';

const ToastStyle = styled(Toast) <{ status?: INotification['status'] }>`
  position: fixed;
  z-index: 2;
  bottom: 20px;
  right: 20px;
  color: white;
  font-weight: bold;
`

export const ToastAlerComponent = () => {
  const dispatch = useAppDispatch()
  const { message, status, show, type, description, icon, CTA, CTAmessage, link } = useAppSelector(state => state.notification)
  return (
    <>
      {type === "toast" ? (
        <ToastStyle
          bg={status}
          onClose={() =>
            dispatch(
              setNotification({
                message,
                status,
                icon,
                CTAmessage,
                CTA: false,
                show: false,
              })
            )
          }
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Body>
            <div className='d-inline-flex align-items-center fw-light'>
              {icon && <i className={icon}></i>}

              {message || "Ha ocurrido un error, por favor intentelo más tarde"}
            </div>

            {CTA && (
              <Link className='color-white text-nowrap ms-5' to={link ?? '/'}>
                {CTAmessage}
              </Link>
            )}
          </Toast.Body>
        </ToastStyle>
      ) : (
        <Modal
          show={show}
          onHide={() =>
            dispatch(setNotification({
              message, status, show: false,
            }))
          }
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p className='text-center fw-bold font-ml'>{message}</p>
            {description && (
              <p className='color-prim fw-bold text-center mt-3'>
                {description}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='prim'
              onClick={() =>
                dispatch(setNotification({
                  message, status, show: false,
                }))
              }
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

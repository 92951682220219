import { useEffect } from 'react';
import { Container, Button, Form, Tab, Tabs, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { getDaysBetweenDates } from '../../../helpers/dates';
import { useAppDispatch } from '../../../module/hooks';
import { employeeList } from '../../../module/reducers/employee/slice';
import { clearStatus } from '../../../module/reducers/reminder';
import { motives, routes } from '../../../router/constants';
import { NoReminders } from '../../components/home/NoReminders';
import { BasicLoader } from '../../components/shared/loaders';
import { ReminderCard } from '../../components/shared/ReminderCard';
import { useEmployee } from '../../customHooks/useEmployee';
import { useReminder } from '../../customHooks/useReminder';

export const RemindersPage = () => {
  const { id } = useParams()
  const { urgent, general, filters, nextDay, previousDay, handleFilter, status } = useReminder({ patient: id, fetch: true })
  const dispatch = useAppDispatch()
  const { employees } = useEmployee()
  useEffect(() => {
    dispatch(employeeList())
  }, [dispatch])
  useEffect(() => () => { dispatch(clearStatus()) }, [dispatch])
  return (
    <Container className="pb-5">
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="fw-bold font-xxml mb-4">Recordatorios</h1>
        <Link className="btn prim" to={routes.newReminder}>Nuevo recordatorio</Link>
      </div>

      <div className="d-flex align-items-center justify-content-start mt-4 mb-3">
        <Button className="transparent" onClick={previousDay}>
          <span><i className="ssp-chevron-left"></i></span>
        </Button>
        <p className="px-3 fw-normal text-capitalize">{getDaysBetweenDates(filters.start_date!, filters.end_date!)}</p>
        <Button className="transparent" onClick={nextDay}>
          <span><i className="ssp-chevron-right"></i></span>
        </Button>
      </div>

      <div className="searchbar-wrapper">
        <Form>
          <Form.Group className="form-group searchbar" controlId="searchbar">
            <i className="ssp-search color-prim font-xml"></i>
            <Form.Control type="text" placeholder="Buscar nombre de paciente" name="search" value={filters.search} onChange={({ target }) => handleFilter('search', target.value)} />
          </Form.Group>
          <Form.Group className="form-group select" controlId="pharmacist">
            <Form.Select aria-label="Farmaceutico" value={filters.employee_id} onChange={({ target }) => handleFilter('employee_id', target.value)}>
              <option value="">Farmacéutico</option>
              {
                employees && employees.length && employees.map((employee, key) => <option key={key} value={employee.id}>{employee.name} {employee.surname}</option>)
              }
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group select" controlId="motive">
            <Form.Select aria-label="Motivo" value={filters.reason} onChange={({ target }) => handleFilter('reason', target.value)}>
              <option value="">Motivo</option>
              {
                motives.map((motive, key) => <option key={key} className="text-capitalize" value={motive}>{motive}</option>)
              }
            </Form.Select>
          </Form.Group>
        </Form>
      </div>
      <Tabs
        defaultActiveKey="urgent-reminders"
        className="my-3"
      >

        <Tab eventKey="urgent-reminders" title="Recordatorios urgentes">
          <Row className="py-4">
            {
              status === 'fetching' ? <div className="loader-center"><BasicLoader /></div> :

                urgent && !!urgent.length ? urgent.map((reminder, key) => (
                  <Col lg={4} key={key}>
                    <ReminderCard key={key} {...reminder} />
                  </Col>
                )) : <NoReminders />
            }
          </Row>
        </Tab>
        <Tab eventKey="general-reminders" title="Recordatorios generales">
          <Row className="py-4">
            {
              status === 'fetching' ? <div className="loader-center"><BasicLoader /></div> :
                general && !!general.length ? general.map((reminder, key) => (
                  <Col lg={4} key={key}>
                    <ReminderCard key={key} {...reminder} />
                  </Col>)) : <NoReminders />
            }
          </Row>
        </Tab>
      </Tabs>
    </Container>
  )
}

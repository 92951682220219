import { del, get, post, put } from "../../helpers";
import { IPatient, IResponseDetail, IResponseList } from "../../interfaces";
import { IAppointment } from "../../interfaces/appointment.interfaces";

export const appointmentByIdService = async (appointment: Partial<IAppointment>): Promise<IResponseDetail<IAppointment>> =>
  get(`/api/patient/${appointment.patient}/appointment/${appointment.id}`);

export const appointmentListService = async (params?: string): Promise<IResponseList<IAppointment>> =>
  get(`/api/appointments?${params}`);

export const appointmentList = async (params?: string): Promise<IResponseList<IAppointment>> =>
    get(`/api/appointments?${params}`);

export const appointmentByPatientService = async (patient: IPatient['id'], params?: string): Promise<IResponseList<IAppointment>> =>
  get(`/api/patient/${patient}/appointments?${params}`);

export const createAppointmentService = async (appointment: Partial<IAppointment>): Promise<IResponseDetail<Partial<IAppointment>>> =>
  post(`/api/patient/${appointment.patient}/appointment`, appointment);

export const updateAppointmentService = async (appointment: Partial<IAppointment>): Promise<IResponseList<Partial<IAppointment>>> =>
  put(`/api/patient/${appointment.patient}/appointment/${appointment.id}`, appointment);

export const deleteAppointmentService = async (appointment: Partial<IAppointment>):  Promise<IResponseDetail<Partial<IAppointment>>> =>
  del(`/api/patient/${appointment.patient}/appointment/${appointment.id}`);

import { Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { dateToString } from '../../../helpers'
import { routes } from '../../../router/constants'
import { useNote } from '../../customHooks/useNote'

export const NoteList = () => {
  const { id } = useParams()
  const { list, deleteNote } = useNote(id)


  return (
    <>



      {
        !!list ?.length && list ? (
          list.map((note, key) => {
            return (
              <div className="note">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <span className="font-s fw-normal m-0">{dateToString(note.created_at)}</span>
                  <span className='service-chip  bg-prim10 font-s d-inline-flex align-items-center'><i className="ssp-receipt me-2 font-m"></i>Nota</span>
                </div>
                <h4 className="color-prim text-decoration-underline font-m my-2 fw-semibold">{note.employee_complete_name}</h4>
                <label className="fw-semibold color-dark30 font-s">Comentarios</label>
                <div className="d-flex align-items-end justify-content-between">
                  <p className="font-s fw-light truncate-2rows">
                    {note.note}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <div>
          <span className='appointment empty message d-flex align-items-center justify-content-center w-100 mb-0'>No hay datos disponibles.</span>
        </div>
          )
      }


    </>

  )
}





<div>
  <span className='appointment empty message d-flex align-items-center justify-content-center w-100'>No hay datos disponibles.</span>
</div>
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Table, Button , Form} from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAppDispatch } from "../../../module/hooks"
import { patientList } from "../../../module/reducers/patient/slice"
import { routes } from "../../../router/constants"
import { usePatient } from "../../customHooks/usePatient"
import { EmptyTable } from "../shared/EmptyTable"
import { TablePagination } from "../shared/TablePagination"
import { FilterTable } from "./FilterTable"
import { ChooseActivityModal } from "../shared/modals/ChooseActivityModal"
import { NewPatientModal } from "../shared/modals/NewPatientModal"
import { IPatient } from "../../../interfaces/patient.interfaces"

interface IProps {
  perPage?: number;
  selectFilter?: boolean;
  checkedOption?: boolean;
  handlePatientSelected?: (patient: IPatient) => void;
}

export const TablePatient: React.FC<IProps> = ({
  perPage,
  selectFilter = true,
  checkedOption = false,
  handlePatientSelected,
}): JSX.Element => {
  const [ChooseActivityShow, ChooseActivitySetShow] = useState<boolean>(false);
  const [NewPatientShow, NewPatientSetShow] = useState<boolean>(false);
  const [patientIDSelected, setPatientIDSelected] = useState<string>('');
  // 

  const handleChooseActivityShow = (patientID: string) => {
    setPatientIDSelected(patientID);
    ChooseActivitySetShow(true);
  }
  const handleChooseActivityClose = () => ChooseActivitySetShow(false);
  const handleNewPatientShow = () => NewPatientSetShow(true);
  const handleNewPatientClose = () => NewPatientSetShow(false);
  const { patients, filters, handleFilter, total, handlerPerPage, handlerOrderFilter } =
    usePatient();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(patientList());
  }, [dispatch, filters]);

  /* useEffect(() => {
    perPage && handlerPerPage(perPage);
  }, [perPage]);*/

  const [orderName, setOrderName] = useState<string>('');
  const [orderService, setOrderService] = useState<string>('');
  const [orderDate, setOrderDate] = useState<string>('');

  useEffect(() => {
    if(orderName != ''){
      handlerOrderFilter(orderName, 'name')
    }
    }, [orderName]);

  useEffect(() => {
    if(orderDate != ''){
      handlerOrderFilter(orderDate, 'lastMeasure')
    }
  }, [orderDate]);

  const handleSelected = (patient: IPatient) => {
    handlePatientSelected && handlePatientSelected(patient);
  };

  const orderByName = () => {
    if(orderName == '' || orderName == 'DESC'){
      setOrderName('ASC')
    }else{
      setOrderName('DESC')
    }
  }
  const orderByDate = () => {
    if(orderDate == '' || orderDate == 'DESC'){
      setOrderDate('ASC')
    }else{
      setOrderDate('DESC')
    }
  }

  return (
    <>
      {patients.length ||
      filters.search ||
      filters.pathologies?.length ||
      filters.page! > 1 ? (
        <>
          <FilterTable selectFilter={selectFilter} />
          <div className='table-wrapper mt-4 m-shadow'>
            <Table striped>
              <thead>
                {/* Hay que controlar el número de columnas que se muestran dependiendo de si estamos en la Home o en la seccion de Pacientes */}
                <tr>
                  {checkedOption && <th></th>}
                  <th>
                    Nombre del paciente{" "}
                    <Button className='sort-btn pointer-event' onClick={orderByName}>
                      <i className='ssp-swap ms-1 font-m'></i>
                    </Button>
                  </th>
                  <th>Servicios vinculados
                  </th>
                  <th>Fecha última medición{" "}
                    <Button className='sort-btn pointer-event' onClick={orderByDate}>
                      <i className='ssp-swap ms-1 font-m'></i>
                    </Button>
                  </th>
                  {!checkedOption && <th></th>}
                </tr>
              </thead>
              <tbody>
                {!!patients.length ? (
                  patients.map((patient, key) => (
                    <tr key={key}>
                      {checkedOption && (
                        <td width='20'>
                          <Form.Check
                            value={patient.id}
                            label=''
                            name='contact-mode'
                            id='contact-mode'
                            type='radio'
                            onChange={() => handleSelected(patient)}
                          />
                        </td>
                      )}

                      <td>
                        {checkedOption ? (
                          <b className='color-prim'>
                            {patient.name} {patient.surname}
                          </b>
                        ) : (
                          <Link to={`${routes.patientArchive(patient.id)}`}>
                            <b className='color-prim text-decoration-underline'>
                              {patient.name} {patient.surname}
                            </b>
                          </Link>
                        )}
                      </td>
                      <td>
                        {patient.health_services &&
                            (patient.health_services)
                            .slice(0, 3)
                            .map((service: string, key: number) => (
                              <span
                                key={key}
                                className={`service-chip asd border-left-${service}`}
                              >
                                {service.charAt(0).toUpperCase()
                                    + service.slice(1).replace('_', ' ')}
                              </span>
                            ))}
                        {patient.health_services.length  > 3 && (
                          <span className='service-chip'>
                            {patient.health_services.length - 3} más
                          </span>
                        )}
                      </td>
                      <td>
                        { patient.last_measure !== '' ? moment(patient.last_measure).format("DD/MM/YYYY") : 'Sin mediciones'}
                      </td>

                      {!checkedOption && (
                        <td>
                          <Button
                            className='px-0 icon-circle'
                            onClick={()=> patient.id && handleChooseActivityShow(patient.id)}
                          >
                            <i className='ssp-add'></i>
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} align='center' className='pt-4'>
                      No tienes pacientes registrados
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <TablePagination
              total={total!}
              filters={filters}
              handleFilter={handleFilter}
              perPage={perPage}
            />
          </div>
          
        </>
      ) : (
        <div className='text-center'>
          <EmptyTable h1='Aún no tienes ningún paciente en la herramienta ' />
          <Button className='activity-btn m-auto' onClick={handleNewPatientShow}>
            <div className='icon-circle big bg-prim30 color-prim'>
              <i className='ssp-accesibility font-xl'></i>
              <span>+</span>
            </div>
            <h5 className='font-l color-prim fw-bold my-2'>Nuevo paciente</h5>
            <p className='font-m color-prim fw-light'>
              Añade a un nuevo paciente a tu listado de forma manual
            </p>
          </Button>
        </div>
      )}

      <ChooseActivityModal
        ChooseActivityShow={ChooseActivityShow}
        handleChooseActivityClose={handleChooseActivityClose}
        source="patientTable"
        patientID={patientIDSelected}
      />
      <NewPatientModal
        NewPatientShow={NewPatientShow}
        handleNewPatientClose={handleNewPatientClose}
      />
    </>
  );
};

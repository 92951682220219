import axios from "axios";
const { REACT_APP_API_URL } = process.env;
export const SandozApi = (withAuthorization: boolean = true) => {
  let headers: { 'Content-Type': string, Authorization?: string } = {
    'Content-Type': 'application/json',
  }
  if(withAuthorization) headers = { ...headers, Authorization: withAuthorization ?  `${localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : null}` : '' }
  return axios.create({
    baseURL: REACT_APP_API_URL || 'https://seguimiento-pacientes-api.syntonize.dev',
    headers
  })
}

import * as yup from 'yup';
import i18next from 'i18next';

export const patientValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18next.t("patient.name"))
    .matches(
        /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
      i18next.t("patient.validationSchema.name")
    ),
  surname: yup
    .string()
    .required(i18next.t("patient.surname"))
    .matches(
        /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
      i18next.t("patient.validationSchema.surname")
    ),
  nif: yup
      .string()
      .required(i18next.t(`patient.nif`))
      .matches(
          /^[XYZ]?\d{7,8}[A-Z]$/i,
          i18next.t("patient.validationSchema.nif")
      ),
  telephone: yup
    .string()
    .required(i18next.t(`patient.validationSchema.telephone`))
    .matches(
      /^[679]{1}[0-9]{8}$/,
      i18next.t(`patient.validationSchema.telephone`)
    ),
  email: yup
    .string()
    .email(i18next.t(`patient.validationSchema.email`))
    /* .required(i18next.t(`patient.email`)) */,
  contacts: yup.array().of(
    yup.object().shape({
      role: yup.string().when('name', {
        is: (name: string) => name,
        then: yup.string().required(i18next.t("patient.validationSchema.role")),
      }),
      name: yup.string().matches(
          /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
        i18next.t("patient.validationSchema.name")),
      phone: yup.string().when('name', {
        is: (name: string) => name,
        then: yup.string().required(i18next.t("patient.telephone")),
        otherwise: yup.string().matches(
          /^[679]{1}[0-9]{8}$/,
          i18next.t(`patient.validationSchema.telephone`)
        ),
      }).matches(
        /^[679]{1}[0-9]{8}$/,
        i18next.t(`patient.validationSchema.telephone`)
      ),
    },[["role", "name"], ["phone", "name"]])
  )
});

export const initialEmploueeValues = { email: "",name: "",surname: "",nif: "", telephone: "", position: "" }

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { INoteState } from "../../../interfaces";
import { noteById, noteListByPatient } from "./slice";
//noteById
export const extraReducers = (builder: ActionReducerMapBuilder<INoteState>) => {
  builder
  .addCase(noteListByPatient.pending, (state) => {
    state.status = 'fetching'
  })
  .addCase(noteListByPatient.fulfilled, (state, action) => {
    state.status = 'fetched'
    state.list = action.payload.content
  })
  .addCase(noteById.pending, (state) => {
    state.status = 'fetching'
  })
  .addCase(noteById.fulfilled, (state, action) => {
    state.status = 'fetched'
    state.note = action.payload.content
  })
}
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './app/module/i18n/config'

/* CSS */
import 'bootstrap/dist/css/bootstrap.min.css';
import './app/theme/css/styles.css';
import './app/theme/css/ssp-icons.css';

/* Datepicker */
import 'react-datepicker/dist/react-datepicker.css';



import { App } from './app';
import { Provider } from 'react-redux';
import { store } from './app/module/store';
import { ScrollTop } from './app/core/customHooks/useScroll';


const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <BrowserRouter>
    <ScrollTop />
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
);

reportWebVitals();

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IFilterPatient, IHealthSurveyAnswer, IPatient } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../module/hooks"
import { setNotification } from "../../module/reducers/notification";
import { clearFilters, clearStatus, updateFilters, updateFiltersModal } from "../../module/reducers/patient";
import { deletePatient, findPatientById, registerPatient, updateHealthSurveyPatient, updatePatient } from "../../module/reducers/patient/slice";
import { routes } from "../../router/constants";
import { INotification } from "../../interfaces/notification.interfaces";

export const usePatient = (id?: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { list, total, filters, status, current } = useAppSelector(state => state.patient)

  useEffect(() => {
    const employeeById = async (id: string) => {
      dispatch(clearStatus())
      const { code } = await dispatch(findPatientById(id)).unwrap();
      if (code === 200) return;
      return navigate(routes.patients)
    }
    if (id) employeeById(id)
    return () => { dispatch(clearFilters())}
  }, [dispatch, id, navigate])

  const getPatientById = async (id: string) => {
    const patient = await dispatch(findPatientById(id)).unwrap()
    return patient
  }
  const handleCreatePatient = async (fields: IPatient, setSubmitting: (isSubmitting: boolean) => void, notification?: INotification) => {
    const { code, error, message, content } = await dispatch(registerPatient(fields)).unwrap();
    setSubmitting(false);
    if (error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    //navigate(notification?.navigateTo ?? '/')
    dispatch(setNotification({ ...notification!, link: `${routes.patients}/archivo/${content?.id}` }))
  }
  const handleUpdatePatient = async (fields: IPatient, setSubmitting: (isSubmitting: boolean) => void, notification?: INotification, redirect?: boolean) => {
    const { error, message, code } = await dispatch(updatePatient(fields)).unwrap();
    setSubmitting(false);
    if (error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    notification?.navigateTo && navigate(notification?.navigateTo)
    notification && dispatch(setNotification({ ...notification! }))
  }

/**
 * This function updates a patient's health survey and handles notifications and redirects.
 */
  const handleUpdatePatientHealthSurvey = async (patientID: string, answers: IHealthSurveyAnswer[], notification?: INotification, redirect?: boolean) => {
    const fields = { patientID, answers };
    const { error, message, code } = await dispatch(updateHealthSurveyPatient(fields)).unwrap();
    if (error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    redirect && navigate(routes.home)
    notification && dispatch(setNotification({ message: notification.message, status: notification.status, show: notification.show, type: notification.type }))
  }


  //FILTER
  const handleFilter = async (filter: keyof IFilterPatient, value: number | string, isArray: boolean = false) => {
    if(value.toString().length >= 3 || value.toString().length == 0){
      if (!isArray) return dispatch(updateFilters({ ...filters, [filter]: value }))
      const getFilters = filters[filter] as string[];
      if (getFilters.find(filterValue => filterValue === value)) return;
      return dispatch(updateFilters({ ...filters, [filter]: [...getFilters, value] }))
    }

  }
  const handlerPerPage = async (value: number) => {
    return dispatch(updateFilters({ ...filters, perPage: value }))
  }

  const handlerPerPageNoUpdate = async (value: number) => {
    return dispatch(updateFilters({ perPage: value, page: 1, order_mode: "", order_field: "", search: "", pathologies: [], services: [] }))
  }
  const handlerOrderFilter = async (orderValue: string, orderType: string) => {
    return dispatch(updateFilters({ ...filters, order_field: orderType, order_mode: orderValue }))
  }
  const handleRemoveFilter = async (filter: keyof IFilterPatient, value: number | string) => {
    const getFilters = filters[filter] as string[];
    return dispatch(updateFilters({ ...filters, [filter]: getFilters.filter(filterName => filterName !== value) }))
  }


//FILTERMODAL
  const handleFilterModal = async (filter: keyof IFilterPatient, value: number | string, isArray: boolean = false) => {
    if(value.toString().length >= 3 || value.toString().length == 0){
      if (!isArray) return dispatch(updateFiltersModal({ ...filters, [filter]: value }))
      const getFilters = filters[filter] as string[];
      if (getFilters.find(filterValue => filterValue === value)) return;
      return dispatch(updateFiltersModal({ ...filters, [filter]: [...getFilters, value] }))
    }
  }
  const handlerPerPageModal = async (value: number) => {
    return dispatch(updateFiltersModal({ ...filters, perPage: value }))
  }
  const handlerOrderFilterModal = async (orderValue: string, orderType: string) => {
    return dispatch(updateFiltersModal({ ...filters, order_field: orderType, order_mode: orderValue }))
  }
  const handleRemoveFilterModal = async (filter: keyof IFilterPatient, value: number | string) => {
    const getFilters = filters[filter] as string[];
    return dispatch(updateFiltersModal({ ...filters, [filter]: getFilters.filter(filterName => filterName !== value) }))
  }

  const findCompleteNameById = (id: IPatient['id']) => {
    const patient = list.find(patient => patient.id === id)
    return `${patient?.name} ${patient?.surname}`
  }
  const del = async (id: string) => {
    const { error, message, code } = await dispatch(deletePatient(id)).unwrap();
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    navigate(routes.patients)
    dispatch(setNotification({ message: "El paciente ha sido eliminado correctamente", status: "success", show: true, type: "modal" }))
  }
  const initialValues = { name: "", surname: "", address: "", telephone: "", email: "", health_card: "", weight: "", size: "", nif: "", note: "", pathologies: [], other_pathologies: [], allergies: "", medication: "", generic_health_services: [], measurement_answers: [], contact_by: "Teléfono", birthdate: new Date(), contacts: [{ name: '', phone: '', role: '' }, { name: '', phone: '', role: '' }]} as IPatient;
  return {
    patientStatus: status,
    patients: list,
    total,
    filters,
    getPatientById,
    createPatient: handleCreatePatient,
    updatePatient: handleUpdatePatient,
    handleFilter,handleFilterModal, handlerOrderFilter, handlerPerPageNoUpdate,
    handleRemoveFilter, handleRemoveFilterModal, handlerOrderFilterModal,
    findCompleteNameById,
    pathologies: ['Hipertensión', 'Colesterol', 'Diabetes', 'Ansiedad', 'Tiroides', 'Fumador'],
    patient: current,
    deletePatient: del,
    initialValues,
    handlerPerPage,handlerPerPageModal,
    updatePatientHealthSurvey: handleUpdatePatientHealthSurvey,
  }
}

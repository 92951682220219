import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IUserState } from "../../../interfaces/user.interfaces";
import { login } from "./"
import { checkAuth, initialState, updateProfile } from "./slice";

export const userExtraReducers = (builder: ActionReducerMapBuilder<IUserState>) => {
  builder
    // Auth Cases
    .addCase(login.fulfilled, (state, action) => {
      if(action.payload.error) return state;
      state.info = action.payload;
      state.status = 'logged';
      return state
    })
    .addCase(login.rejected, (state) => {
      state.status = 'error';
      return state
    })
    .addCase(checkAuth.fulfilled, (state, action) => {
      if(action.payload.error) return {...state, info: initialState.info, status: 'guest'}
      state.info = action.payload.content;
      state.status = 'logged';
      return state;
    })
    .addCase(checkAuth.rejected, (state) => {
      state.status = 'guest';
      state.info = initialState.info;
      return state
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.info = {...state.info, ...action.payload.content}
    });
}
import { Container, Dropdown, Button } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';
import { routes } from "../../../router/constants";
import { useAuth } from "../../customHooks/useAuth";
import { SignOutComponent } from "./SignOutComponent";


export const Header: React.FC = (): JSX.Element => {
  const activeHandle = ({ isActive }: { isActive: boolean }) => isActive ? 'current' : undefined
  const { user } = useAuth()
  return (
    <header>
      <Container>
        <div className='d-flex justify-content-between align-items-center w-100 h-100'>
          <div className='logo'>
            <Link to={routes.home}>
              {" "}
              <img src='/assets/img/logo.svg' alt='Logo Sandoz' />
            </Link>
          </div>

          <nav className='h-100'>
            <ul className='h-100'>
              <li>
                <NavLink to={routes.home} className={activeHandle}>
                  Panel de control
                </NavLink>

                <span className='corners'></span>
              </li>
              <li>
                <NavLink to='/pacientes' className={activeHandle}>
                  Pacientes
                </NavLink>

                <span className='corners'></span>
              </li>
              <li>
                <NavLink to='/servicios' className={activeHandle}>
                  Servicios
                </NavLink>

                <span className='corners'></span>
              </li>
              {/* <li>
                <NavLink to={routes.reminders} className={activeHandle}>

                  Recordatorios </NavLink>
              </li> */}
             
              <li className='ps-lg-5 ps-3'>
                <Dropdown className='profile-select'>
                  <Dropdown.Toggle id='dropdown-basic'>
                    <div className='avatar'>
                      <i className='ssp-pharmacy font-l '></i>
                    </div>
                    <div className='text'>
                      <p>
                        <b>{user?.name}</b>
                      </p>
                      <p className='font-s fw-light color-prim70'>
                        {user?.owner_name} {user?.owner_surname}
                      </p>
                    </div>
                    <i className='ssp-chevron-down font-l color-prim mx-2 lh-1'></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      className='fw-light color-dark'
                      href='/perfil'
                    >
                      <Button className='transparent p-1 d-inline-flex'>
                        <span className='color-dark fw-light'>
                          {" "}
                          Datos de la farmacia
                        </span>
                      </Button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <SignOutComponent />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </header>
  );
};

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IAppointment, IAppointmentState } from "../../../interfaces/appointment.interfaces";
import { AppointmentById, deleteAppointment, listByPatient, serviceList } from "./slice";

export const reminderExtraReducers = (builder: ActionReducerMapBuilder<IAppointmentState>) => {
  builder
    .addCase(listByPatient.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(listByPatient.fulfilled, (state, action) => {
      state.list = action.payload.content
      state.status = 'fetched'
    })
    .addCase(serviceList.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(serviceList.fulfilled, (state, action) => {
      state.list = action.payload.content
      state.status = 'fetched'
    })
    .addCase(AppointmentById.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(AppointmentById.fulfilled, (state, action) => {
      state.appointment = action.payload.content
      state.status = 'fetched'
    })
    .addCase(deleteAppointment.fulfilled, (state, action) => {
      const { id } =  action.meta.arg
      state.list = state.list.filter((appointment: IAppointment) => appointment.id !== id)
    })

}
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../customHooks/useAuth';

export const LoginPage = () => {
  const { login } = useAuth()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [searchParams] = useSearchParams();
  const tokenLogin = searchParams.get('token');


  useEffect(() => {
    if(tokenLogin){
      login({token: tokenLogin}, true)
    }
  }, []);

  return (
    <div className="login-page">
      
    </div>
  )
}

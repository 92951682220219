import { useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useAppDispatch } from '../../../../module/hooks';
import { serviceList } from '../../../../module/reducers/service/slice';
import { useAppointment } from '../../../customHooks/useAppointment';
import { useService } from '../../../customHooks/useService';

export const NewAppointmentStep2 = () => {
  const { services, status, findServiceStateById } = useService()
  const { handleAppointment, appointment } = useAppointment({})
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(serviceList());
  }, [dispatch])
  return (
    <Row className='mt-3 align-items-end'>
      <Col className='' lg={6}>
        <h3 className='font-ml fw-bold mb-3 color-sec'>Datos de la cita</h3>
        <Form.Group className='form-group select with-icon' controlId='tipo'>
          <Form.Label className='mb-4'>
            Tipo de cita (telefónica o presencial):
          </Form.Label>
          <Form.Select
            name='appointment_type'
            value={appointment.type}
            aria-label=''
            onChange={({ target }) =>
              handleAppointment({
                type: target.value,
              })
            }
          >
            <option>Seleccionar tipo de cita</option>
            <option value='telefonica'>Telefónica</option>
            <option value='presencial'>Presencial</option>
          </Form.Select>
          <i className='ssp-storefront'></i>
        </Form.Group>
      </Col>
      <Col className='' lg={6}>
        <Form.Group
          className='form-group select with-icon'
          controlId='appointment-motive'
        >
          <Form.Label className='mb-4'>
            Servicio de Salud en que quieras agendar la cita:
          </Form.Label>
          <Form.Select
            aria-label='appointment-motive'
            disabled={status === "fetching"}
            value={appointment.health_service_id}
            onChange={({ target }) =>
              handleAppointment({
                health_service_id: target.value,
                health_service_name: findServiceStateById(target.value)?.name,
              })
            }
          >
            {status === "fetching" ? (
              <option>Cargando servicios...</option>
            ) : (
              <option>Seleccionar servicio de salud</option>
            )}
            {!!services.length &&
              services.map((service, key) => (
                <option key={key} value={service.id}>
                  {service.label}
                </option>
              ))}
          </Form.Select>
          <i className='ssp-medkit'></i>
        </Form.Group>
      </Col>
    </Row>
  );
}

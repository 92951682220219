import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Footer } from "./app/core/components/shared"
import { ToastAlerComponent } from "./app/core/components/shared/alerts/ToastAlert"
import { Header } from "./app/core/components/shared/HeaderComponent"
import { useAuth } from "./app/core/customHooks/useAuth"
import { routes } from "./app/router/constants"
import { MainRouter } from "./app/router/MainRouter"
import { BasicLoader } from "./app/core/components/shared/loaders/BasicLoader"

export const App = () => {
  const { pathname } = useLocation()
  const { login, register, forgotPassword, recoverPassword } = routes
  const exclude = [login, register, forgotPassword, recoverPassword]
  const { isChecking, checkAuth } = useAuth()

  useEffect(() => {
  if(isChecking) checkAuth();
  }, [isChecking, checkAuth])

  if(isChecking) return <div className="loader"><BasicLoader></BasicLoader></div>

  return (
    <>
      {
        !exclude.find(path => pathname.includes(path)) && <Header />
      }
      <main>
        <div className="content-wrapper">
          <MainRouter />
        </div>
        <ToastAlerComponent />
        <Footer />
      </main>
    </>
  )
}

import { FormikValues } from "formik";
import moment from "moment";
import { Form, InputGroup } from "react-bootstrap";
import { InputValues } from "../../../../interfaces/form.interfaces";

export const InputField = ({ name, maxDate, type, values, errors, handleChange, label, touched, handleBlur, icon, placeholder }: InputValues<FormikValues>) => {
  return (
    <Form.Group className={`form-group ${errors[name] && touched[name] && 'error'}`} controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      {
        maxDate ?
          <Form.Control type={type} max={moment().format('YYYY-MM-DD')} value={values[name]} onChange={handleChange} onBlur={handleBlur} placeholder={placeholder || label} name={name} />
          :
          <Form.Control type={type} value={values[name]} onChange={handleChange} onBlur={handleBlur} placeholder={placeholder || label} name={name} />
      }
      {
        icon && <button onClick={icon.handleClick} className="show-password" type="button">{icon.component}</button>
      }
      {errors[name] && touched[name] && <p className="error-msg">{`${errors[name]}`}</p>}
    </Form.Group>
  )
}


export const InputGroupField = ({ prefixText, name, type, values, errors, handleChange, label, touched, handleBlur, icon, placeholder }: InputValues<FormikValues>) => {
  return (
    <Form.Group className={`form-group ${errors[name] && touched[name] && 'error'}`} controlId={name}>
      <Form.Label>{label}</Form.Label>
      <InputGroup className="form-group mb-0">
        <Form.Control
          type={type}
          value={values[name]}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder || label}
          name={name}
        />
        <InputGroup.Text>{prefixText}</InputGroup.Text>
      </InputGroup>
      {
        icon && <button onClick={icon.handleClick} className="show-password" type="button">{icon.component}</button>
      }
      {errors[name] && touched[name] && <p className="error-msg">{`${errors[name]}`}</p>}
    </Form.Group>
  )
}

export const TextAreaField = ({ name, values, errors, handleChange, label, touched, handleBlur, icon, placeholder }: InputValues<FormikValues>) => {
  return (
    <Form.Group className={`form-group ${errors[name] && touched[name] && 'error'}`} controlId={name}>
      {
        label && <Form.Label>{label}</Form.Label>
      }
      <InputGroup className="form-group mb-0">
        <Form.Control
          as="textarea"
          style={{ minHeight: '300px' }}
          value={values[name]}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder || label}
          name={name}
        />
      </InputGroup>
      {
        icon && <button onClick={icon.handleClick} className="show-password" type="button">{icon.component}</button>
      }
      {errors[name] && touched[name] && <p className="error-msg">{`${errors[name]}`}</p>}
    </Form.Group>
  )
}
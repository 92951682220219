import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from "react-bootstrap";
import { groupBy } from "lodash";
import { useMeasurement } from '../../customHooks/useMeasurement';
import { usePatient } from '../../customHooks/usePatient';
import * as _ from 'lodash';

type IProps = {
  patientID: string;
  readOnly?: boolean;
};

const HealthSurvey: React.FC<IProps> = ({ patientID, readOnly }) => {
  const { handleMeasurement } = useMeasurement();
  const { patient } = usePatient(patientID);
  const [answers, setAnswers] = useState<any[]>([]);
  const groupBySurvey = groupBy(patient?.health_survey, "groupTitle");

  const handlerSelectedAnswer = (item: any, value: string) => {
    const surveyData = {
      questionID: item.questionID,
      value,
      rating: "",
    };
    const exist = answers.find(
      (answer: { questionID: string }) => answer.questionID === item.questionID
    );
    if (exist && Object.keys(exist).length > 0) {
      const removeAnswer = answers.filter(
        (answer: { questionID: string }) =>
          answer.questionID !== item.questionID
      );
      surveyData.value === ""
        ? setAnswers([...removeAnswer])
        : setAnswers([...removeAnswer, surveyData]);
    } else {
      setAnswers([...answers, surveyData]);
    }
  };

  useEffect(() => {
    handleMeasurement({
      health_patient_survey: answers,
    });
  }, [answers]);

  /* It loops through the `healthSurvey` array of the `patient` object to create the `preLoad` array and set it to the `answers` state. With this operation, we make sure of having the whole health survey object to avoid overwriting in the database. */
  useEffect(() => {
    const preLoad: any[] = [];
    patient?.health_survey?.forEach((item: { questionID: any; value: any }) => {
      const { questionID, value } = item;
      const surveyData = {
        questionID,
        value,
        rating: "",
      };
      preLoad.push(surveyData);
    });
    setAnswers(preLoad);
  }, [patient?.health_survey]);

  return (
    <div className='survey mt-4'>
      <Form>
        <Row>
          <Col lg={6}>
            <div className='radius-8 p-3 mb-3 bg-prim5'>
              <h5 className='fw-semibold font-m'>Datos generales</h5>
              <Row>
                {groupBySurvey["Datos generales"].map((question, key) => {
                  const { questionID, type, title, value } = question;
                  const answerValue = answers.find(
                    (answer) => answer.questionID === questionID
                  )?.value;
                  const fieldValue = answerValue ? answerValue : value;
                  return (
                    <>
                      <Col xs={12} key={`${questionID}-${key}`}>
                        <div className='bg-white radius-8 p-3 mt-2 pt-2'>
                          <Form.Group
                            className='form-group mb-0'
                            controlId={questionID}
                          >
                            <Form.Label>{title}</Form.Label>
                            <Form.Control
                              type={type}
                              name={questionID}
                              value={fieldValue}
                              disabled={readOnly}
                              onChange={({ target }) => {
                                handlerSelectedAnswer(question, target.value);
                              }}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </div>
            <div className='radius-8 p-3 mb-3 bg-prim5'>
              <h5 className='fw-semibold font-m'>Estilo de vida</h5>
              <Row>
                {groupBySurvey["Estilo de vida"].map((question, key) => {
                  const { questionID, type, values, title, value } = question;
                  const answerValue = answers.find(
                    (answer) => answer.questionID === questionID
                  )?.value;
                  const fieldValue = answerValue ? answerValue : value;
                  return (
                    <>
                      <Col lg={6} key={`${questionID}-${key}`}>
                        <div className='bg-white radius-8 p-3 mt-2 pt-2'>
                          <Form.Group
                            className='form-group mb-0'
                            controlId={questionID}
                          >
                            <Form.Label>{title}</Form.Label>
                            {type === "select" && (
                              <>
                                <Form.Select
                                  key={`${questionID}-${key}-select`}
                                  aria-label={title}
                                  name={questionID}
                                  value={fieldValue}
                                  disabled={readOnly}
                                  onChange={({ target }) => {
                                    handlerSelectedAnswer(
                                      question,
                                      target.value
                                    );
                                  }}
                                >
                                  <option key='select-01' value=''>
                                    Seleccionar
                                  </option>
                                  {values?.map((values, key) => {
                                    const { value, label } = values;
                                    return (
                                      <>
                                        <option key={key} value={value}>
                                          {label}
                                        </option>
                                      </>
                                    );
                                  })}
                                </Form.Select>
                              </>
                            )}
                          </Form.Group>
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col lg={6}>
            <div className='radius-8 p-3 mb-3 bg-prim5'>
              <h5 className='fw-semibold font-m'>Salud</h5>
              <Row>
                {groupBySurvey["Salud"].map((question, key) => {
                  const { questionID, type, values, title, value } = question;
                  const answerValue = answers.find(
                    (answer) => answer.questionID === questionID
                  )?.value;
                  const fieldValue = answerValue ? answerValue : value;
                  let orderedValues = _.cloneDeep(values)
                  if(questionID === 'salud_04' || questionID === 'salud_03'){
                    orderedValues.sort((a, b) => a.value.localeCompare(b.value))
                  }
                  return (
                    <>
                      <Col lg={12} key={`${questionID}-${key}`}>
                        <div className='radius-8 p-3 mt-2 bg-white d-flex align-items-center justify-content-between'>
                          {type === "radio" && (
                            <>
                              <p className='fw-normal lh-1'>{title}</p>
                              <div key={questionID} className=''>
                                {values?.map((values, key) => {
                                  const { value, label } = values;
                                  return (
                                    <Form.Check
                                      className='ms-4 me-0'
                                      inline
                                      reverse
                                      label={`${label}`}
                                      name={questionID}
                                      value={value}
                                      disabled={readOnly}
                                      checked={fieldValue === value}
                                      type='radio'
                                      id={`${questionID}-radio-${label.toLowerCase()}`}
                                      onChange={() => {
                                        handlerSelectedAnswer(question, value);
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </>
                          )}
                          {type === "select" && (
                            <>
                              <Form.Group
                                className='form-group mb-0'
                                controlId={questionID}
                              >
                                <Form.Label>{title}</Form.Label>
                                <Form.Select
                                  aria-label={title}
                                  name={questionID}
                                  value={fieldValue}
                                  disabled={readOnly}
                                  onChange={({ target }) => {
                                    handlerSelectedAnswer(
                                      question,
                                      target.value
                                    );
                                  }}
                                >
                                  <option value=''>Seleccionar</option>
                                  {orderedValues?.map((values, key) => {
                                    const { value, label } = values;
                                    return (
                                      <>
                                        <option value={value}>{label}</option>
                                      </>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </>
                          )}
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col lg={12}>
            {groupBySurvey["Notas adicionales"].map((question, key) => {
              const { questionID, type, values, title, value } = question;
              const answerValue = answers.find(
                (answer) => answer.questionID === questionID
              )?.value;
              const fieldValue = answerValue ? answerValue : value;
              return (
                <>
                  <div
                    className='radius-8 p-3 mb-3 bg-prim5'
                    key={`${questionID}-${key}`}
                  >
                    <h5 className='fw-semibold font-m mb-2'>{title}</h5>
                    <Form.Group
                      className='form-group mb-0'
                      controlId={questionID}
                    >
                      {type === "textarea" && (
                        <Form.Control
                          as={type}
                          placeholder='Escríbe aquí tus notas adicionales sobre este paciente...'
                          style={{
                            minHeight: "80px",
                            backgroundColor: "white",
                          }}
                          value={fieldValue}
                          disabled={readOnly}
                          onChange={({ target }) => {
                            handlerSelectedAnswer(question, target.value);
                          }}
                        />
                      )}
                    </Form.Group>
                  </div>
                </>
              );
            })}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default HealthSurvey

import { Container, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { NewAppointmentStep1 } from './new-appointment-steps/NewAppointmentStep1';
import { NewAppointmentStep2 } from './new-appointment-steps/NewAppointmentStep2';
import { NewAppointmentStep3 } from './new-appointment-steps/NewAppointmentStep3';
import { NewAppointmentStep4 } from './new-appointment-steps/NewAppointmentStep4';
import { useState } from 'react';
import { useAppointment } from '../../customHooks/useAppointment';
import { BasicLoader } from '../../components/shared/loaders';
import { NewActivityModal } from '../../components/shared/modals/NewActivityModal';
import { useAppDispatch } from '../../../module/hooks';
import { setNewAppointmentID } from '../../../module/reducers/appointment';

interface IProps {
  patientID?: string;
  handleNewAppointmentClose?: () => void;
}

export const NewAppointmentPage: React.FC<IProps> = ({
  patientID,
  handleNewAppointmentClose,
}): JSX.Element => {
  const { patient: patientId, appointmentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const ID = patientID || patientId;
  const {
    createAppointment,
    appointment,
    validations,
    updateAppointment,
    newAppointmentID,
  } = useAppointment({ patient: patientID });

  const { patientInfo } = appointment;
  let [step, setStep] = useState(1);
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const endSteps = () => {
    setLoading(true);
    ID && appointmentId
      ? updateAppointment(appointment, setLoading)
      : createAppointment(appointment, setLoading, true);
    handleNewAppointmentClose && handleNewAppointmentClose();
  };

  const endStepsPrint = () => {
    dispatch(setNewAppointmentID(''));
    navigate('/');
    handleNewAppointmentClose && handleNewAppointmentClose();
  };

  const [NewActivityShow, setNewActivityShow] = useState(false);
  const handleNewActivityShow = (title: string) => {
    setNewActivityShow(true);
    setTitle(title);
    if(document.getElementById('table-patient-modal')){
      handleNewAppointmentClose && handleNewAppointmentClose();
    }
  };

  return (
    <>
      <Container className='pb-5'>
        <div className='d-flex align-items-center' id={'new-appointment-modal'}>
          <label className='color-prim fw-light font-s'>Paciente:</label>

          <div className='d-flex bg-prim10 align-items-center radius-24 p-2 mx-3'>
            <div className='bg-prim30 icon-circle '>
              <i className='ssp-person color-prim font-l'></i>
            </div>
            <div className='px-2 text-start font-s'>
              <p className='color-dark fw-semibold lh-1'>
                {patientInfo?.name} {patientInfo?.surname}
              </p>
            </div>
          </div>

          <Button
            className='btn transparent color-prim font-xs p-0'
            onClick={() => {
              handleNewActivityShow("Nueva Cita");
            }}
          >
            Editar
          </Button>
        </div>

        <div className='steps mb-5 mt-4'>
          {/* El orden de los botones está al reves por necesidades de maquetacion, por css se invierte para asi poder aplicar estilos adecuadamente */}

          <div
            className={
              (step > 4 ? "completed " : "") +
              (step !== 4 && step < 4 ? "disabled" : "")
            }
          >
            <span className='dot'></span>
            <p className='fw-semibold font-s'>
              <i className='ssp-checkmark-circle'></i>
              <span>Confirmación</span>
            </p>
          </div>

          <div className='bar'></div>

          <div
            className={
              (step > 3 ? "completed " : "") +
              (step !== 3 && step < 3 ? "disabled" : "")
            }
          >
            <span className='dot'></span>
            <p className='fw-semibold font-s'>
              <i className='ssp-checkmark-circle'></i>
              <span>Fecha y hora</span>
            </p>
          </div>

          <div className='bar'></div>

          <div
            className={
              (step > 2 ? "completed " : "") +
              (step !== 2 && step < 2 ? "disabled" : "")
            }
          >
            <span className='dot'></span>
            <p className='fw-semibold font-s'>
              <i className='ssp-checkmark-circle'></i>
              <span>Datos de la cita</span>
            </p>
          </div>

          <div className='bar'></div>

          <div
            className={
              (step > 1 ? "completed " : "") +
              (step !== 1 && step < 1 ? "disabled" : "")
            }
          >
            <span className='dot'></span>
            <p className='fw-semibold font-s'>
              <i className='ssp-checkmark-circle'></i>
              <span>Profesionales</span>
            </p>
          </div>
        </div>

        <Form className='pt-2'>
          {step === 1 && <NewAppointmentStep1 />}
          {step === 2 && <NewAppointmentStep2 />}
          {step === 3 && <NewAppointmentStep3 />}
          {step === 4 && <NewAppointmentStep4 />}
        </Form>

        <div className='d-flex align-items-center justify-content-end mt-4'>
          {step > 1 && (
            <>
              {!newAppointmentID && (
                <Button className='prim outline' onClick={prevStep}>
                  Anterior
                </Button>
              )}
            </>
          )}

          {/* Poner la clase disabled cuando corresponda */}
          {step < 4 && (
            <Button
              className='prim ms-2'
              disabled={validations(step)}
              onClick={nextStep}
            >
              Siguiente
            </Button>
          )}

          {step === 4 && (
            <>
              {newAppointmentID ? (
                <Button className='prim ms-2' onClick={endStepsPrint}>
                  Cerrar
                </Button>
              ) : (
                <Button className='prim ms-2' onClick={endSteps}>
                  {loading ? <BasicLoader /> : "Confirmar cita"}
                </Button>
              )}
            </>
          )}
        </div>
      </Container>

      <NewActivityModal
        NewActivityShow={NewActivityShow}
        handleNewActivityClose={() => setNewActivityShow(false)}
        title={title}
        type='appointment'
      />
    </>
  );
};

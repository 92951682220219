
import { Formik, Form } from 'formik';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { changePasswordValidationSchema, updateProfileValidationSchema } from '../../../helpers/schemas/authSchema';
import { routes } from '../../../router/constants';
import { InputField } from '../../components/shared/fields';
import { BasicLoader } from '../../components/shared/loaders';
import { useAuth } from '../../customHooks/useAuth';

export const EditPharmacyDataPage = () => {
  const { user, updateUser, updatePassword } = useAuth()
  const { t } = useTranslation()
  return (
    <>
      <Container className="pb-5">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="fw-bold font-xxml mb-4">Editar datos</h1>
        </div>
        <div>
          <Link className="btn transparent" to={routes.profile}>
            <i className="ssp-chevron-left me-1"> </i>
            <span className="font-s">Volver</span>
          </Link>
        </div>

        <div className="mt-5">
          <Formik
            initialValues={user}
            validationSchema={updateProfileValidationSchema}
            onSubmit={(fields, actions) => updateUser(fields, actions.setSubmitting)}
          >
            {
              (props) => {
                return (
                  <Form>
                    <Row>
                      <Col className="pe-lg-5" lg={6}>
                        <h3 className="font-ml fw-bold mb-4">Datos de la farmacia</h3>
                        <InputField {...props} type="text" label={t('user.name')} name="name" />
                        <InputField {...props} type="text" label={t('user.owner_name')} name="owner_name" />
                        <InputField {...props} type="text" label={t('user.owner_surname')} name="owner_surname" />
                      </Col>
                      <Col className="ps-lg-5" lg={6}>
                        <Row className="mt-5">
                          <Col lg={12}>
                            <InputField {...props} type="text" label={t('user.telephone')} name="telephone" />
                            <InputField {...props} type="text" label={t('user.cif')} name="cif" />
                            <InputField {...props} type="text" label={t('user.address')} name="address" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="text-end mt-4">
                      <Button className="btn prim" disabled={props.isSubmitting} type="submit">
                        {
                          props.isSubmitting ? <BasicLoader /> : 'Confirmar cambios'
                        }
                      </Button>
                    </div>
                  </Form>
                )
              }
            }
          </Formik>

          <Formik
            initialValues={{new_password: "", old_password: "", repeat_new_password: ""}}
            validationSchema={changePasswordValidationSchema}
            onSubmit={(fields, actions) => updatePassword(fields, actions.setSubmitting)}
          >
            {
              (props) => {
                return (
                  <Form>
                    <Row className="mt-5">
                      <Col className="pe-lg-5" lg={6}>
                        <h3 className="font-ml fw-bold mb-4">Cambiar contraseña</h3>
                        <InputField {...props} type="password" label={`Contraseña actual`} name="old_password" />
                        <InputField {...props} type="password" label={`Nueva contraseña`} name="new_password" />
                        <InputField {...props} type="password" label={`Confirmar nueva contraseña`} name="repeat_new_password" />
                      </Col>
                    </Row>
                    <div className="text-end mt-4">
                      <Button className="btn prim" disabled={props.isSubmitting} type="submit">
                        {
                          props.isSubmitting ? <BasicLoader /> : 'Cambiar contraseña'
                        }
                      </Button>
                    </div>
                  </Form>
                )
              }
            }
          </Formik>
        </div>
      </Container>
    </>
  )
}

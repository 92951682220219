import { Row, Col, Form, Button } from 'react-bootstrap';
import { useMeasurement } from '../../../customHooks/useMeasurement';

export const NewVisitStep4 = () => {
  const { handleMeasurement, measurement } = useMeasurement()
  return (
    <Row >

      <Col lg={12} >

        <div className="radius-8 bg-prim10 p-4 d-inline-flex align-items-center">
          <i className="ssp-checkmark-circle color-prim70 font-xl me-2"></i>
          <p>Ya casi estanos para finalizar y guardar la medición haz click en "terminar medición".</p>
        </div>

        {/* <h4 className="font-ml fw-bold mb-4 mt-5">
          ¿Necesitas hacer algo más?
      </h4>

        <Button
          className='activity-btn flex-row'
        >
          <div className='icon-circle big bg-prim30 color-prim'>
            <i className='ssp-calendar-clean font-xl'></i>

            <span>+</span>
          </div>

          <div className="text-start ps-4">
            <h5 className='font-m color-prim fw-bold mb-2'>Nueva Cita</h5>
            <p className='font-s color-prim fw-light'>
              Agenda una cita para este Servicio de Salud
                </p>
          </div>

        </Button> */}
      </Col>
    </Row>


  )
}

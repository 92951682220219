import { Form, Formik } from 'formik';
import { patientValidationSchema } from '../../../helpers/schemas/patientSchema';
import { usePatient } from '../../customHooks/usePatient';
import { NewPatientFirstStep } from './NewPatientFirstStep';
import { INotification } from '../../../interfaces/notification.interfaces';
import { routes } from '../../../router/constants';
import {NewPatientModal} from "../../components/shared/modals/NewPatientModal";
import {registerPatient} from "../../../module/reducers/patient/slice";
import {useAppDispatch} from "../../../module/hooks";
import {setNotification} from "../../../module/reducers/notification";

interface IProps {
  edit?: boolean;
  patientID?: string;
  handleNewPatientClose?: any;
}

export const NewPatientDataPage: React.FC<IProps> = ({ 
  edit,
  patientID, handleNewPatientClose
}) => {
  const dispatch = useAppDispatch();

  const {
    createPatient,
    updatePatient,
    initialValues,
    patient,
    patientStatus,
  } = usePatient(patientID);
  const notificationMessage: INotification = {
    message: edit
      ? "Paciente actualizado correctamente"
      : "Paciente nuevo creado correctamente",
    status: "success",
    show: true,
    type: "toast",
    icon: "ssp-eye-off font-l me-2",
    ...(edit && { navigateTo: `${routes.patientArchive(patient?.id)}` }),
  };
  const createNewPatient = async (fields: any, setSubmitting: any, notificationMessage: any) =>{
    //createPatient(fields, setSubmitting, notificationMessage)
    const { code, error, message, content } = await dispatch(registerPatient(fields)).unwrap();
    if(error){
      dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    }else{
      dispatch(setNotification({ ...notificationMessage!, link: `${routes.patients}/archivo/${content?.id}` }))
      handleNewPatientClose()
    }
  }
  return (
    <>
      {patientStatus === "fetched" && (
        <Formik
          initialValues={edit && patient ? patient : initialValues}
          validationSchema={patientValidationSchema}
          onSubmit={(fields, actions) =>
            edit
              ? updatePatient(
                  fields,
                  actions.setSubmitting,
                  notificationMessage
                )
              : createNewPatient(
                  fields,
                  actions.setSubmitting,
                  notificationMessage
                )
          }
        >
          {(props) => {
            return (
              <Form>
                <NewPatientFirstStep formProps={props} />
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

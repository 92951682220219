
import {useEffect, useState} from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../module/hooks';
import { useEmployee } from '../../customHooks/useEmployee';
import { useNote } from '../../customHooks/useNote';
import { employeeList } from '../../../module/reducers/employee/slice';

interface IProps {
  NewNoteShow: boolean;
  handleNewNoteClose: () => void;
}

export const NewNoteModal: React.FC<IProps> = ({
  NewNoteShow,
  handleNewNoteClose,
}) => {
  const dispatch = useAppDispatch();
  const { employees, employeeStatus, findCompleteNameById } = useEmployee();
  const { createNote, handleNote, note } = useNote();

  useEffect(() => {
    dispatch(employeeList(false));
  }, [dispatch]);

  const handleSubmit = async () => {
    createNote(note);
  }

  return (
    <Modal size='lg' show={NewNoteShow} onHide={handleNewNoteClose}>
      <Modal.Header className='justify-content-between' closeButton>
        <h5 className='fw-bold'>Nueva/Detalle nota</h5>
      </Modal.Header>
      <Modal.Body>
        {/*  Solo visible en vista de Nueva nota */}

        <div className='d-flex align-items-center'>
          <label className='color-prim fw-light font-s'>Paciente:</label>

          <div className='d-flex bg-prim10 align-items-center radius-24 p-2 mx-3'>
            <div className='bg-prim30 icon-circle '>
              <i className='ssp-person color-prim font-l'></i>
            </div>
            <div className='px-2 text-start font-s'>
              <p className='color-dark fw-semibold lh-1'>
                {note.patientInfo?.name} {note.patientInfo?.surname}
              </p>
            </div>
          </div>
        </div>
        <h3 className='font-ml fw-bold mb-3 color-sec mt-5'>
          Datos de la nota
        </h3>

        <p className='text-left fw-light color-dark50 font-s my-4'>
          Selecciona en el listado al farmacéutico responsable de crear esta
          nota
        </p>

        <Form.Group
          className='form-group select with-icon'
          controlId='responsable-agendar'
        >
          <Form.Select
            name='contact_by'
            aria-label=''
            disabled={employeeStatus === "fetching"}
            value={note.employee_id}
            onChange={({ target }) =>
              handleNote({
                employee_id: target.value,
                employee_complete_name: findCompleteNameById(target.value),
              })
            }
          >
            {employeeStatus === "fetching" ? (
              <option>Cargando servicios...</option>
            ) : (
              <option value=''>Seleccione el personal encargado</option>
            )}
            {!!employees.length &&
              employees.map((employee, key) => (
                <option key={key} value={employee.id}>
                  {employee.name} {employee.surname}
                </option>
              ))}
          </Form.Select>
          <i className='ssp-person'></i>
        </Form.Group>

        {/*  Solo visible en vista de detalle */}
        {/* <div className='bg-prim5 p-3 radius-12 d-flex align-items-center mt-2 mb-4'>
          <div className='form-group readonly mb-0'>
            <label className='fw-semibold'>Quién tomó la nota</label>
            <p className='font-s'>
              <i className='ssp-person me-1 font-m'></i> Nombre Apellidos
            </p>
          </div>
          <div className='form-group readonly mb-0 ps-5'>
            <label className='fw-semibold'>
              Fecha en la que se tomó la nota
            </label>
            <p className='font-s'>05/08/2023</p>
          </div>
        </div> */}
        <Form>
          <Form.Group className='form-group my-3' controlId='medition-resume'>
            <Form.Control
              as='textarea'
              placeholder='Escríbe aquí tu nota'
              style={{ minHeight: "100px" }}
              onChange={({ target }) =>
                handleNote({
                  note: target.value,
                })
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className='justify-content-end'>
        {/* <Button className='btn red' onClick={handleNewNoteClose}>
          Eliminar nota
        </Button> */}
        <Button className='btn prim outline' onClick={handleNewNoteClose}>
          Cancelar
        </Button>
        <Button className='btn prim' onClick={handleSubmit}>
          Terminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { useNavigate } from "react-router-dom"
import { IUserInfo, IUserPassword } from "../../interfaces"
import { ILoginUser, IRecoveryUser, IRegisterUser } from "../../interfaces/auth.interfaces"
import { useAppDispatch, useAppSelector } from "../../module/hooks"
import { setNotification } from "../../module/reducers/notification"
import { login, logout } from "../../module/reducers/user"
import { changePassword, checkAuth, isAuthSelector, recovery, register, updateProfile, userInfo } from "../../module/reducers/user/slice"
import { routes } from "../../router/constants"

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(userInfo)
  const isChecking = useAppSelector(state => state.user.status) === "checking"
  const isAuthenticated = useAppSelector(isAuthSelector)
  const navigate = useNavigate()
  
  const handleLogin = async (fields: ILoginUser, setSubmitting: boolean) => {
    const { error, message, code } = await dispatch(login({credentials: fields })).unwrap();
    console.log(error)
    console.log(message)
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    // return navigate(routes.home)
  }
  const handleRegister = async (fields: IRegisterUser, setSubmitting: (isSubmitting: boolean) => void) => {
    const { error, message, code } = await dispatch(register({credentials: fields })).unwrap();
    setSubmitting(false);
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    navigate(routes.login)
    dispatch(setNotification({ message: "Estamos verificando tu cuenta para que puedas acceder lo antes posible.", status: "success", show: true, type: "modal" }))
  }
  const handleRecovery = async (fields: IRecoveryUser, setSubmitting: (isSubmitting: boolean) => void) => {
    const { error, message, code } = await dispatch(recovery({credentials: fields })).unwrap();
    setSubmitting(false);
    if(error) return dispatch(setNotification({ message: code !== 500 ? message! : "", status: "danger", show: true }))
    navigate(routes.login)
    dispatch(setNotification({ message: "Te hemos enviado un correo electrónico a la dirección que nos has facilitado.", description: fields.email, status: "success", show: true, type: "modal" }))
  }
  const handleCheckAuth = async () => {
    if(isChecking && localStorage.getItem("token"))  await dispatch(checkAuth())
  }
  const update = async (fields: IUserInfo, setSubmitting: (isSubmitting: boolean) => void) => {
    const { message, code } = await dispatch(updateProfile(fields)).unwrap();
    setSubmitting(false);
    if(code! >= 400) return  code! >=500 ? dispatch(setNotification({ message: "Ha ocurrido un error al actualizar los datos", status: "danger", show: true, type: "toast" })) : dispatch(setNotification({ message: message!, status: "danger", show: true, type: "toast" }))
    navigate(routes.profile)
    dispatch(setNotification({ message: "Datos de la farmacia actualizados exitosamente", status: "success", show: true }))
  }

  const updatePassword = async (fields: IUserPassword, setSubmitting: (isSubmitting: boolean) => void) => {
    const { message, code } = await dispatch(changePassword(fields)).unwrap();
    setSubmitting(false);
    if(code! >= 400) return  code! >=500 ? dispatch(setNotification({ message: "Ha ocurrido un error al actualizar la contraseña", status: "danger", show: true, type: "toast" })) : dispatch(setNotification({ message: message!, status: "danger", show: true, type: "toast" }))
    navigate(routes.profile)
    dispatch(setNotification({ message: "Contraseña actualizada exitosamente", status: "success", show: true }))
  }
  const handleLogout = () => {
    dispatch(logout());
    localStorage.clear()
    navigate(routes.login)
  }
  return {
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister,
    recovery: handleRecovery,
    checkAuth: handleCheckAuth,
    updatePassword,
    isAuthenticated,
    user,
    isChecking,
    updateUser: update
  }
}

import { FormikProps, FieldArray, ErrorMessage, Field } from "formik";
import { Row, Col, Form, Button, Accordion } from 'react-bootstrap';
import { IPatient } from '../../../interfaces';
import { InputField } from '../../components/shared/fields';
import { BasicLoader } from '../../components/shared/loaders/BasicLoader';

export const NewPatientFirstStep = ({ next, formProps }: { next?: () => void; formProps: FormikProps<IPatient> }) => {

  return (
    <>
      <Row className='mt-3'>
        <Col className='' lg={6}>
          <h3 className='font-ml fw-bold mb-3 color-sec'>Datos personales</h3>
          <InputField
            {...formProps}
            type='text'
            label={"Nombre del paciente*"}
            name='name'
          />
          <InputField
            {...formProps}
            type='text'
            label={"Apellidos del paciente*"}
            name='surname'
          />
          <InputField {...formProps} type='text' label={"NIF*"} name='nif' />
        </Col>
        <Col className='' lg={6}>
          <h3 className='font-ml fw-bold mb-3 color-sec'>Datos de contacto</h3>
          <Form.Group
            className='form-group select with-icon'
            controlId='contactPreference'
          >
            <Form.Label>Preferencia de contacto</Form.Label>
            <Form.Select
              name='contact_by'
              value={formProps.values.contact_by}
              aria-label='Default select example'
              onChange={formProps.handleChange}
            >
              <option value='Teléfono'>Teléfono</option>
              <option value='Whatsapp'>Whatsapp</option>
              <option value='Email'>Email</option>
            </Form.Select>
            <i className='ssp-bell'></i>
          </Form.Group>
          <InputField
            {...formProps}
            type='text'
            label={"Teléfono*"}
            name='telephone'
          />
          <InputField
            {...formProps}
            type='text'
            label={"Email"}
            name='email' />
        </Col>
      </Row>

      <Accordion className='my-4'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <h2 className='font-ml fw-bold color-dark'>Contactos asociados</h2>
          </Accordion.Header>
          <Accordion.Body>
            <p className='fw-light mb-4'>
              Introduce los datos de las personas de contacto afines a este
              paciente.
            </p>
            <FieldArray
              name='contacts'
              render={() => {
                const contacts = formProps.values.contacts;
                return (
                  <div>
                    {contacts?.map((contact: Record<any, any>, index) => {
                      return (
                        <Row className='mb-5' key={index}>
                          <Col className='' lg={3}>
                            <Form.Group
                              className='form-group select with-icon mb-0'
                              controlId='contact_role_01'
                            >
                              <Form.Select
                                name={`contacts.${index}.role`}
                                value={contacts[index]?.role}
                                aria-label='Rol'
                                onChange={formProps.handleChange}
                              >
                                <option value=''>Tipo de contacto</option>
                                <option value='FAMILY'>Familiar</option>
                                <option value='KEEPER'>Cuidador</option>
                              </Form.Select>
                              <i className='ssp-person'></i>
                            </Form.Group>
                            <p className='error-msg'>
                              <ErrorMessage name={`contacts.${index}.role`} />
                            </p>
                          </Col>
                          <Col className='' lg={5}>
                            <div className='form-group'>
                              <Field
                                {...formProps}
                                type='text'
                                placeholder='Nombre y apellidos'
                                name={`contacts.${index}.name`}
                                className='form-control'
                              />
                            </div>
                            <p className='error-msg'>
                              <ErrorMessage name={`contacts.${index}.name`} />
                            </p>
                          </Col>
                          <Col className='' lg={4}>
                            <div className='form-group'>
                              <Field
                                {...formProps}
                                type='text'
                                placeholder='Teléfono'
                                name={`contacts.${index}.phone`}
                                className='form-control'
                              />
                            </div>
                            <p className='error-msg'>
                              <ErrorMessage name={`contacts.${index}.phone`} />
                            </p>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                )
              }} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className='text-end pb-4'>
        {/* Quitar el disabled cuando corresponda */}
        <Button 
          className='prim' 
          // onClick={handleCreateNewPatient} 
          type='submit'
        >
          {formProps.isSubmitting ? <BasicLoader /> : "Confirmar"}
        </Button>
      </div>
    </>
  );
}

import { Button, Form } from "react-bootstrap"
import { IFilterEmployee } from "../../../interfaces/employee.interfaces"
import { useEmployee } from "../../customHooks/useEmployee"
import { useEffect, useState } from "react"

interface IProps {
  selectFilter?: boolean
}

export const FilterTable = ({ selectFilter = true }: IProps): JSX.Element => {
  const { filters, handleFilter, handleRemoveFilter } = useEmployee()
  const [val, setVal] = useState<any>('');
  const defaultPathologyInput = "Servicio"
  const filterByService = (name: string, value: string) => {
    if (value === defaultPathologyInput) return;
    return handleFilter(name as keyof IFilterEmployee, value, true)
  }

  useEffect(() => {
    setVal (undefined)
  }, [])


  return (
    <>
      <div className='searchbar-wrapper mt-4 pt-2'>
        <Form>
          <Form.Group
            className='form-group searchbar mb-0'
            controlId='searchbar'
          >
            <i className='ssp-search color-prim font-xml'></i>
            <Form.Control
              type='text'
             // value={filters.search}
              value={val}
              name='search'
              onChange={({ target }) =>
                handleFilter(target.name as keyof IFilterEmployee, target.value)
              }
              placeholder='Buscar nombre del empleado'
            />
          </Form.Group>
          {/* selectFilter && (
            <Form.Group className='form-group select mb-0' controlId='service'>
              <Form.Select
                aria-label='service'
                name='pathologies'
                value={defaultPathologyInput}
                onChange={({ target }) =>
                  filterByService(target.name, target.value)
                }
              >
                <option>{defaultPathologyInput}</option>
                {pathologies.length &&
                  pathologies.map((pathology, key) => (
                    <option key={key} value={pathology}>
                      {pathology}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          )*/}
        </Form>
      </div>
      {/* <div className='d-flex align-items-center justify-content-start pt-2'>
        {!!filters.pathologies?.length &&
          filters.pathologies.map((pathology, key) => (
            <TagComponent
              key={pathology + key}
              name={pathology}
              remove={() => handleRemoveFilter("pathologies", pathology)}
            />
          ))}
      </div>*/}

      {/* Poner el numero de resultados */}
      {/*<p className="color-dark30 font-ml mt-3 mb-4"># resultados en total</p>*/}
    </>
  );
}

export const TagComponent = ({ name, remove }: { name: string, remove: () => any }) => {
  return <div className="pill active">{name} <Button onClick={remove} className="transparent fw-normal color-white p-0 font-ml ms-1"><i className="ssp-close"></i></Button></div>
}

import { Button, Modal } from "react-bootstrap";
import { INotification } from "../../../interfaces/notification.interfaces";

export const ArchivePatientModal = ({ archiveShow, handleArchiveClose }: { archiveShow: boolean, handleArchiveClose: () => void }) => {
  return <Modal show={archiveShow} onHide={handleArchiveClose}>
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
      <p className="text-center fw-bold font-ml">¿Quieres archivar esta ficha de paciente?</p>
      <p className="text-left fw-light font-m mt-3">Podrás recuperarla cuando lo desees desde tu sección “Fichas archivadas” en tu listado de pacientes.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button className="prim outline" onClick={handleArchiveClose}>
        Cancelar
      </Button>
      <Button className="prim" type="submit">
        Archivar ficha
      </Button>
    </Modal.Footer>
  </Modal>;
}

interface IArchivePatientModal {
  deleteShow: boolean;
  handleDeleteClose: () => void;
  deletePatient: (id: string) => Promise<{ payload: INotification; type: "notifications/setNotification"; } | undefined>;
  id: string;
}

export const DeletePatientModal: React.FC<IArchivePatientModal> = ({ deleteShow, handleDeleteClose, deletePatient, id }) => {
  return (
    <Modal show={deleteShow} onHide={handleDeleteClose}>
      <Modal.Header className='justify-content-between' closeButton>
        <h5 className='fw-bold'>¿Quieres eliminar esta ficha?</h5>
      </Modal.Header>
      <Modal.Body>
        <p className='text-left fw-light color-dark50 font-s my-3'>
          Al eliminarla, borrarás el historial y toda la información de este paciente y no podrás recuperarla después.
        </p>
      </Modal.Body>
      <Modal.Footer  className='justify-content-end'>
        <Button className="prim outline" onClick={handleDeleteClose}>
          Cancelar
        </Button>
        <Button className="red" onClick={() => deletePatient(id)}>
          Eliminar ficha
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serviceExtraReducers } from "./extraReducers";
import { IService, IServiceState } from "../../../interfaces";
import { createService, deleteServiceService, serviceListService, updateSandozServiceService, updateServiceService } from "../../../api/services/service.service";
import moment from "moment";
export const initialState: IServiceState = { sandoz:[], list: [], status: 'fetching' };

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    handleAddNewService: (state) => {
      state.list = [ ...state.list, {name: "", machine: "", start_date: moment().format('YYYY-MM-DD'), edit: true}]
      return state;
    },
    handleEdit: (state, action: PayloadAction<string>) => {
      if (!action.payload) {
        state.list = state.list.filter(service => service.id! !== action.payload);
        return state
      }
      state.list = state.list.map(service => {
        if(service.id! === action.payload) service.edit = !service.edit
        return service
      })
      return state;
    } 
  },
  extraReducers: (builder) => serviceExtraReducers(builder),
});

export const serviceList = createAsyncThunk(
  'services/list',
  async () => serviceListService()
);
/*
export const serviceSandozList = createAsyncThunk(
  'services/Sandozlist',
  async () =>  serviceSandozListService()
);
*/

export const registerService = createAsyncThunk(
  'services/register',
  async (service: IService) => createService(service)
);

export const updateService = createAsyncThunk(
  'services/update',
  async (service: IService) => updateServiceService(service)
);

export const updateSandozService = createAsyncThunk(
  'services/updateSandozService',
  async (service: IService) => updateSandozServiceService(service)
);

export const deleteService = createAsyncThunk(
  'services/delete',
  async (id: IService['id']) => deleteServiceService(id)
);
//deleteServiceService
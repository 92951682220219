

import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import moment from "moment";
import { capitalize } from "../../../../helpers";
import { measurementById } from "../../../../module/reducers/measurement/slice";
import {
  measurementByIdPatient,
  measurementByIdService,
  measurementByPatientService
} from "../../../../api/services/measurement.service";
import { usePatient } from "../../../customHooks/usePatient";
import { IPatient } from "../../../../interfaces";


interface IProps {
  SeeMeditionShow: boolean,
  handleSeeMeditionClose: () => void,
  patientID?: string;
  measuresList?: any[];
  patient?: IPatient;
}

export const SeeMeditionModal: React.FC<IProps> = ({ SeeMeditionShow, handleSeeMeditionClose, patientID, measuresList, patient }) => {
  const edit = patientID ? true : false;

  const [listGrouped, setListGrouped] = useState<any[] | undefined>([])
  const [addMeasurement, setAddMeasurement] = useState<any>(undefined)
  const [patientNote, setPatientNote] = useState<string>('')
  const [recommendationNote, setRecommendationNote] = useState<string>('')
  useEffect(() => {

    if (measuresList && measuresList[0]) {
      measurementByIdPatient(measuresList[0].patient_id, measuresList[0].measure_id).then((value) => {
        setPatientNote(value.content.note)
        setRecommendationNote(value.content.recommendation)
      })
    }

    setListGrouped(measuresList ?.filter((measure) => measure.service_id !== 'mediciones_adicionales'))
    if (measuresList ?.filter((measure) => measure.service_id === 'mediciones_adicionales') && measuresList ?.filter((measure) => measure.service_id === 'mediciones_adicionales').length > 0) {
      setAddMeasurement(measuresList ?.filter((measure) => measure.service_id === 'mediciones_adicionales')[0])
    } else {
      setAddMeasurement(undefined)
    }
  }, [measuresList])
  const resultLevel = [
    {
      title: "NIVEL 1: RESULTADOS CORRECTOS",
      description:
        "El resultado de todas las mediciones de este servicio están dentro de la normalidad.",
      color: "green",
    },
    {
      title: "NIVEL 2: NECESITA CONSEJO FARMACÉUTICO",
      description:
        "El resultado de al menos una de las mediciones requiere de seguimiento farmacéutico.",
      color: "orange",
    },
    {
      title: "NIVEL 3: DERIVAR AL MÉDICO",
      description:
        "El resultado de al menos una de las mediciones requiere atención médica.",
      color: "red",
    },
  ];
  const handlerMaxMin = (item: any, value: string) => {
    let { min, max, reverse } = item.limits;
    let measurementLevelColor = "";
    if (value !== "") {
      if (min && max) {
        if (reverse) {
          const temp = min;
          min = max;
          max = temp;
        }
        const valueNumber = parseInt(value);
        if (valueNumber < min) {
          reverse
            ? (measurementLevelColor = "red")
            : (measurementLevelColor = "green");
        }
        else if (valueNumber >= max) {
          reverse
            ? (measurementLevelColor = "green")
            : (measurementLevelColor = "red");
        } else {
          measurementLevelColor = "orange";
        }
      }
    }

    if(value !== '' && item.questionID.includes('piel')){
      if(item.questionID.includes('_01')){
        value == 'Normal' ? measurementLevelColor = 'green' : value == 'Media' ? measurementLevelColor = 'orange' : measurementLevelColor = 'red'
      }
      if(item.questionID.includes('_02')){
        parseInt(value) < 30 ? measurementLevelColor = 'red' : parseInt(value) < 55 && parseInt(value) > 30 ? measurementLevelColor = 'orange' : measurementLevelColor = 'green'
      }
      if(item.questionID.includes('_03')){
        parseInt(value) < 5 ? measurementLevelColor = 'red' : parseInt(value) < 25 && parseInt(value) > 5 ? measurementLevelColor = 'orange' : measurementLevelColor = 'green'
      }
      if(item.questionID.includes('_04')){
        parseInt(value) < 70 && parseInt(value) > 40 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_05')){
        parseInt(value) < 40 && parseInt(value) > 15 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_06')){
        parseInt(value) < 65 && parseInt(value) > 30 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_07')){
        parseInt(value) < 45 && parseInt(value) > 20 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_08')){
        parseInt(value) < 25 && parseInt(value) > 5 ? measurementLevelColor = 'green' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_10')){
        value == 'Normal' ? measurementLevelColor = 'green' : value == 'Muy seca' ? measurementLevelColor = 'red' : measurementLevelColor = 'orange'
      }
      if(item.questionID.includes('_11')){
        value == 'Normal' ? measurementLevelColor = 'green' : value == 'Grasa' ? measurementLevelColor = 'red' : measurementLevelColor = 'orange'
      }
    }

    return measurementLevelColor;
  }
  const getFPSValue = (item: any) => {
    let value;
    if(parseInt(item.value) < 50){
      parseInt(item.value) >= 1 && parseInt(item.value) <= 10 ? value = 50 : parseInt(item.value) >= 11 && parseInt(item.value) <= 23 ? value = 40 : parseInt(item.value) >= 24 && parseInt(item.value) <= 35 ? value = 35 : value = 30
    }else{
      parseInt(item.value) >= 50 && parseInt(item.value) <= 62 ? value = 25 : parseInt(item.value) >= 63 && parseInt(item.value) <= 75 ? value = 20 : parseInt(item.value) >= 76 && parseInt(item.value) <= 94 ? value = 15 : value = 10
    }
    return value
  }

  return (
    <Modal className="modal-meditions" size='xl' show={SeeMeditionShow} onHide={handleSeeMeditionClose}>
      <Modal.Header className='justify-content-between' closeButton>
        <h5 className='fw-bold'>Resultado de las mediciones</h5>
      </Modal.Header>
      <Modal.Body>

        {/*ARRAY*/}

        {listGrouped && listGrouped.length > 0 ?
          listGrouped.map((items: any, index: number) => {
            let resultIndex = -1
            let resultsArray: string[] = []
            return (
              <>
                <div className='service-row'>
                  <div>
                    <p className='font-s color-dark50 mb-1'>
                      {moment(items.updatedAt).format('DD/MM/YYYY HH:mm')}
                    </p>
                    <h2 className="font-m fw-semibold">
                      {items.survey && items.survey[0] && items.survey[0].groupTitle}
                    </h2>
                    {items.survey ?.map((item: any, index: number) => {
                      const { title, type, unit, value } = item;
                      const levelColor = handlerMaxMin(item, value);
                      resultsArray.push(levelColor)
                      resultsArray.includes('red') ? resultIndex = 2 : resultsArray.includes('orange') ?  resultIndex = 1 : resultIndex = 0
                      return (
                        <div
                          className={`medition-input ${levelColor}`}
                          key={index}
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>{title}</Form.Label>
                            {type === "input" && (
                              <>
                                <Form.Control
                                  type='text'
                                  placeholder='Dato'
                                  disabled
                                  value={value}
                                />
                                <span className='unit'>{unit}</span>
                              </>
                            )}
                            {type === "select" && (
                                <>
                                  <Form.Control
                                      type='select'
                                      disabled
                                      value={value}
                                  />
                                </>
                            )}
                          </Form.Group>
                        </div>
                      );
                    })
                      }
                    {items && items.survey && items.survey[0] && items.survey[0].questionID.includes('piel') && (
                        <div className='mt-4'>
                          <h4 className='text-uppercase font-s fw-normal'>
                            PIGMENTACIÓN: <span>{getFPSValue(items.survey[8])} FPS</span>
                          </h4>
                        </div>
                    )}
                    {resultIndex >= 0 && (
                      <div
                        className={`results ${resultLevel[resultIndex].color}`}
                      >
                        <h4 className='text-uppercase font-s fw-normal'>
                          {resultLevel[resultIndex].title}
                        </h4>
                        <p className='font-s'>
                          {resultLevel[resultIndex].description}
                        </p>
                      </div>
                    )}
                  </div>

                  <div>
                    {items &&
                      <div>
                        <span className={`service-chip border-left-${items.service_id}`}>
                          {items && capitalize(items.service_id)}
                        </span>
                        <div className='medition-img mt-4'>
                          <img
                            src={`/assets/img/measurement-${resultIndex}.png`}
                            alt=''
                          />
                        </div>
                      </div>
                    }
                  </div>

                </div>
              </>
            )
          })
          :
          <div>
            <span className='appointment empty message d-flex align-items-center justify-content-center w-100 mb-0'>No hay datos disponibles.</span>
          </div>
        }
        {/* ADICIONALES */}

        {
          addMeasurement &&
          <div className="service-row">
            <div>
              <p className='font-s color-dark50 mb-1'>
                {moment(addMeasurement.updatedAt).format('DD/MM/YYYY HH:mm')}
              </p>
              <h2 className="font-m fw-semibold">Mediciones adicionales</h2>

              <div className="medition-input">
                <Form.Group className='form-group'>
                  <Form.Label>Tensión</Form.Label>
                  <Form.Control readOnly={true}
                    type='text'
                    placeholder='Dato'
                    disabled
                    value={addMeasurement.survey.servicio_adicional_measurement_01.value}
                  />
                  <span className="unit">sistólica (mmHG)</span>
                </Form.Group>
                <Form.Group className='form-group'>
                  <Form.Control readOnly={true}
                    type='text'
                    placeholder='Dato'
                    disabled
                    value={addMeasurement.survey.servicio_adicional_measurement_02.value}
                  />
                  <span className="unit">diastólica (mmHG)</span>
                </Form.Group>
              </div>
              <div className="medition-input">
                <Form.Group className='form-group'>
                  <Form.Label>Altura</Form.Label>
                  <Form.Control readOnly={true}
                    type='text'
                    placeholder='Dato'
                    disabled
                    value={addMeasurement.survey.servicio_adicional_measurement_03.value}
                  />
                  <span className="unit">cm</span>
                </Form.Group>
              </div>
              <div className="medition-input">
                <Form.Group className='form-group'>
                  <Form.Label>Peso</Form.Label>
                  <Form.Control readOnly={true}
                    type='text'
                    placeholder='Dato'
                    disabled
                    value={addMeasurement.survey.servicio_adicional_measurement_04.value}
                  />
                  <span className="unit">kg.</span>
                </Form.Group>
              </div>


            </div>
            <div>
              <div>
                <span className='service-chip border-left-dark-blue'>Mediciones adicionales</span>
              </div>
            </div>
          </div>
        }

        <Row className="mt-5 pt-4">
          <Col lg={6}>
            <h4 className="font-ml fw-bold mb-4">
              Nota para el paciente
      </h4>
            <Form.Group className="form-group" >
              <Form.Control readOnly={true} as="textarea" value={patientNote} placeholder="Nota no disponible" style={{ minHeight: '150px' }} />


            </Form.Group>
          </Col>
          <Col lg={6}>
            <h4 className="font-ml fw-bold mb-4">
              Nota privada
      </h4>
            <Form.Group className="form-group" >
              <Form.Control readOnly={true} as="textarea" value={recommendationNote} placeholder="Nota no disponible" style={{ minHeight: '150px' }} />
            </Form.Group>
          </Col>
        </Row>

      </Modal.Body>
    </Modal>
  );
}

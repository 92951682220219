import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IPatientState } from "../../../interfaces/patient.interfaces";
import { findPatientById, patientList } from "./slice";

export const patientExtraReducers = (builder: ActionReducerMapBuilder<IPatientState>) => {
  builder
  .addCase(findPatientById.pending, (state) => {
    state.status = 'fetching'
  })
  .addCase(findPatientById.fulfilled, (state, action) => {
    state.status = 'fetched'
    state.current = action.payload.content
  })
  .addCase(patientList.pending, (state) => {
    state.status = 'fetching'
  })
  .addCase(patientList.fulfilled, (state, action) => {
    const { total, content } = action.payload
    state.list = action.payload.content
    state.total = total ? total : content.length ? content.length : 0
    state.status = 'fetched'
  })
}
import { Row, Col } from 'react-bootstrap';
import { ReminderCard } from '../../../components/shared/ReminderCard';
import { useReminder } from '../../../customHooks/useReminder';

export const NewReminderStep6 = () => {
  const { reminder } = useReminder({})
  return (
    <Row >
      <Col lg={5} className="pe-lg-5">
        <h3 className="font-ml fw-bold mb-4 mt-4 pt-2">Así se verá tu recordatorio:</h3> 
         

         <ReminderCard {...reminder} />
      </Col>
      <Col lg={7} className="ps-lg-5 mt-lg-0 mt-4">
       
      </Col>
    </Row>


  )
}

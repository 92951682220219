import { del, get, post, put } from "../../helpers";
import { IResponseDetail, IResponseList } from "../../interfaces";
import { IEmployee } from "../../interfaces/employee.interfaces";

export const employeeByIdService = async (id: string): Promise<IResponseDetail<IEmployee>> =>
  get(`/api/pharmacy/employee/${id}`);

export const employeeListService = async (params?: string): Promise<IResponseList<IEmployee>> =>
  get(`/api/pharmacy/staff${params ? ('?'+params) : ''}`);

export const createEmployeeService = async (employee: Partial<IEmployee>): Promise<IResponseList<Partial<IEmployee>>> =>
  post(`/api/pharmacy/employee`, employee);

export const updateEmployeeService = async (employee: Partial<IEmployee>): Promise<IResponseList<Partial<IEmployee>>> =>
  put(`/api/pharmacy/employee/${employee.id}`, employee);

export const deleteEmployeeService = async (id: IEmployee['id']): Promise<IResponseList<Partial<IEmployee>>> =>
  del(`/api/pharmacy/employee/${id}`);
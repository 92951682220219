import Modal from "react-bootstrap/Modal";
import { Button, Col, Row, Form } from "react-bootstrap";
import { Key, useEffect, useState } from "react";
import { usePatient } from "../../../customHooks/usePatient";
import { IHealthSurveyAnswer, IPatient } from "../../../../interfaces";
import { useMeasurement } from "../../../customHooks/useMeasurement";
import HealthSurvey from "../HealthSurvey";
import { INotification } from "../../../../interfaces/notification.interfaces";
import { useAppDispatch } from "../../../../module/hooks";
import { getHealthServiceSurvey } from "../../../../module/reducers/measurement/slice";

interface IProps {
  patientID: string;
  edit?: boolean;
  measurementModalShow: boolean;
  handleMeasurementModalClose: () => void;
  serviceID: string;
  onValueSurveyChange: any;
}

export const MeasurementModal: React.FC<IProps> = ({
  measurementModalShow,
  handleMeasurementModalClose,
  serviceID,
  patientID,
  edit,
    onValueSurveyChange
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { patient, updatePatientHealthSurvey } = usePatient(patientID);
  const { handleMeasurement, measurement, updateHealthServiceSurvey} =
    useMeasurement();
  const [titleAnswers, setTitleAnswers] = useState<string>("");
  const [serviceSurvey, setServiceSurvey] = useState<any[]>([]);
  const [answers, setAnswers] = useState<any[]>([]);
  const [displayHealthSurvey, setDisplayHealthSurvey] =
    useState<boolean>(false);

/**
 * Handles the selection of an answer option in a survey and updates the answers state. */
  const handlerSelectedAnswer = (item: any, label: string) => {
    const surveyData = {
      questionID: item.questionID,
      value: label,
      rating: '',
    };
    const exist = answers.find(
      (answer: { questionID: string }) => answer.questionID === item.questionID
    );
    if (exist && Object.keys(exist).length > 0) {
      const removeAnswer = answers.filter(
        (answer: { questionID: string }) =>
          answer.questionID !== item.questionID
      );
      setAnswers([...removeAnswer, surveyData]);
    } else {
      setAnswers([...answers, surveyData]);
    }
  };
  useEffect(() => {
    handleMeasurement({
      health_service_survey_answers: answers,
    });
  }, [answers]);

  /* If the `patientID` and `serviceID` properties are updated, it calls the `getHealthServiceSurvey` action to get the health service survey of the patient*/
  useEffect(() => {
    if (patientID && serviceID) {
      dispatch(
        getHealthServiceSurvey({
          patientID,
          serviceID,
        })
      );
    }
  }, [dispatch, patientID, serviceID]);

  /* If the `health_service_survey` property of the `measurement` object is updated, it sets the `serviceSurvey` state to the new value of `health_service_survey`*/
  useEffect(() => {
    if (measurement.health_service_survey) {
      setServiceSurvey(measurement.health_service_survey);
    }
  }, [measurement]);

  const handlerSubmit = async () => {
    if (patient) {
      const notificationMessage: INotification = {
        message: "Paciente actualizado correctamente",
        status: "success",
        show: true,
        type: "toast",
        icon: "ssp-eye-off font-l me-2",
      };

      const healthPatientSurveyAnswer =
        measurement.health_patient_survey as IHealthSurveyAnswer[];

      const healthServicesSurveyAnswer =
        measurement.health_service_survey_answers as IHealthSurveyAnswer[];

      patient.id && healthPatientSurveyAnswer.length > 0 &&
        updatePatientHealthSurvey(
          patient.id,
          healthPatientSurveyAnswer,
          notificationMessage
        );

      patient.id &&
      healthServicesSurveyAnswer.length > 0 &&
      onValueSurveyChange(
          patient.id,
          serviceID,
          healthServicesSurveyAnswer
      );

      handleMeasurement({
        measurement_step: 2,
      });
    }
    handleMeasurementModalClose();
  };

  /* Setting the title of the modal based on the `health_service_name`
  property of the `measurement` object*/
  useEffect(() => {
    measurement.health_service_name && setTitleAnswers(measurement.health_service_name);
  }, [measurement]);

  return (
    <>
      <Modal
        size='xl'
        show={measurementModalShow}
        onHide={handleMeasurementModalClose}
      >
        <Modal.Header className='justify-content-between' closeButton>
          <h5 className='fw-bold'>Añadir preguntas del Servicio de Salud</h5>
        </Modal.Header>
        <Modal.Body>
          <div className='bg-prim5 p-3 radius-8 mt-3'>
            <h4 className='fw-bold font-m mb-4'>
              {`Preguntas para el Servicio de ${titleAnswers}`}
            </h4>
            <Row className='align-items-stretch'>
              {serviceSurvey.map((question, key) => {
                const { questionID, type, values, title } = question;
                return (
                  <>
                    <Col xl={6} className='h-auto mb-2' key={key}>
                      <div
                        key={key}
                        className='h-100 radius-8 p-3 mb-2 bg-white d-flex align-items-center justify-content-between '
                      >
                        <p className='fw-normal'>{title[0] + title[1].toUpperCase() + title.slice(2)}</p>
                        <div className='d-inline-flex' key={key}>
                          {type === "radio" &&
                            values?.map(
                              (
                                values: { value: any; label: any },
                                key: Key | null | undefined
                              ) => {
                                const { value, label } = values;
                                return (
                                  <div key={key}>
                                    <Form.Check
                                      className='ms-4 me-0'
                                      inline
                                      reverse
                                      label={`${label}`}
                                      name={questionID}
                                      type='radio'
                                      id={`${questionID}-radio-${label.toLowerCase()}`}
                                      onChange={() => {
                                        handlerSelectedAnswer(question, value);
                                      }}
                                      key={key}
                                    />
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
          {!displayHealthSurvey ? (
            <>
              <h4 className='font-l fw-bold mt-5'>Encuesta de salud</h4>
              <Button
                className='alt-btn mt-4'
                onClick={() => setDisplayHealthSurvey(true)}
              >
                <span>Añadir encuesta de salud</span>
                <i className='ssp-add icon-circle'></i>
              </Button>
            </>
          ) : (
            <HealthSurvey patientID={patientID} />
          )}
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button
            className='prim outline'
            onClick={handleMeasurementModalClose}
          >
            Anterior
          </Button>
          <Button
            className='prim ms-2'
            onClick={handlerSubmit}
            disabled={answers.length !== serviceSurvey.length}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

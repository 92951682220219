
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState, useRef } from 'react';
import { useAuth } from '../../../customHooks/useAuth';
import { Row, Col } from 'react-bootstrap';
import { NewActivityModal } from './NewActivityModal';
import { Link } from 'react-router-dom';
import { useAppointment } from '../../../customHooks/useAppointment';
import { useReactToPrint } from 'react-to-print';
import { PrintModal } from './PrintModal';


interface IProps {
  AppointmentDetailShow: boolean;
  handleAppointmentDetailClose: () => void;
}
export const AppointmentDetailModal: React.FC<IProps> = ({
  AppointmentDetailShow,
  handleAppointmentDetailClose,
}): JSX.Element => {
  const [PrintShow, PrintSetShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayPrintOption, setDisplayPrintOption] = useState<boolean>(true);

  const handlePrintShow = () => PrintSetShow(true);
  const handlePrintClose = () => PrintSetShow(false);

  const { createAppointment, appointment } = useAppointment(
    {}
  );
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      createAppointment(appointment, setLoading, false);
    },
    onAfterPrint: () => setDisplayPrintOption(false),
  });

  return (
    <>
      <Modal
        size="lg"
        show={AppointmentDetailShow}
        onHide={handleAppointmentDetailClose}
      >
        <Modal.Header className='justify-content-between' closeButton>
          <h5 className='fw-bold'>Detalle Cita</h5>
        </Modal.Header>
        <Modal.Body className="pt-4">

          {/* Hay que cargar el componente SimpleAppointmentCard */}

          <div className={`appointment granate`}>
            <div className='d-flex align-items-center justify-content-between w-100'>
              <p className='font-s fw-semibold'>test h</p>

              <span className='service-chip border-left-granate'>
                Diabetes
              </span>
            </div>

            <div className='mt-2'>
              <Link
                className='font-m color-prim fw-bold mb-1 text-decoration-underline'
                to="test"
              >
                test
              </Link>
              <p className='font-s color-dark70 truncate-2rows'></p>
            </div>

            <div className='d-flex align-items-center justify-content-between mt-2 w-100'>
              <div className='d-inline-flex align-items-center'>
                <div className='d-inline-flex color-dark50 me-3'>
                  <i className='ssp-medkit font-m me-1'></i>
                  <span className='font-xs text-truncate'>
                    test
                  </span>
                </div>
                <div className='d-inline-flex color-dark50 me-3'>
                  <i className='ssp-person font-m me-1'></i>
                  <span className='font-xs text-truncate'>test</span>
                </div>
              </div>{/* 


              <Link
                className='font-xs fw-bold color-prim d-inline-flex'
                to="/"
              >
                Iniciar medición <i className='ssp-arrow-right ms-1 font-m'></i>
              </Link> */}

            </div>
            <div className='d-flex align-items-center justify-content-start mt-3 w-100'>
              <div className="form-group readonly mb-0">
                <label className="fw-normal font-xs mb-0">Quién tomó la cita</label>
                <div className='d-inline-flex color-dark50 me-3'>
                  <i className='ssp-person font-m me-1'></i>
                  <span className='font-xs text-truncate'>nombre</span>
                </div>
              </div>
              <div className="form-group readonly mb-0 ms-4">
                <label className="fw-normal font-xs mb-0">Fecha en la que se tomó la cita</label>
                <div className='d-inline-flex color-dark50 me-3'>

                  <span className='font-xs text-truncate'>13/12/2023</span>
                </div>
              </div>
            </div>
          </div>

          <h3 className='font-ml fw-bold mb-3 color-dark mt-5'>
            Imprimir cita
              </h3>

          <div className='bg-dark5 radius-12 p-3 d-flex align-items-center mb-3'>
            <div className='icon-circle big bg-prim'>
              <i className='ssp-print_outline font-xl color-white'></i>
            </div>
            <div className='ps-3 w-100'>
              <p className='fw-bold font-m mb-2'>Nueva cita</p>
              <p className='fw-light font-s'>Para entregar al paciente</p>
            </div>

            <Button
              className='btn prim outline px-4'
              onClick={handlePrintShow}
            >
              <span className='text-nowrap'>Imprimir cita</span>
              <i className='ssp-print_outline ms-2 font-l'></i>
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button className='red outline ms-2'>Eliminar cita</Button>
          <Link className='btn prim outline ms-2' to="/">
            Editar
          </Link>
          <Button className='prim ms-2 align-items-center d-inline-flex'>Iniciar medición <i className="ssp-chevron-right ms-1"></i></Button>
        </Modal.Footer>
      </Modal>

      <PrintModal
        PrintShow={PrintShow}
        handlePrintClose={handlePrintClose}
        handlePrint={handlePrint}
      />
    </>
  );
};
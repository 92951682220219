import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IEmployeeState } from "../../../interfaces/employee.interfaces";
import { employeeList, findEmployeeById } from "./slice";

export const employeeExtraReducers = (builder: ActionReducerMapBuilder<IEmployeeState>) => {
  builder
  .addCase(findEmployeeById.pending, (state) => {
    state.status = 'fetching'
  })
  .addCase(findEmployeeById.fulfilled, (state, action) => {
    state.status = 'fetched'
    state.current = action.payload.content
  })
  .addCase(employeeList.pending, (state) => {
    state.status = 'fetching'
  })
  .addCase(employeeList.fulfilled, (state, action) => {
    const { total, content } = action.payload
    state.list = action.payload.content
    state.total = total ? total : content.length
    state.status = 'fetched'
  })
}

// 
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Datepicker } from '../../../components/shared/Datepicker';
import { useReminder } from '../../../customHooks/useReminder';

export const NewReminderStep4 = () => {
  const { handleReminder, reminder } = useReminder({})
  const periodicity_type = ['Diario', 'Cada tres días', 'Semanal', 'Mensual']
  const handleSelectDate = (date: string) => {
    handleReminder({ periodicity_start_date: date })
  }
  return (
    <Row >
      <Col lg={6} className="pe-lg-5">
        <h3 className="font-ml fw-bold mb-4 mt-4 pt-2">Selecciona el día del recordatorio</h3>

        <Datepicker handleSelectDate={handleSelectDate} selected={reminder.periodicity_start_date}/>
      </Col>
      <Col lg={6} className="ps-lg-5 mt-lg-0 mt-4">
        <h3 className="font-ml fw-bold mb-4 mt-4 pt-2">Elige la hora del recordatorio:</h3>
        <Form.Group className="form-group select" controlId="reminder-hour">
          <Form.Select aria-label="reminder-motive" value={reminder.periodicity_hour}
            onChange={({ target }) => handleReminder({ periodicity_hour: target.value })}>
            <option value="">Elige la hora</option>
            <option value="10:00">10:00</option>
            <option value="10:30">10:30</option>
            <option value="11:00">11:00</option>
          </Form.Select>
        </Form.Group>

        <div className="bg-prim5 radius-20 p-4 d-flex align-items-baseline mb-2rem">

          <Form.Check className="ms-3"
            type="checkbox"
            id="reminder-complete"
            name="reminder-complete"
            checked={reminder.periodicity_routinely}
            onChange={({ target }) => handleReminder({ periodicity_routinely: target.checked })}
          />
          <div className="ps-3 w-100">
            <label className="fw-bold mb-2">Recordatorio rutinario</label>
            <p className="font-s fw-light mb-2">Selecciona si este es un recordatorio que se vaya a repetir a lo largo del tiempo.
</p>
            <p className="font-s fw-light">
              Puedes especificar también cada cuanto quieres que te lo recordemos:</p>

            <div className="d-flex align-items-center justify-content-start mb-4 mt-3">
              {
                periodicity_type.map((periodicity: string, key: number) => <Button key={key} onClick={() => handleReminder({ periodicity_type: periodicity })} className={`pill prim ${periodicity === reminder.periodicity_type && 'active'}`}>{periodicity}</Button>)
              }
            </div>

            {/* <Form.Group className="form-group mb-2" controlId="periodicity">
              <Form.Control type="text" placeholder="Elegir otra periodicidad" />
            </Form.Group> */}
          </div>
        </div>
      </Col>
    </Row>


  )
}

import moment from "moment";
import 'moment/locale/es'

export const dateToString = (date: Date | string, clientFormat: boolean = true) => clientFormat ? moment(new Date(date)).format('DD/MM/YYYY') : moment(new Date(date)).format('YYYY-MM-DD')

export const getDay = (date: Date | string) => moment(date).calendar(null, {
  sameDay: '[Hoy]',
  nextDay: '[Mañana]',
  nextWeek: 'dddd DD MMMM',
  lastDay: '[Ayer]',
  lastWeek: 'dddd DD MMMM',
  sameElse: 'dddd DD MMMM'
});

export const getDaysBetweenDates = (start_date: Date | string, end_date: Date | string) => {
moment.locale('es');
  if (start_date === end_date) return getDay(start_date);
}
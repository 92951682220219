export const routes = {
  home: '/tablero',
  login: '/ingreso',
  register: '/registro',
  patients: '/pacientes',
  patientArchive: (id?: string) => `/pacientes/archivo/${id ? id : ':id'}`,
  patientNewNote: (id?: string) => `/paciente/${id ? id : ':patient'}/archivos/nota/crear`,
  patientEditNote: (patient?: string, noteId?: string) => `/paciente/${patient ? patient : ':patient'}/archivos/nota/${noteId ? noteId : ':noteId'}/editar`,
  patientAllNotes: (id?: string) => `/paciente/${id ? id : ':id'}/archivo/notas`,
  newPatient: '/pacientes/crear',
  editPatient: '/pacientes/editar',
  services: '/servicios',
  newService: '/servicios/crear',
  reminders: '/recordatorios',
  newReminder: '/recordatorios/crear',
  editReminder: (patient?: string, reminderId?: string) => `/paciente/${patient ? patient : ':patient'}/recordatorio/${reminderId ? reminderId : ':reminderId'}/editar`,
  newAppointment: '/citas/crear',
  editAppointment: (patient?: string, appointmentId?: string) => `/paciente/${patient ? patient : ':patient'}/cita/${appointmentId ? appointmentId : ':appointmentId'}/editar`,
  newMedition: '/medicion/crear',
  newMeditionArchive: (id?: string) => `'/medicion/crear/'${id ? id : ':id'}`,
  editMedition: (patient?: string, measurementId?: string) => `/paciente/${patient ? patient : ':patient'}/medicion/${measurementId ? measurementId : ':measurementId'}/editar`,
  forgotPassword: '/recuperar/clave',
  recoverPassword: '/restablecer/clave',
  profile: '/perfil',
  newTeammate: '/perfil/crear-trabajador',
  editTeammate: '/perfil/editar-trabajador',
  editPharmacyData: '/perfil/editar'
}

export const motives = ['Llamada','Cita','Medicación','Servicio']
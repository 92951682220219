
import { Formik, Form } from 'formik';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { employeeValidationSchema, initialEmploueeValues } from '../../../helpers/schemas/employeeSchema';
import { routes } from '../../../router/constants';
import { InputField } from '../../components/shared/fields';
import { BasicLoader } from '../../components/shared/loaders';
import { useEmployee } from '../../customHooks/useEmployee';

export const EditTeammatePage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { updateEmployee, currentEmployee } = useEmployee(id)
  return (
    <>
      <Container className="pb-5">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="fw-bold font-xxml mb-4">Añadir nuevo trabajador</h1>
        </div>
        <div>
          <Link className="btn transparent" to={routes.profile}>
            <i className="ssp-chevron-left me-1"> </i>
            <span className="font-s">Volver</span>
          </Link>
        </div>

        <div className="mt-5">
          <h3 className="font-ml fw-bold mb-2">Datos del trabajador</h3>
          <p className="fw-light mb-4">Introduce los datos personales del trabajador</p>
          {
            currentEmployee && (
              <Formik
                initialValues={{ ...initialEmploueeValues, ...currentEmployee }}
                validationSchema={employeeValidationSchema}
                onSubmit={(fields, actions) => updateEmployee(fields, actions.setSubmitting)}
              >
                {
                  (props) => {
                    return (
                      <Form>
                        <Row>
                          <Col className="pe-lg-5" lg={6}>
                            <InputField {...props} type="text" label={t('employee.name')} name="name" />
                            <InputField {...props} type="text" label={t('employee.surname')} name="surname" />
                            <InputField {...props} type="text" label={t('employee.nif')} name="nif" />
                          </Col>
                          <Col className="ps-lg-5" lg={6}>
                            <InputField {...props} type="text" label={t('employee.position')} name="position" />
                            <InputField {...props} type="text" label={t('employee.telephone')} name="telephone" />
                            <InputField {...props} type="text" label={t('employee.email')} name="email" />
                          </Col>
                        </Row>
                        <div className="text-end mt-4">
                          <Button className="btn prim" disabled={props.isSubmitting} type="submit">
                            {
                              props.isSubmitting ? <BasicLoader /> : t('general.save')
                            }
                          </Button>
                        </div>
                      </Form>
                    )
                  }
                }
              </Formik>
            )
          }
        </div>
      </Container>
    </>
  )
}

import { Button } from 'react-bootstrap';
import { IFilter, IFilterPatient } from '../../../interfaces';

export const TablePaginationModal = ({
  filters,
  total,
  handleFilterModal,
  perPage,
}: {
  handleFilterModal: (
    filter: keyof IFilterPatient,
    value: string | number,
    isArray?: boolean
  ) => Promise<
    | {
        payload: IFilterPatient;
        type: "patients/updateFiltersModal";
      }
    | undefined
  >;
  filters: IFilter;
  total: number;
  perPage?: number;
}) => {
  const filterPerPage = [10, 25, 50];
  perPage &&
    perPage !== 10 &&
    perPage !== 25 &&
    perPage !== 50 &&
    filterPerPage.unshift(perPage);
    
  return (
    <div className='pagination'>
      <div>
        <label>Filas por página</label>
        <select
          defaultValue={perPage ?? filters.perPage}
          name='perPage'
          onChange={({ target }) =>
            handleFilterModal(target.name as keyof IFilterPatient, target.value)
          }
        >
          {filterPerPage.map((perPage, key) => (
            <option key={key} value={perPage}>
              {perPage}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>
          {filters.page! * filters.perPage! - filters.perPage! + 1}-
          {(filters.page! * filters.perPage!) > total ? total : filters.page! * filters.perPage!} de {total}
        </label>
        <Button
          onClick={() => handleFilterModal("page", filters.page! - 1)}
          disabled={filters.page === 1}
        >
          <i className='ssp-chevron-left'></i>
        </Button>
        <Button
          onClick={() => handleFilterModal("page", filters.page! + 1)}
          disabled={Math.ceil(total! / filters.perPage!) <= filters.page!}
        >
          <i className='ssp-chevron-right'></i>
        </Button>
      </div>
    </div>
  );
};


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../customHooks/useAuth';
import { Form } from 'react-bootstrap';
import { useService } from '../../../customHooks/useService';
import { useMeasurement } from '../../../customHooks/useMeasurement';
import { uniq } from 'lodash';
 


export const AddServiceModal = ({ AddServiceShow, handleAddServiceClose }: { AddServiceShow: boolean, handleAddServiceClose: () => void, }) => {
  const { services } = useService();
  const { measurement, handleMeasurement } = useMeasurement();
  const [measurementNames, setMeasurementNames] = useState<string[]>([]);
  const colors = [
    "border-left-dark-pink",
    "border-left-pink",
    "border-left-green",
    "border-left-red",
    "border-left-granate",
      "border-left-blue"
  ];
  /* health service name stored in the measurement state.*/

  /**
   * This function adds a new service to an array of measurement names.
   */
  const handleSelectService = (service: string) => {
    const serv = uniq([...measurementNames, service]);
    setMeasurementNames(serv);
  };

  /**
   * The function adds unique health service names to a list of measurement names and updates the state.
   */
  const handleAddService = () => {
    const measurementName = measurement.health_service_id as string[];
    const srv = uniq([...measurementName, ...measurementNames]);
    handleMeasurement({ health_service_id: srv });
    hide()
  }

 const hide = () => {
  setMeasurementNames([])
  handleAddServiceClose();
 }
  return (
    <Modal show={AddServiceShow} onHide={hide}>
      <Modal.Header className='justify-content-between' closeButton>
        <h5 className='fw-bold'>Añadir Servicio de Salud</h5>
      </Modal.Header>
      <Modal.Body className=''>
        <p className='text-left fw-light color-dark50 font-s mt-2 mb-4'>
          Selecciona de qué servicios quieres añadir mediciones:
        </p>

        {!!services.length &&
          services.map((service, key) => {
            const found = service.id ?  (measurement.health_service_id as string[]).includes(service.id) : false;
            return (
              <div key={key}>
                {!found ? (
                  <div
                    key={service.id}
                    className={`radius-8 pe-2 bg-prim5 d-flex align-items-center justify-content-between service-check ${colors[key]}`}
                  >
                    <p className='fw-normal lh-1 text-nowrap p-4 px-3 fw-bold'>
                      {service.label}
                    </p>
                    <div className=''>
                      <Form.Check
                        className='ms-4 me-0'
                        label=''
                        name='patient'
                        type='checkbox'
                        onChange={() => service.id && handleSelectService(service.id)}
                      />
                    </div>
                  </div>
                ) : (
                  <> </>
                )}
              </div>
            );
          })}
      </Modal.Body>
      <Modal.Footer className='justify-content-end'>
        <Button className='btn prim outline' onClick={handleAddServiceClose}>
          Anterior
        </Button>
        <Button className='btn prim' onClick={handleAddService}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { Button, Form } from "react-bootstrap"
import { IFilterPatient } from "../../../interfaces"
import { usePatient } from "../../customHooks/usePatient"
import {useService} from "../../customHooks/useService";
import {useAppDispatch, useAppSelector} from "../../../module/hooks";
import {serviceList} from "../../../module/reducers/service/slice";
import { useEffect, useState } from "react";

interface IProps {
  selectFilter?: boolean
  total?: number
}

export const FilterTable = ({ selectFilter = true }: IProps): JSX.Element => {
  const { filters, handleFilter, handleRemoveFilter } = usePatient()
  const dispatch = useAppDispatch()
  const { services, status } = useService();
  useEffect(() => {
    if (status === 'fetched') return;
    dispatch(serviceList());
  }, [dispatch, status])
  useEffect(() => { dispatch(serviceList())}, [dispatch])
  const [val, setVal] = useState<any>('');
  const defaultPathologyInput = "Servicio"
  const filterByService = (name: string, value: string) => {
    if (value === defaultPathologyInput) return;
    return handleFilter(name as keyof IFilterPatient, value, true)
  }

  useEffect(() => {
    setVal (undefined)
  }, [])


  return (
    <>
      <div className='searchbar-wrapper mt-4 pt-2'>
        <Form>
          <Form.Group
            className='form-group searchbar mb-0'
            controlId='searchbar'
          >
            <i className='ssp-search color-prim font-xml'></i>
            <Form.Control
              type='text'
              //value={filters.search}        
              value={val}
              name='search'
              onChange={({ target }) =>
              { 
                 handleFilter(target.name as keyof IFilterPatient, target.value)}
              }
              placeholder='Buscar nombre de paciente (mínimo 3 caracteres)'
            />
          </Form.Group>
          {selectFilter && (
            <Form.Group className='form-group select mb-0' controlId='service'>
              <Form.Select
                aria-label='service'
                name='pathologies'
                value={defaultPathologyInput}
                onChange={({ target }) =>
                  filterByService(target.name, target.value)
                }
              >
                <option>{defaultPathologyInput}</option>
                {services.length &&
                  services.map((service, key) => (
                    <option key={key} value={service.id}>
                      {service.label}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          )}
        </Form>
      </div>

      <div className='d-flex align-items-center justify-content-start pt-2'>
        {!!filters.pathologies?.length &&
          filters.pathologies.map((pathology, key) => (
            <TagComponent
              key={pathology + key}
              name={pathology.charAt(0).toUpperCase()
                  + pathology.slice(1).replace('_', ' ')}
              remove={() => handleRemoveFilter("pathologies", pathology)}
            />
          ))}
      </div>

      {/* Poner el numero de resultados */}
      {/*<p className="color-dark30 font-ml mt-3 mb-4"># resultados en total</p>*/}
    </>
  );
}

export const TagComponent = ({ name, remove }: { name: string, remove: () => any }) => {
  return <div className="pill active">{name} <Button onClick={remove} className="transparent fw-normal color-white p-0 font-ml ms-1"><i className="ssp-close"></i></Button></div>
}

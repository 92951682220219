
import { IPatient } from '../../../interfaces'
import { NoteList } from './NoteList'
import { Button } from 'react-bootstrap'
import { useEffect, useState } from 'react';
import { NewNoteModal } from '../../pages/patients/NewNoteModal';
import { useNote } from '../../customHooks/useNote';


export const NotePatientFile = (patient: IPatient) => {
  const [NewNoteShow, NewNoteSetShow] = useState<boolean>(false);
  const { handleNote } = useNote();

  const handleNewNoteClose = () => NewNoteSetShow(false);
  
  const handleNoteShow = () => {
    patient &&
      handleNote({
        patient: patient.id,
        patientInfo: patient,
      });
      NewNoteSetShow(true);
  };

  return (
    <>
      <div className='card p-4 mt-lg-0 mt-4'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <h3 className='font-xml fw-bold'>Notas</h3>

          <Button className=' alt-btn' onClick={handleNoteShow}>
            <span>Nueva nota</span>
            <i className='ssp-receipt icon-circle'></i>
          </Button>
        </div>

        <NoteList />

        
      </div>

      <NewNoteModal
        NewNoteShow={NewNoteShow}
        handleNewNoteClose={handleNewNoteClose}
      />
    </>
  );
}

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IServiceState } from "../../../interfaces";
import { serviceList, updateSandozService, updateService } from "./slice";

export const serviceExtraReducers = (builder: ActionReducerMapBuilder<IServiceState>) => {
  builder
  .addCase(serviceList.pending, (state, action) => {
    state.status = 'fetching'
  })
  .addCase(serviceList.fulfilled, (state, action) => {
    state.list = action.payload.content
    state.total = action.payload.content.length
    state.status = 'fetched'
  })
  .addCase(updateService.fulfilled, (state, action) => {
    const serviceUpdated = action.meta.arg
    state.list = state.list.map(service => {
      if(service.id! === serviceUpdated.id!) service = serviceUpdated
      return service
    })
  }) /*
  .addCase(serviceSandozList.fulfilled, (state, action) => {
    state.sandoz = action.payload.content
  })*/
  .addCase(updateSandozService.fulfilled, (state, action) => {
    state.sandoz = state.sandoz.map(sandoz => {
      if(sandoz.id === action.meta.arg.id) sandoz.active = true
      return sandoz
    })
  })
}
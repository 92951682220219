import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  createPatient, deletePatientService, patientByIdService, patientListService, updateHealthSurveyPatientService, updatePatientService } from "../../../api/services/patient.service";
import { IPatient, IPatientState } from "../../../interfaces/patient.interfaces";
import { RootState } from "../../store";
import { patientExtraReducers } from "./extraReducers";
import { getFilters } from "../../../helpers/restClient";
import { IHealthSurveyAnswer } from "../../../interfaces";

const initialFilters = { perPage: 6, page: 1, search: "", pathologies: [], services: [],  order_field: "", order_mode: ""}
export const initialState: IPatientState = { list: [], status: 'fetching', filters: initialFilters};

export const patientSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<IPatientState['filters']>) => {
      state.filters = action.payload
      return state
    },
    updateFiltersModal: (state, action: PayloadAction<IPatientState['filters']>) => {
      state.filters = action.payload
      return state
    },
    updatePatientById: (state, action: PayloadAction<Partial<IPatient>>) => {
      state.list = state.list.map(patient => patient.id === action.payload.id ? ({ ...patient, ...action.payload }) : { ...patient, checked: false})
    },
    clearStatus: (state) => {
      state.status = 'fetching'
    },
    clearFilters: (state) => {
      state.filters = initialFilters
    }
  },
  extraReducers: (builder) => patientExtraReducers(builder),
});

export const patientList = createAsyncThunk(
  'patients/list',
  async (_, { getState }) => {
    const rootState = getState() as RootState;
    const params = getFilters(rootState.patient)
    return patientListService(params)
  }
);

export const findPatientById = createAsyncThunk(
  'patients/getByid',
  async (id: string, { getState }) => {
    return patientByIdService(id);
  }
);

export const registerPatient = createAsyncThunk('patients/register', async (patient: IPatient) => createPatient(patient));

export const updatePatient = createAsyncThunk('patients/actualizar', async (patient: IPatient) => updatePatientService(patient));

export const deletePatient = createAsyncThunk('employees/delete', async (id: string) => deletePatientService(id));

export const updateHealthSurveyPatient = createAsyncThunk('patients/healthSurvey', async ({ patientID, answers }: { patientID: string, answers: IHealthSurveyAnswer[]}) => updateHealthSurveyPatientService(patientID, answers));

import { useEffect, useState } from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { useAppDispatch } from '../../../module/hooks';
import { serviceList, updateSandozService } from '../../../module/reducers/service/slice';
import { CardComponent } from '../../components/service/CardComponent';
import { EmptyComponent } from '../../components/service/EmptyComponent';
import { useService } from '../../customHooks/useService';
import { AddSandozServiceToPharmacyModdal } from "../../components/shared/modals/AddSandozServiceToPharmacyModdal";
import { IService } from '../../../interfaces';

export const ServicesPage = () => {
  const { services, status, findServiceStateById } = useService();
  const dispatch = useAppDispatch()
  const [expandService, setExpandService] = useState<boolean>(false)
  const [AddServiceShow, AddServiceSetShow] = useState(false);

  useEffect(() => {
    if (status === 'fetched') return;
    dispatch(serviceList());
  }, [dispatch, status])
  useEffect(() => { dispatch(serviceList())}, [dispatch])

  const handlerExpand = () => {
    setExpandService(true);
  };
  const handlerCloseExpand = () => {
    setExpandService(false);
  };
  const ChooseActivityShow = () => {
    console.log("ChooseActivityShow");
  };

  const handleAddServiceShow = () => AddServiceSetShow(true);
  const handleAddServiceClose = () => {
    console.log("ffffff")
    AddServiceSetShow(false);
  }
  console.log(".....",services)

  return (
    <>
      <Container className="pb-3">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="fw-bold font-xxml mb-4">Servicios de Salud</h1>
      
          {/* 
          <Link className="btn alt-btn" to="/servicios/crear" >
            <span>Añadir nuevo servicio</span>
            <i className="ssp-medkit icon-circle"></i>
          </Link>
         

          <Button className="btn prim align-items-center px-3" onClick={handleAddServiceShow}>
         <div className="bg-white icon-circle ">
           <i className="ssp-add color-prim font-l"></i>
         </div>
         <div className="ps-3 text-start">
           <p className="color-white mb-1">Añadir nuevo servicio</p>
         </div>
       </Button>
 */}

        </div>
       
        <Row className="mt-3">

        {!!services.length ?
              services.map((service: IService, key) => (
                <CardComponent service={service} key={key} />
              )) : <EmptyComponent />
            }

        </Row>
         {/*<AddSandozServiceToPharmacyModdal 
          AddServiceShow={AddServiceShow} 
          handleAddServiceClose={handleAddServiceClose} 
          services={serviceList}
          buttonClose={'Cerrar'}/>*/}
      </Container>
    </>
  )
}

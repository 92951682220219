import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotification } from "../../../interfaces/notification.interfaces";

export const initialState: INotification = { message: "", status: "success", show: false, type: "toast" };

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<INotification>) => {
      return state = { ...initialState, ...action.payload} 
    },
  },
});


import React, { useRef, useState } from "react";
import { Button, Carousel } from "react-bootstrap";
import { CarouselRef } from "react-bootstrap/esm/Carousel";
import { useAppointment } from "../../customHooks/useAppointment";
import { IPatient } from "../../../interfaces";
import moment from 'moment';
import { useEffect } from 'react';
import { IAppointment } from "../../../interfaces/appointment.interfaces";

const DateAppointments = ({ patient }: { patient?: IPatient }) => {
  const { appointments, nextDay, previousDay, filters, filterByDate } =
    useAppointment({ patient: patient?.id || undefined });
  const [initialStartDate, setInitialStartDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [initialEndDate, setInitialEndDate] = useState<string>(
    moment().add(3, "d").format("YYYY-MM-DD")
  );
  const [appointmentsData, setAppointmentsData] = useState<IAppointment[]>([]);
  const [changeWeekState, setChangeWeekState] = useState<boolean>(true);
  const ref = useRef<CarouselRef>(null);
  const [currentDayIndex, setCurrentDayIndex] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  /* Setting the startDate to the current date. */
  const startDate = new Date();

  /**
   * It takes in an index, a date, and an action, and then sets the startDate to the date, filters the
   * data by the date, and sets the currentDayIndex to the index
   * @param {number} index - the index of the selected date
   * @param {Date} date - Date - the date that was selected
   * @param {string} [action] - The action that triggered the change. It can be 'prev' or 'next'
   */
  const handlerSelect = (index: number, date: Date, action?: string) => {
    const currentDay = new Date()
    const currentDayFormatted = moment(currentDay).format("YYYY-MM-DD")

    if(ref.current){
      if(action === 'prev'){
        ref.current.prev();
      } else if (action === 'next'){
        ref.current.next();
      }
    }

    let lastDay = new Date()
    lastDay.setDate(date.getDate() + 4)
    const filterDate = moment(date).format("YYYY-MM-DD");
    const filterDateEnd = moment(date).add(4, "d").format("YYYY-MM-DD");

    //Seleccion de dia dentro de carrusel primero o dia actual
    if(date < currentDay && moment(date).diff(moment(currentDay), 'days') === -1 && currentDay < lastDay){
      handlerIndividualSelect(currentDayFormatted)
      //setInitialStartDate(currentDayFormatted)
    }else{
      handlerIndividualSelect(filterDate)
    }

    setInitialStartDate(filterDate);
    setInitialEndDate(filterDateEnd);
    setChangeWeekState(true);
    //filterByDate(filterDate, filterDateEnd);
    setCurrentDayIndex(index);
  };

  const handlerIndividualSelect = (date: string) => {
    setSelectedDate(date);
    filterByDate(date, date);
  };

  useEffect(() => {
    filterByDate(initialStartDate, initialEndDate);
  }, [initialStartDate, initialEndDate]);

  useEffect(() => {
    if (changeWeekState && appointments && appointments.length > 0) {
      setAppointmentsData(appointments);
      setChangeWeekState(false);
      //filterByDate(initialStartDate, initialStartDate);
    }
  }, [appointments]);

  /**
   * It renders the days of the week
   * @returns An array of buttons.
   */
  const renderDays = () => {
    const days = [];
    /* Setting the startDate to the current date. */
    currentDayIndex === 0
      ? startDate.setDate(startDate.getDate() - 1)
      : startDate.setDate(startDate.getDate() + currentDayIndex -1);

    const prevDate = new Date(startDate);
    prevDate.setDate(prevDate.getDate() - 5);
    /* Getting the current month. */
    const currentMonth = new Intl.DateTimeFormat("es-ES", {
      month: "long",
    }).format(startDate);
    /* Getting the current formatted month. */
    const currentMonthFormatted =
      currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
    /* Getting the current year. */
    const currentYear = startDate.getFullYear();
    /* Checking if the currentDayIndex is greater than 0, if it is, it will subtract 5 from the
    currentDayIndex. If it is not, it will set the previousIndex to 0. */
    //const previousIndex = currentDayIndex > 0 ? currentDayIndex - 5 : 0;
    const previousIndex = currentDayIndex - 5;
    /* Adding 5 to the currentDayIndex. */
    const nextIndex = currentDayIndex + 5;
    /* Getting the current date and then converting it to a number. */
    const todayFormatted = moment(new Date()).format('YYYY-MM-DD');
    const todayValue = new Date(todayFormatted).valueOf();
    const renderDate = startDate;
    
    /* Looping through the days. */
    for (let i = currentDayIndex; i < currentDayIndex + 5; i++) {
      /* Converting the startDate to a string and then converting it to a number. */
      const startDateFormatted = moment(startDate).format("YYYY-MM-DD");
      const startDateValue = new Date(startDateFormatted).valueOf();
      const dateSelected =
        selectedDate && new Date(selectedDate).valueOf() === startDateValue;
      const hasAppointment = appointmentsData.some(
        (appointment) =>
          appointment.periodicity_start_date === startDateFormatted
      );
      const classCurrentDay = todayValue === startDateValue;
      const classCurrent =
        selectedDate && dateSelected
          ? true
          : selectedDate === '' && classCurrentDay
          ? true
          : false;
      const buttonClass = classCurrent ? "day-btn current" : "day-btn";
      const dayOfWeek = new Intl.DateTimeFormat("es-ES", {
        weekday: "long",
      }).format(startDate);

      if(hasAppointment){
        days.push(
          <Button
            className={`${buttonClass} bg`}
            key={i}
            onClick={() => handlerIndividualSelect(startDateFormatted)}
          >
            <span className="rounded px-2 has-appointment">{startDate.getDate().toString().padStart(2, "0")}</span>
            <label className='px-1'>{dayOfWeek}</label>
          </Button>
        );
      } else {
        days.push(
          <div
            className={`${buttonClass}`}
            key={i}
          >
            <span>{startDate.getDate().toString().padStart(2, "0")}</span>
            <label className='px-1'>{dayOfWeek}</label>
          </div>
        );
      }

      /* Incrementing the date by 1 day. */
      startDate.setDate(startDate.getDate() + 1);
    }
    return (
      <>
        <div className='d-flex align-items-center justify-content-between mt-4 mb-3'>
          <Button
            className='transparent mt-3 carousel-btn'
            onClick={() => handlerSelect(previousIndex, prevDate, "prev")}
          >
            <span>
              <i className='ssp-chevron-left '></i>
            </span>
          </Button>
          <div className='fw-normal'>
            <p className='text-center fw-bold color-darkblue py-3'>
              {currentMonthFormatted} {currentYear}
            </p>
            <Carousel controls={false} interval={null} className='mb-4'>
              <Carousel.Item>
                <div>{days}</div>
              </Carousel.Item>
            </Carousel>
          </div>
          <Button
            className='transparent mt-3 carousel-btn'
            onClick={() => handlerSelect(nextIndex, renderDate, "next")}
          >
            <span>
              <i className='ssp-chevron-right '></i>
            </span>
          </Button>
        </div>
      </>
    );
  };
  return <>{renderDays()}</>;
};

export default DateAppointments;

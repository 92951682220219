import { Row, Col, Button } from 'react-bootstrap';
import { useAppointment } from '../../../customHooks/useAppointment';
import { IPatient } from '../../../../interfaces';

import { PrintModal } from '../../../components/shared/modals/PrintModal';
import { useRef, useState } from 'react';
import { SimpleAppointmentCard } from '../../../components/appointment/SimpleAppointmentCard';
import { useReactToPrint } from 'react-to-print';

export const NewAppointmentStep4 = ({ patient }: { patient?: IPatient }) => {
  const [PrintShow, PrintSetShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayPrintOption, setDisplayPrintOption] = useState<boolean>(true);

  const handlePrintShow = () => PrintSetShow(true);
  const handlePrintClose = () => PrintSetShow(false);

  const { createAppointment, appointment } = useAppointment(
    {}
  );
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    /*onBeforePrint: () => {
      createAppointment(appointment, setLoading, false);
    },*/
    onAfterPrint: () => setDisplayPrintOption(false),
  });
  return (
    <>
      <Row>
        <Col lg={12}>
          {appointment && appointment.patient && (
            <>

              <div className='appointment-list' ref={componentRef}>

                {/* Titulo solo visible al Imprimir */}
                <h3 className='font-xml fw-bold mb-4 color-sec print-title'>
                  <span>Nueva cita</span>
                  <span className="font-m">
                  <img src="/assets/img/logo-ps.png" alt="logo sandoz pacientes" />
                  </span>
                </h3>
                <SimpleAppointmentCard
                  patientData={patient}
                  {...appointment}
                  cardType='simple'
                />
              </div>
            </>
          )}
          {displayPrintOption && (
            <>
              <h3 className='font-ml fw-bold mb-3 color-dark mt-4'>
                Imprimir cita
              </h3>

              <div className='bg-dark5 radius-12 p-3 d-flex align-items-center mb-3'>
                <div className='icon-circle big bg-prim'>
                  <i className='ssp-print_outline font-xl color-white'></i>
                </div>
                <div className='ps-3 w-100'>
                  <p className='fw-bold font-m mb-2'>Nueva cita</p>
                  <p className='fw-light font-s'>Para entregar al paciente</p>
                </div>

                <Button
                  className='btn prim outline px-4'
                  onClick={handlePrintShow}
                >
                  <span className='text-nowrap'>Imprimir cita</span>
                  <i className='ssp-print_outline ms-2 font-l'></i>
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>

      <PrintModal
        PrintShow={PrintShow}
        handlePrintClose={handlePrintClose}
        handlePrint={handlePrint}
      />
    </>
  );
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createMeasurementService, deleteMeasurementService, getHealthMeasurementSurveyService, getHealthServiceSurveyService, measurementByIdService, measurementByPatientService, measurementListService, updateHealthMeasurementSurveyService, updateHealthServiceSurveyService, updateMeasurementService } from "../../../api/services/measurement.service";
import { IMeasurementState, IMeasurement, IPatient } from "../../../interfaces";
import { reminderExtraReducers } from "./extraReducers";

export const initialMeasurement = { type: 'Buena', visit_type:"",first_measure: false, data: "", note: "", health_service_id : "", health_service_name : "", employee_id : "", employee_complete_name : "", recommendation : "", patient: "", health_service_survey: [], health_patient_survey: [], health_measurement_survey: [], health_measurement_survey_answers: [], measurement_step: 1, health_measurement_notes: {patient: "", private: "", add: false} } as IMeasurement;

export const initialState: IMeasurementState = { measurement: initialMeasurement, list: [], status: 'nofetched' };

export const measurementSlice = createSlice({
  name: 'measurement',
  initialState,
  reducers: {
    newMeasurement: (state, action: PayloadAction<Partial<IMeasurement>>) => {
      //console.log("state",state)
      //console.log("action",action)
      state.measurement = { ...state.measurement, ...action.payload }
    },
    clearStatus: (state) => {
      state = initialState


      return state
    },
  },
  extraReducers: (builder) => reminderExtraReducers(builder),
});

export const measurementById = createAsyncThunk(
  'measurements/measurementById',
  async (measurement: Partial<IMeasurement>) => measurementByIdService(measurement)
);

export const serviceList = createAsyncThunk('measurements/list', async () => measurementListService());

export const listByPatient = createAsyncThunk(
  'measurements/listByPatient',
  async (patient: IPatient['id']) => measurementByPatientService(patient)
);

export const registerMeasurement = createAsyncThunk(
  'measurements/register',
  async (service: Partial<IMeasurement>) => createMeasurementService(service)
);

export const updateMeasurement = createAsyncThunk(
  'measurements/update',
  async (service: Partial<IMeasurement>) => updateMeasurementService(service)
);

export const deleteReminder = createAsyncThunk('measurements/delete', async (reminder: IMeasurement) => deleteMeasurementService(reminder));


export const getHealthServiceSurvey = createAsyncThunk('measurements/healthSurveyService/get', async ({
  patientID,
  serviceID,
}: {
  patientID: string;
  serviceID: string;
}) => getHealthServiceSurveyService(patientID, serviceID));

export const updateHealthServiceSurvey = createAsyncThunk('measurements/healthSurveyService/update', async ({
  patientID,
  serviceID,
  answers,
}: {
  patientID: string;
  serviceID: string;
  answers: any[];
}) => updateHealthServiceSurveyService(patientID, serviceID, answers));

export const getHealthMeasurementSurvey = createAsyncThunk('measurements/healthSurveyMeasurement/get', async ({
  patientID,
  serviceID,
}: {
  patientID: string;
  serviceID: string;
}) => getHealthMeasurementSurveyService(patientID, serviceID));

export const updateHealthMeasurementSurvey = createAsyncThunk('measurements/healthSurveyMeasurement/update', async ({
  patientID,
  answers,
}: {
  patientID: string;
  answers: any[];
}) => updateHealthMeasurementSurveyService(patientID, answers));

import { Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../module/hooks';
import { deleteService, serviceList, updateSandozService } from '../../../module/reducers/service/slice';
import { NewServiceCardComponent } from '../../components/service/NewServiceCardComponent';
import { InputField } from '../../components/shared/fields';
import { BasicLoader } from '../../components/shared/loaders';
import { useService } from '../../customHooks/useService';

export const NewServicePage = () => {

  const { createService, externalServices, status, sandozServices, handleChangeEdit, handleUpdateService, handelAddNewService, handleSave } = useService()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (status === 'fetched') return;
    dispatch(serviceList());
  }, [dispatch, status])
  useEffect(() => { dispatch(serviceList())}, [dispatch])

  return (
    <Container className="pb-5">
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="fw-bold font-xxml mb-4">Añade y gestiona los servicios de tu farmacia</h1>
      </div>

      <div>
        <Link className="btn transparent" to="/servicios">
          <i className="ssp-chevron-left me-1"> </i>
          <span className="font-s">Volver a todos los servicios</span>
        </Link>
      </div>

      <h3 className="font-ml fw-bold mb-2 mt-5 pt-2">Selecciona Servicios de Salud</h3>
      <p className="fw-light mb-4">Si dispones de Servicios de Salud Sandoz en tu farmacia puedes seleccionarlos en el listado y se agregarán automáticamente en tu listado de servicios.  </p>

      <Form>
        <Row className="pt-3">
          {
            !!sandozServices.length && sandozServices.map((service, key) => (
              <Col lg={4} key={`sandoz-${key}`}>
            <div className="radius-8 p-4 ps-5 mb-2 bg-prim5 d-flex align-items-center justify-content-between position-relative overflow-hidden">
              <div className="service-type blue"></div>

              <p className="fw-bold lh-1 d-flex align-items-center">
                Servicio de { service.name }
              </p>
              <div key="services" className="">
                <Form.Check className="ms-4 me-0"
                  label=""
                  name="services"
                  type="checkbox"
                  onChange={() => service.active ? dispatch(deleteService(service.id)) : dispatch(updateSandozService(service))}
                  checked={service.active}
                />

              </div>
            </div>
          </Col>
            ))
          }
        </Row>

        <hr className="my-5" />
         { /*
        <Row>
          <Col lg={4}>
            <h3 className="font-ml fw-bold mb-2 pt-2">Otros Servicios de Salud</h3>
            <p className="fw-light mb-3">Si tienes otros Servicios de Salud que no sean Sandoz puedes agregarlos aquí:</p>
            {
              !!externalServices.length && externalServices.map((service, key) => service.edit ? (
                <div className="radius-8 p-4 mb-2 mt-4 bg-prim5" key={key}>
                  <h3 className="font-ml fw-bold mb-4 pt-2">Añadir nuevo servicio</h3>
                  <Formik
                    initialValues={{ ...service }}
                    onSubmit={(fields, actions) => service.id ? handleUpdateService({ ...fields }, actions.setSubmitting) : createService({ ...fields, start_date: moment().format('YYYY-MM-DD') }, actions.setSubmitting)}
                  >
                    {
                      (props) => {
                        return (
                          <>
                            <InputField {...props} type="text" label={'Area del servicio de salud'} name="name" />
                            <InputField {...props} type="text" label={'Maquinaria'} name="machine" />
                            <div className="d-flex align-items-center justify-content-end mt-4">
                              <Button className="transparent color-red px-3" onClick={() => handleChangeEdit(service.id!)}>Descartar</Button>
                              <Button className="prim outline ms-2 px-4" onClick={() => props.handleSubmit()} >{props.isSubmitting ? <BasicLoader /> : service.id ? 'Actualizar Servicio' : 'Añadir Servicio'}</Button>

                            </div>
                          </>
                        )
                      }
                    }
                  </Formik>
                </div>
              ) : <NewServiceCardComponent {...service} key={key} />)
            }

            <Button className="prim outline my-4 d-inline-flex align-items-center" onClick={() => handelAddNewService()} disabled={!!externalServices.find(service => !service.id)}>Añadir otro servicio <span className="font-l ms-2">+</span></Button>
          </Col>
        </Row>
          */ }  
        <div className="d-flex align-items-center justify-content-end mt-4">
          <Button className="prim ms-2 px-4" onClick={() => handleSave()}>Guardar cambios</Button>
        </div>
      </Form>


    </Container>

  )
}



import Modal from 'react-bootstrap/Modal';
import { NewPatientDataPage } from '../../../pages/patients/NewPatientData';
import React, {useState} from 'react';

interface IProps {
  NewPatientShow: boolean,
  handleNewPatientClose: any,
  patientID?: string;
}

export const NewPatientModal: React.FC<IProps> = ({ NewPatientShow, handleNewPatientClose, patientID}) => {
  const edit = patientID ? true : false;
  const title = edit ? "Editar paciente" : "Añadir nuevo paciente";

  return (
    <Modal size='lg' show={NewPatientShow} onHide={handleNewPatientClose}>
      <Modal.Header className='justify-content-between' closeButton>
        <h5 className='fw-bold'>{title}</h5>
      </Modal.Header>
      <Modal.Body>
        <NewPatientDataPage edit={edit} patientID={patientID} handleNewPatientClose={handleNewPatientClose} />
      </Modal.Body>
    </Modal>
  );
}

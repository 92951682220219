import { useNavigate } from "react-router-dom";
import { IService } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../module/hooks";
import { setNotification } from "../../module/reducers/notification";
import { handleAddNewService, handleEdit } from "../../module/reducers/service";
import { registerService, serviceList, updateService } from "../../module/reducers/service/slice";
import { routes } from "../../router/constants";

export const useService = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { list, total, status, sandoz } = useAppSelector(state => state.service)

  const handleCreateService = async (fields: IService, setSubmitting: (isSubmitting: boolean) => void) => {
    const { code, message } = await dispatch(registerService(fields)).unwrap();
    setSubmitting(false);
    if(code! >= 400) return  code! >=500 ? dispatch(setNotification({ message: "Ha ocurrido un error al guardar el servicio", status: "danger", show: true, type: "toast" })) : dispatch(setNotification({ message: message!, status: "danger", show: true, type: "toast" }))
    dispatch(serviceList());
    return dispatch(setNotification({ message: "Servicio agregado exitosamente", status: "success", show: true, type: "toast" }))
  }

  const handleChangeEdit = (id: string) => dispatch(handleEdit(id))

  const handleUpdateService = async (fields: IService, setSubmitting: (isSubmitting: boolean) => void) => {
    const { code } = await dispatch(updateService(fields)).unwrap()
    setSubmitting(false);
    if(code! >= 400) return dispatch(setNotification({ message: "Ha ocurrido un error al actualizar el servicio", status: "danger", show: true, type: "toast" }))
    handleChangeEdit(fields.id!);
    return dispatch(setNotification({ message: "Servicio actualizado exitosamente", status: "success", show: true, type: "toast" }))
  }

  const handleCheckedUpdateService = async (fields: IService) => {
    const { code } = await dispatch(updateService({...fields, active: !fields.active})).unwrap()
    if(code! >= 400) return dispatch(setNotification({ message: "Ha ocurrido un error al actualizar el servicio", status: "danger", show: true, type: "toast" }))
    return dispatch(setNotification({ message: "Servicio actualizado exitosamente", status: "success", show: true, type: "toast" }))
  }

  const handelAddNewService = () => dispatch(handleAddNewService())

  const handleSave = () => {
    navigate(routes.services)
    return dispatch(setNotification({ message: "Los cambios han sido guardados", status: "success", show: true, type: "modal" }))
  }

  const findServiceStateById = (id: IService['id']) => list.find(service => service.id === id)

  return {
    externalServices: list.filter(service => service.type !== 'Sandoz'),
    sandozServices: sandoz,
    services: list,
    total,
    createService: handleCreateService,
    status,
    handleChangeEdit,
    handleUpdateService,
    handleCheckedUpdateService,
    handelAddNewService,
    handleSave,
    findServiceStateById
  }
}

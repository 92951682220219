import { get } from "../../helpers";
import { post, put } from "../../helpers/restClient";
import { IResponseDetail } from "../../interfaces";
import { ILoginUser, IRecoveryUser, IRegisterUser } from "../../interfaces/auth.interfaces";
import { IUserAuthInfo, IUserInfo, IUserPassword } from "../../interfaces/user.interfaces";

export const loginService = async (credentials: ILoginUser): Promise<IUserInfo> =>
  post(`/login`, credentials, false)

export const registerService = async (credentials: IRegisterUser) =>
  post(`/api/pharmacy`, credentials, false)

export const updateUserService = async (credentials: IUserInfo): Promise<IResponseDetail<IUserInfo>> =>
  put(`/api/pharmacy`, credentials)

export const recoveryService = async (data: IRecoveryUser): Promise<IUserInfo> =>
  post(`/api/user/password/send-recovery-email`, data)

export const changePasswordService = async (data: IUserPassword): Promise<IUserInfo> =>
  put(`/api/usuario/clave/cambiar`, data)

export const checkAuthService = async (): Promise<any> =>
    get(`/api/pharmacy`)

export const checkTermsService = async (): Promise<any> =>
  get(`/api/pharmacy/terms`)
export const confirmTermsService = async (): Promise<any> =>
    post(`/api/pharmacy/terms`, {})
  

// mock authenticate
// /7b38505b-66eb-4e84-b22b-08be458c4a67
// mock error authentication
// https://mocki.io/9e8a3b8d-5dab-4a0f-8145-f65802835701
// https://seguimiento-pacientes-api.syntonize.dev/farmacia/registro
import * as yup from 'yup';
import i18next from 'i18next';

const cif = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const phone = /^[679]{1}[0-9]{8}$/

export const loginValidationSchema = yup.object().shape({
  username: yup.string().email(i18next.t(`register.validationSchema.beEmail`)).required(i18next.t(`register.validationSchema.email`)),
  password: yup.string().required(i18next.t(`register.validationSchema.password`)),
});

export const registerValidationSchema = yup.object().shape({
  name: yup.string().required(i18next.t(`register.validationSchema.name`)),
  address: yup.string().required(i18next.t(`register.validationSchema.address`)),
  owner_name: yup.string().required(i18next.t(`register.validationSchema.owner_name`)),
  owner_surname: yup.string().required(i18next.t(`register.validationSchema.owner_surname`)),
  cif: yup.string().required(i18next.t(`register.validationSchema.cif`)).matches(cif, "Debe ser un CIF valido, por ejemplo: A0101010C"),
  telephone: yup.string().required(i18next.t(`register.validationSchema.telephone`)).matches(phone, 'Debe introducir un teléfono valido'),
  email: yup.string().email(i18next.t(`register.validationSchema.beEmail`)).required(i18next.t(`register.validationSchema.email`)),
  password: yup.string().required(i18next.t(`register.validationSchema.password`))
  .min(8, 'Debe contener mínimo 8 caracteres')
  .max(15, 'Debe contener mamáximoximo 15 caracteres')
  .matches(/\d+/, "Debe contener al menos 1 número")
  .matches(/[a-z]+/, "Debe contener al menos 1 letra minúscula")
  .matches(/[A-Z]+/, "Debe contener al menos 1 letra mayúscula")
  .matches(/[!@.,#$%^&*()-+]+/, "Debe contener al menos 1 carácter especial (!@.,#$%^&*()-+)")
  .test(
    {
      test: (value) => !/\s+/.test(value!), message: "No puede tener espacios en blancos"
    }
  ),
  confirmPassword: yup.string().required(i18next.t(`register.validationSchema.confirmPassword`)).test({ test: (value, context) => value === context.parent.password, message: i18next.t(`register.validationSchema.confirmPasswordEqualToPassword`) }),
});

export const updateProfileValidationSchema = yup.object().shape({
  name: yup.string().required(i18next.t(`register.validationSchema.name`)),
  address: yup.string().required(i18next.t(`register.validationSchema.address`)),
  owner_name: yup.string().required(i18next.t(`register.validationSchema.owner_name`)),
  owner_surname: yup.string().required(i18next.t(`register.validationSchema.owner_surname`)),
  cif: yup.string().required(i18next.t(`register.validationSchema.cif`)).matches(cif, "Debe ser un CIF valido, por ejemplo: A0101010C"),
  telephone: yup.string().required(i18next.t(`register.validationSchema.telephone`)).matches(phone, 'Debe introducir un teléfono valido'),
});

export const recoveryValidationSchema = yup.object().shape({
  email: yup.string().email(i18next.t(`register.validationSchema.beEmail`)).required(i18next.t(`register.validationSchema.email`)),
});

export const changePasswordValidationSchema = yup.object().shape({
  old_password: yup.string().required(i18next.t(`updateInfo.validationSchema.old_password`)),
  new_password: yup.string().required(i18next.t(`updateInfo.validationSchema.new_password`))
  .min(8, 'Debe contener mínimo 8 caracteres')
  .max(15, 'Debe contener mamáximoximo 15 caracteres')
  .matches(/\d+/, "Debe contener al menos 1 número")
  .matches(/[a-z]+/, "Debe contener al menos 1 letra minúscula")
  .matches(/[A-Z]+/, "Debe contener al menos 1 letra mayúscula")
  .matches(/[!@.,#$%^&*()-+]+/, "Debe contener al menos 1 carácter especial (!@.,#$%^&*()-+)")
  .test(
    {
      test: (value) => !/\s+/.test(value!), message: "No puede tener espacios en blancos"
    }
  ),
  repeat_new_password: yup.string().required(i18next.t(`updateInfo.validationSchema.repeat_new_password`)).test({ test: (value, context) => value === context.parent.new_password, message: i18next.t(`updateInfo.validationSchema.confirmPasswordEqualToPassword`) }),
});

export const initialRegisterValues = { name: "", address: "", owner_name: "", owner_surname: "", cif: "", telephone: "", email: "", password: "", confirmPassword: "" }
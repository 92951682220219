import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './reducers/user'
import reminderReducer from './reducers/reminder'
import patientReducer from './reducers/patient'
import serviceReducer from './reducers/service'
import appointmentReducer from './reducers/appointment'
import employeeReducer from './reducers/employee'
import measurementReducer from './reducers/measurement'
import noteReducer from './reducers/note'
import notificationReducer from './reducers/notification'
export const store = configureStore({
  reducer: {
    user: userReducer,
    reminder: reminderReducer,
    patient: patientReducer,
    service: serviceReducer,
    appointment: appointmentReducer,
    employee: employeeReducer,
    measurement: measurementReducer,
    note: noteReducer,
    notification: notificationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../customHooks/useAuth';
import { Row, Col } from 'react-bootstrap';
import { NewActivityModal } from './NewActivityModal';
import { usePatient } from '../../../customHooks/usePatient';
import { useAppDispatch } from '../../../../module/hooks';
import { findPatientById } from '../../../../module/reducers/patient/slice';
import { useAppointment } from '../../../customHooks/useAppointment';
import { useMeasurement } from '../../../customHooks/useMeasurement';
import { useNote } from '../../../customHooks/useNote';
import { useNavigate } from 'react-router-dom';
import { NewAppointmentModal } from './NewAppointmentModal';
import { NewNoteModal } from '../../../pages/patients/NewNoteModal';
import { routes } from '../../../../router/constants';
// import { getPatientById } from '../../../../services/patient.service';


interface IProps {
  ChooseActivityShow: boolean;
  handleChooseActivityClose: () => void;
  patientID?: string;
  source?: string;
}
export const ChooseActivityModal: React.FC<IProps> = ({
  ChooseActivityShow,
  handleChooseActivityClose,
  source,
  patientID,
}): JSX.Element => {
  const { logout } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleAppointment, appointment } = useAppointment({});
  const { handleMeasurement, measurement } = useMeasurement();
  const { handleNote, note } = useNote();
  const { patient } = usePatient();
  const [NewActivityShow, setNewActivityShow] = useState<boolean>(false);
  const [NewAppointmentShow, setNewAppointmentShow] = useState<boolean>(false);
  const [NewNoteShow, NewNoteSetShow] = useState<boolean>(false);
  const handleNewNoteShow = () => NewNoteSetShow(true);
  const handleNewNoteClose = () => NewNoteSetShow(false);
  const handleNewAppointmentClose = () => setNewAppointmentShow(false);
  const [title, setTitle] = useState<string>("");
  const [activityType, setActivityType] = useState<string>("");

  const handleNewActivityShow = (title: string, type: string): void => {
    setActivityType(type);
    if (patientID && source && patient) {
      if (type === "appointment") {
        handleAppointment({
          patient: patient.id,
          patientInfo: patient,
        });
      }
      if (type === "visit") {
        handleMeasurement({
          patient: patient.id,
          patientInfo: patient,
        });
      }
      if (type === "note") {
        handleNote({
          patient: patient.id,
          patientInfo: patient,
        });
      }
    } else {
      setTitle(title);
      setNewActivityShow(true);
      handleChooseActivityClose();
    }
  };
  const handleNewActivityClose = () => setNewActivityShow(false);

  useEffect(() => {
    if (patientID){
      dispatch(findPatientById(patientID)).unwrap();
    }
  }, [patientID, dispatch]);

  useEffect(() => {
    if(patientID && source && patient){
      activityType === "appointment" &&
        appointment.patientInfo &&
        setNewAppointmentShow(true);
      activityType === "note" && note.patientInfo && NewNoteSetShow(true);
      activityType === "visit" &&
        measurement.patientInfo &&
        navigate(routes.newMedition);
    }
  }, [activityType, measurement.patientInfo, appointment.patientInfo, note.patientInfo, source, navigate]);

  return (
    <>
      <Modal
        size='lg'
        show={ChooseActivityShow}
        onHide={handleChooseActivityClose}
      >
        <Modal.Header className='justify-content-between' closeButton>
          <h5 className='fw-bold'>Nueva actividad</h5>
        </Modal.Header>
        <Modal.Body>
          <p className='text-left fw-light color-dark50 font-s mt-3'>
            Selecciona qué quieres hacer:
          </p>

          <Row className='my-4'>
            <Col md={4}>
              <Button
                className='activity-btn'
                onClick={() => {
                  handleChooseActivityClose && handleChooseActivityClose();
                  handleNewActivityShow("Nueva Cita", "appointment");
                } 
                }
              >
                <div className='icon-circle big bg-prim30 color-prim'>
                  <i className='ssp-calendar-clean font-xl'></i>

                  <span>+</span>
                </div>
                <h5 className='font-l color-prim fw-bold my-2'>Nueva Cita</h5>
                <p className='font-m color-prim fw-light'>
                  Genera una cita para un paciente.
                </p>
              </Button>
            </Col>
            <Col md={4}>
              <Button
                  className='activity-btn'
                  onClick={() => handleNewActivityShow("Nueva medición", "visit")}
              >
                <div className='icon-circle big bg-prim30 color-prim'>
                  <i className='ssp-person font-xl'></i>

                  <span>+</span>
                </div>
                <h5 className='font-l color-prim fw-bold my-2'>Nueva Medición</h5>
                <p className='font-m color-prim fw-light'>
                  Registra una medición de tu paciente.
                </p>
              </Button>
            </Col>
            <Col md={4}>
              <Button
                className='activity-btn'
                onClick={() => handleNewActivityShow("Nueva Nota", "note")}
              >
                <div className='icon-circle big bg-prim30 color-prim'>
                  <i className='ssp-receipt font-xl'></i>

                  <span>+</span>
                </div>
                <h5 className='font-l color-prim fw-bold my-2'>Nueva Nota</h5>
                <p className='font-m color-prim fw-light'>
                  Añade una nota sobre un paciente.
                </p>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <NewActivityModal
        NewActivityShow={NewActivityShow}
        handleNewActivityClose={handleNewActivityClose}
        title={title}
        type={activityType}
      />
      <NewAppointmentModal
        patientID={patient?.id}
        NewAppointmentShow={NewAppointmentShow}
        handleNewAppointmentClose={handleNewAppointmentClose}
      />
      <NewNoteModal
        NewNoteShow={NewNoteShow}
        handleNewNoteClose={handleNewNoteClose}
      />
    </>
  );
};

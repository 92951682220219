import { Row, Col } from 'react-bootstrap';

export const NoReminders = ({ urgent }: { urgent?: boolean }) => {
  return (
    <Row className="align-items-center">
      <Col lg={6}>
        <div className="reminder-card">
          <div className="d-flex align-items-center justify-content-between">
            <p className="color-prim font-s fw-semibold">Recordatorio</p>

            <div className="d-flex align-items-center">
              <span className={`badge ${urgent ? 'red': 'green'} `} style={{ width: '60px', height: '24px' }}></span>
            </div>
          </div>

          <div className="mt-2 mb-1">
            <p className="fw-bold">
              <span className="empty-bar bg-prim70 p-2 w-50"></span>
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-start color-dark70 font-s">
            <span className="empty-bar bg-prim30 p-1 w-25"></span>
          </div>

          <Row className="mt-3">
            <Col lg={6}>
              <div className="form-group readonly mb-0">
                <label>
                  <span className="empty-bar bg-prim30 p-1 w-75"></span>
                </label>
                <p className="font-s">
                  <span className="empty-bar bg-prim30 p-1"></span>
                  <span className="empty-bar bg-prim30 p-1"></span>
                  <span className="empty-bar bg-prim30 p-1"></span>
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group readonly mb-0">
                <label><span className="empty-bar bg-prim30 p-1 w-75"></span></label>
                <p className="font-s">
                  <span className="empty-bar bg-prim30 p-1"></span>
                  <span className="empty-bar bg-prim30 p-1"></span>
                  <span className="empty-bar bg-prim30 p-1"></span>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col lg={6}>
        <div className="text-center font-xl fw-light">Aquí se verán tus <br /><span className="fw-bold color-prim">Recordatorios { urgent ? 'urgentes' : 'generales'}</span></div>
      </Col>
    </Row>

  )
}

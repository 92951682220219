import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IMeasurementState } from "../../../interfaces";
import { listByPatient, measurementById, getHealthServiceSurvey, getHealthMeasurementSurvey } from "./slice";

export const reminderExtraReducers = (builder: ActionReducerMapBuilder<IMeasurementState>) => {
  builder
    .addCase(listByPatient.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(listByPatient.fulfilled, (state, action) => {
      state.status = 'fetched'
      state.list = action.payload.content.sort((a,b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime())
    })
    .addCase(measurementById.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(measurementById.fulfilled, (state, action) => {
      state.status = 'fetched'
      state.measurement = action.payload.content
    })
    .addCase(getHealthServiceSurvey.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(getHealthServiceSurvey.fulfilled, (state, action) => {
      state.status = 'fetched'
      state.measurement.health_service_survey = action.payload.content
    })
    .addCase(getHealthMeasurementSurvey.pending, (state) => {
      state.status = 'fetching'
    })
    .addCase(getHealthMeasurementSurvey.fulfilled, (state, action) => {
      const payload = action.payload?.content?.map((item:any) => {
        return {
          ...item,
          serviceID: action.meta.arg.serviceID
        }
      })
      state.status = 'fetched'
      state.measurement.health_measurement_survey = payload
    })

}
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FilterSelect } from '../../../components/patient/FilterSelect';
import { useReminder } from '../../../customHooks/useReminder';

export const NewReminderStep1 = () => {
  const { reminder, handleReminder } = useReminder({})

  return (
    <Row>
      <Col lg={6} className="pe-lg-5">
        <FilterSelect value={reminder.patient || ''} handleSelect={handleReminder} />
        
      </Col>
      <Col lg={6} className="ps-lg-5 mt-lg-0 mt-4">
        <div className="radius-8 p-2rem bg-prim5">
        <p className="fw-light mb-4">Si este paciente no está en tu base de datos puedes incluirlo ahora:</p>

        <Link className="btn prim ms-3" to="/pacientes/crear">Crear nuevo paciente</Link>

          {/* <h3 className="font-ml fw-bold mb-2">Crear nuevo paciente</h3>
          <p className="fw-light mb-4">Si este paciente no está en tu base de datos puedes incluirlo ahora y terminar más tarde de cumplimentar la ficha:</p>
          <Form.Group className="form-group" controlId="patientName">

            <Form.Control type="text" placeholder="Nombre del paciente*" />
          </Form.Group>
          <Form.Group className="form-group" controlId="patientSurame">

            <Form.Control type="text" placeholder="Apellidos del paciente*" />
          </Form.Group>

          <Form.Group className="form-group" controlId="phone">

            <Form.Control type="text" placeholder="Teléfono del paciente*" />
          </Form.Group>
          <Form.Group className="form-group" controlId="dni">

            <Form.Control type="text" placeholder="Documento Nacional de Identidad (DNI)*" />
          </Form.Group>
          <Form.Check className="me-0"
          inline
            label="Crear ficha del paciente"
            name="pacientes"
            type="checkbox"

          /> */}
        </div>
      </Col>
    </Row>


  )
}

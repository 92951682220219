import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createNoteService, deleteNoteService, noteByIdService, noteByPatientService, updateNoteService } from "../../../api/services/note.service";
import { INote, INoteState, IPatient } from "../../../interfaces";
import { RootState } from "../../store";
import { extraReducers } from "./extraReducers";
export const initialNote = { note: "", patient: "" } as INote;

export const initialState: INoteState = { list: [], note: initialNote, status: 'nofetched' };

export const noteSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    newNote: (state, action: PayloadAction<Partial<INote>>) => {
      state.note = { ...state.note, ...action.payload }
    },
    clearStatus: (state) => {
      state = initialState
      return state
    },
  },
  extraReducers
});

export const noteById = createAsyncThunk(
  'notes/noteById',
  async (note: Partial<INote>, { getState }) => {
    const rootState = getState() as RootState;
    const { list } = rootState.note
    const findNoteInStore = list.length && list.find(currentNote => currentNote.id === note.id);
    if(findNoteInStore) return { content: findNoteInStore, code: 200 };
    return noteByIdService(note)
  }
);


export const noteListByPatient = createAsyncThunk(
  'notes/listByPatient',
  async (patient: IPatient['id']) => noteByPatientService(patient)
);

export const registerNote = createAsyncThunk(
  'notes/register',
  async (note: Partial<INote>) => createNoteService(note)
);

export const updateNote = createAsyncThunk(
  'notes/update',
  async (note: Partial<INote>) => updateNoteService(note)
);

export const deleteNote = createAsyncThunk('notes/delete', async (note: INote) => deleteNoteService(note));

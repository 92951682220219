import { AxiosResponse } from "axios";
import { del, get, post, put } from "../../helpers";
import { DataErrorResponse, INote, IPatient, IResponseDetail, IResponseList } from "../../interfaces";

export const noteByIdService = async (note: Partial<INote>): Promise<IResponseDetail<INote>> =>
  get(`/api/patient/${note.patient}/note/${note.id}`);

export const noteByPatientService = async (patient: IPatient['id'], params?: string): Promise<IResponseList<INote>> =>
  get(`/api/patient/${patient}/notes?${params ? params : ''}`);

export const createNoteService = async (note: Partial<INote>): Promise<IResponseList<Partial<INote>>> =>
  post(`/api/patient/${note.patient}/note`, note);

export const updateNoteService = async (note: Partial<INote>): Promise<IResponseList<Partial<INote>>> =>
  put(`/api/patient/${note.patient}/note/${note.id}`, note);

export const deleteNoteservice = async (note: Partial<INote>): Promise<DataErrorResponse | AxiosResponse<any, any>> =>
  del(`/api/patient/${note.patient}/note/${note.id}`);

export const deleteNoteService = async (note: INote): Promise<IResponseList<Partial<INote>>> =>
  del(`/api/patient/${note.patient}/note/${note.id}`);